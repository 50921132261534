import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import globalCardStyle from '_asset/global/jss/component/common/card/globalCardStyle'

const CardHeader = (props) => {
  const {classes, className, children, color, image, icon, ...rest} = props

  const cardHeaderClasses = cx({
    [classes.cardHeader]: true,
    [classes[color + 'CardHeader']]: color,
    [classes.cardHeaderImage]: image,
    [classes.cardHeaderIcon]: icon,
    [className]: className !== undefined,
  })

  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  )
}

CardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary']),
  image: PropTypes.bool,
  icon: PropTypes.bool,
  children: PropTypes.node,
}

export default withStyles(globalCardStyle)(CardHeader)
