import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import HorizontalStepperWrapper from '_component/global/common/wrapper/HorizontalStepperWrapper'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getTopics} from '_redux/_action/projectSpecific/topicActions'
import {getSelectOptions, hasOnlyPartnerRole} from '_helper/global/functions'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import EventAvailabilityForm from '_component/projectSpecific/event/eventForm/EventAvailabilityForm'
import DateTimeEventForm from '_component/projectSpecific/event/eventForm/EventDateTimeForm'
import SettingsEventForm from '_component/projectSpecific/event/eventForm/EventSettingsForm'
import {getAvailableDates} from '_redux/_action/projectSpecific/eventActions'
import RequirementsEventForm from '_component/projectSpecific/event/eventForm/EventRequirementsForm'
import {getListOrganizations} from '_redux/_action/projectSpecific/organizationActions'
import {getRRuleFromConfig} from '_helper/projectSpecific/functions'
import {RRule} from 'rrule'
import {EVENT_PLATFORMS} from '_helper/projectSpecific/constants'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import moment from 'moment'
import {DATE_TIME_DATABASE_FORMAT} from '_helper/global/constants'

const EventStepper = (props) => {
  const {
    getAvailableDates,
    topics,
    getTopics,
    organizations,
    getListOrganizations,
    handleSubmitEvent,
    event,
    operation,
    handleBack,
  } = props

  const [activeStep, setActiveStep] = useState(0)
  const [availableDates, setAvailableDates] = useState([])
  const [activeTopic, setActiveTopic] = useState()
  const [formData, setFormData] = useStateWithCallbackLazy({
    step1: {},
    step2: {},
    step3: {},
    step4: {},
  })
  const [havePartnerPermission, setPartnerPermission] = useState(false)

  const fetchTopics = useCallback(() => {
    return getTopics(null, 0, 1000, 'name')
  }, [getTopics])

  const fetchOrganizations = useCallback(() => {
    return getListOrganizations(0, 1000, 'name') // get all organizations without a limit
  }, [getListOrganizations])

  const eventSteps = [
    <Trans>Space configuration</Trans>,
    <Trans>Date and time</Trans>,
    <Trans>Event definition</Trans>,
    <Trans>Requirements</Trans>,
  ]

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <EventAvailabilityForm
            havePartnerPermission={havePartnerPermission}
            initialValues={formData['step1']}
            onSubmitAvailability={handleSubmitAvailability}
            topics={getSelectOptions(topics)}
            organizations={getSelectOptions(organizations)}
            handleBack={handleBack}
          />
        )
      case 1:
        return (
          <DateTimeEventForm
            havePartnerPermission={havePartnerPermission}
            goStepBack={goStepBack}
            configuration={formData['step1']}
            initialValues={formData['step2']}
            availableDates={availableDates}
            topic={activeTopic}
            onSubmitDateTime={handleSubmitDateTime}
            operation={operation}
          />
        )
      case 2:
        return (
          <SettingsEventForm
            havePartnerPermission={havePartnerPermission}
            goStepBack={goStepBack}
            configuration={formData['step1']}
            initialValues={formData['step3']}
            onSubmitSettings={handleSubmitSettings}
            event={event}
            form={operation}
          />
        )
      case 3:
        return (
          <RequirementsEventForm
            havePartnerPermission={havePartnerPermission}
            goStepBack={goStepBack}
            configuration={formData['step1']}
            initialValues={formData['step4']}
            operation={operation}
            onSubmitRequirements={handleSubmitRequirements}
          />
        )
      default:
        return <Trans>Unknown step</Trans>
    }
  }

  const handleSubmitAvailability = (values) => {
    const data = {
      ...values,
      organizationId: values.organizationId,
      topicId: values.topicId,
      zone: values.zone,
      organizerContact: {
        id: values.organizerContactId,
      },
    }
    setFormData({...formData, step1: data}, null)
    setActiveTopic(topics.find((topic) => topic.id === values.topicId))
    const newValues = {...values}
    delete newValues.organizationId
    getAvailableDates(newValues)
      .then((response) => {
        setAvailableDates(response)
        setActiveStep(activeStep + 1)
      })
      .catch((err) => console.error('err.response: ', err.response))
  }

  const handleSubmitDateTime = (values) => {
    const stepValues = {
      ...values,
      length: Number(values.length),
      preparationLength: Number(values.preparationLength),
    }

    setFormData({...formData, step2: stepValues}, null)
    setActiveStep(activeStep + 1)
  }

  const handleSubmitSettings = (values) => {
    const stepValues = {
      ...values,
      platform: values.platform === true ? EVENT_PLATFORMS[0].value : EVENT_PLATFORMS[1].value,
      type: values.type ? values.type : formData.step1?.zone === 'PODCAST' ? 'CUSTOM' : '',
      privacy: values.privacy
        ? values.privacy
        : formData.step1?.zone === 'PODCAST'
        ? 'PRIVATE'
        : '',
      capacity: values.capacity ? values.capacity : formData.step1?.zone === 'PODCAST' ? 0 : 0,
      actualParticipantsCount: values.actualParticipantsCount
        ? values.actualParticipantsCount
        : formData.step1?.zone === 'PODCAST'
        ? 0
        : 0,
    }
    setFormData({...formData, step3: stepValues}, null)

    setActiveStep(activeStep + 1)
  }

  const handleSubmitRequirements = (values) => {
    setFormData({...formData, step4: values}, (currentFormData) => {
      const eventData = {
        ...event,
        ...currentFormData.step1,
        ...currentFormData.step2,
        ...currentFormData.step3,
        ...currentFormData.step4,
        length: currentFormData.step2.length / 60,
        preparationLength: currentFormData.step2.preparationLength / 60,
      }
      delete eventData.eventZone

      if (eventData.isRecurrent) {
        eventData.rrule = getRRuleFromConfig(eventData.rruleConfig).replace('RRULE:', '')
        delete eventData.rruleConfig?.help
        delete eventData.rruleConfig?.monthlyRepetition
        delete eventData.rruleConfig
      }
      eventData.from = moment(eventData.from).format(DATE_TIME_DATABASE_FORMAT)
      handleSubmitEvent(eventData, eventData.isRecurrent)
    })
  }

  const goStepBack = (values) => {
    if (activeStep === 2 && operation === 'edit') {
      const data = {
        topicId: event.topic.id,
        zone: event.zone,
      }
      getAvailableDates(data).then((response) => {
        setAvailableDates(response)
      })
    }

    if (values) {
      setFormData((prevState) => ({...prevState, step4: values}), null)
    }

    setActiveStep(activeStep - 1)
  }

  useEffect(() => {
    if (authorizedAbility.can('read', 'Topics')) {
      fetchTopics()
    }
    if (authorizedAbility.can('read', 'Organizations')) {
      fetchOrganizations()
    }
  }, [fetchTopics, fetchOrganizations])

  useEffect(() => {
    if (operation === 'edit') {
      const step1 = {
        organizationId: event.organization && event.organization.id,
        topicId: event.topic && event.topic.id,
        zone: event.zone && event.zone,
        spaceConfiguration: event.spaceConfiguration && event.spaceConfiguration,
        organizer: event.organizer && event.organizer,
        organizerContact: event?.organizerContact?.id,
        organizerContactId: event?.organizerContact?.id,
      }
      const step2 = {
        eventId: event.id && event.id,
        from: event.from && event.from,
        length: event.length && Math.round(event.length * 60),
        preparationLength: event.preparationLength && Math.round(event.preparationLength * 60),
        isRecurrent: event.isRecurrent && event.isRecurrent,
        rruleConfig:
          event.isRecurrent &&
          event.rrule &&
          !event.rrule.includes('undefined') &&
          RRule.parseString(event.rrule),
      }
      const step3 = {
        isTopEvent: event.isTopEvent && event.isTopEvent,
        platform: event.platform && event.platform,
        name: event.name && event.name,
        type: event.type && event.type,
        customType: event.customType && event.customType,
        capacity: event.capacity && event.capacity,
        actualParticipantsCount: event.actualParticipantsCount && event.actualParticipantsCount,
        privacy: event.privacy && event.privacy,
        description: event.description && event.description,
        hideRegistration: event.hideRegistration && event.hideRegistration,
      }
      const step4 = {
        technicalRequirements: event.technicalRequirements && event.technicalRequirements,
        technicalNote: event.technicalNote && event.technicalNote,
        organizationalRequirements:
          event.organizationalRequirements && event.organizationalRequirements,
        organizationalNote: event.organizationalNote && event.organizationalNote,
        organizationalSetup: event.organizationalSetup && event.organizationalSetup,
        additionalRequirements: event.additionalRequirements && event.additionalRequirements,
        additionalNote: event.additionalNote && event.additionalNote,
        internalOrganizationalNote:
          event.internalOrganizationalNote && event.internalOrganizationalNote,
        internalTechnicalNote: event.internalTechnicalNote && event.internalTechnicalNote,
      }

      setFormData({step1: step1, step2: step2, step3: step3, step4: step4}, null)

      hasOnlyPartnerRole ? setActiveStep(2) : setActiveStep(0)
    } else {
      setActiveStep(0)
    }

    // if user have permissions to update events and by mistake have permission to parterUpdate
    // take the update event as more important
    const partnerPermission = authorizedAbility.can('update', 'Events')
      ? false
      : authorizedAbility.can('partnerUpdate', 'Events')

    setPartnerPermission(partnerPermission)
  }, [event])

  return (
    <HorizontalStepperWrapper
      steps={eventSteps}
      getStepContent={getStepContent}
      activeStep={activeStep}
    />
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAvailableDates,
      getTopics,
      getListOrganizations,
    },
    dispatch
  )
}

EventStepper.propTypes = {
  getAvailableDates: PropTypes.func,
  getTopics: PropTypes.func,
  getListOrganizations: PropTypes.func,
}

export default compose(
  connect((store) => {
    return {
      topics: store.SimpleListTopics.data,
      organizations: store.Organizations.data,
    }
  }, mapDispatchToProps)
)(EventStepper)
