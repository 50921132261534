import {UPDATE_FILTERS_STATE} from '_redux/_action/global/types'

function updateAppFilterStates(data) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_FILTERS_STATE,
        data,
      })
      resolve()
    })
  }
}

export default updateAppFilterStates
