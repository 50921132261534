import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {required} from '_helper/projectSpecific/validations'
import SelectField from '_component/global/common/formField/SelectField'
import HorizontalStepFormWrapper from '_component/global/common/stepper/HorrizontalStepFormWrapper'
import GlobalStepperButtons from '_component/global/common/button/GlobalStepperButtons'
import SpaceConfiguration from '_component/projectSpecific/proposal/proposalForm/SpaceConfiguration'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {EVENT_ZONES} from '_helper/projectSpecific/constants'
import TextInput from '_component/global/common/formField/TextInput'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getOrganizationContacts} from '_redux/_action/projectSpecific/organizationContactAction'
import {getSelectOptions} from '_helper/global/functions'

const EventAvailabilityForm = (props) => {
  const {
    onSubmitAvailability,
    initialValues,
    topics,
    organizations,
    hasPartnerPermission,
    handleBack,
    getOrganizationContacts,
    contacts,
    contactsLoading,
  } = props

  const [selected, setSelected] = useState(initialValues.spaceConfiguration || null)

  // set initial selected value for spaceConfiguration
  useEffect(() => {
    setSelected(initialValues.spaceConfiguration)
    if (initialValues.organizationId) {
      handleChangeOrganization('organizationId', initialValues.organizationId)
    }
  }, [initialValues])

  const getEventZonesOptions = (formProps) => {
    if (authorizedAbility.can('manage', 'AllEvents')) {
      return EVENT_ZONES
    } else if (authorizedAbility.can('manage', 'Podcast')) {
      formProps.form.change('zone', 'PODCAST')
      return [{value: 'PODCAST', label: <Trans>Podcast</Trans>}]
    }
  }

  const handleChangeOrganization = (name, val) => {
    getOrganizationContacts(val, 0, 100)
  }

  const hasOnlyManagePodcast =
    authorizedAbility.can('manage', 'Podcast') && authorizedAbility.cannot('manage', 'AllEvents')

  return (
    <HorizontalStepFormWrapper>
      <Form onSubmit={onSubmitAvailability} initialValues={initialValues}>
        {(formProps) => {
          const handleSelectConfigurations = (id) => () => {
            setSelected(id)
            formProps.form.change('spaceConfiguration', id)
          }
          if (formProps.values.zone !== 'EASY_EDU') {
            formProps.form.change('spaceConfiguration', null)
          }
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                {!hasPartnerPermission && (
                  <GridItem xs={12}>
                    <Field
                      name="organizationId"
                      label={<Trans>Organization</Trans>}
                      component={SelectField}
                      options={organizations}
                      validate={required}
                      customOnChange={handleChangeOrganization}
                    />
                  </GridItem>
                )}
                {authorizedAbility.can('manage', 'Internal') && (
                  <>
                    <GridItem xs={12}>
                      <Field
                        name="organizer"
                        label={<Trans>Organizer</Trans>}
                        component={TextInput}
                        disabled={hasPartnerPermission}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="organizerContactId"
                        label={<Trans>Contact</Trans>}
                        component={SelectField}
                        options={getSelectOptions(contacts, 'id', 'email')}
                        // validate={required}
                        emptyOption={true}
                        emptyOptionLabel={<Trans>None</Trans>}
                        disabled={hasPartnerPermission || contactsLoading}
                      />
                    </GridItem>
                  </>
                )}
                <GridItem xs={12}>
                  <Field
                    name="topicId"
                    label={<Trans>Topic</Trans>}
                    component={SelectField}
                    options={topics}
                    validate={required}
                    disabled={hasPartnerPermission}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="zone"
                    label={<Trans>Zone</Trans>}
                    component={SelectField}
                    options={getEventZonesOptions(formProps)}
                    validate={required}
                    disabled={hasPartnerPermission || hasOnlyManagePodcast}
                  />
                </GridItem>
                {formProps.values.zone && formProps.values.zone === 'EASY_EDU' && (
                  <GridItem xs={12}>
                    <SpaceConfiguration
                      selected={selected}
                      selectConfigurations={!hasPartnerPermission && handleSelectConfigurations}
                    />
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <GlobalStepperButtons
                    showBack={true}
                    backButtonLabel={<Trans>Cancel</Trans>}
                    handleBack={handleBack}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationContacts,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      contacts: store.OrganizationContacts.data,
      contactsLoading: store.OrganizationContacts.isLoading,
    }
  }, mapDispatchToProps)
)(EventAvailabilityForm)
