import {
  darkText,
  grayColor,
  lightGradient,
  primaryColor,
  warningColor,
  whiteColor,
} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'

const calendarStyle = (theme) => ({
  calendarMonthHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    fontWeight: 700,
    paddingBottom: '25px',

    '& .text': {
      minWidth: '180px',
      padding: '0 5px',
      textAlign: 'center',
    },
    '& svg': {
      fill: primaryColor[0],
      cursor: 'pointer',
    },
  },
  tableWrapper: {
    width: '100%',
    overflowY: 'hidden',

    '& table': {
      margin: '0 auto',
      tableLayout: 'fixed',
      width: '100%',
      height: '100%',
      borderCollapse: 'collapse',

      '& thead': {
        '& tr': {
          display: 'flex',
          paddingBottom: '5px',
          paddingTop: '5px',
          '& th': {
            fontWeight: 400,
            width: '100%',
            minWidth: '120px',
          },
        },
      },
      '& tbody': {
        overflow: 'auto',
        width: '100%',

        '& tr': {
          display: 'flex',

          '& td': {
            borderTop: '1px solid ' + grayColor[8],
            width: '100%',
            minHeight: '100%',
            minWidth: '120px',

            '&.today-cell': {
              backgroundColor: lightGradient,
            },

            '& .cell-wrapper': {
              minHeight: '100px',
              height: '100%',
              padding: 8,
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',

              '& .show-more-less': {
                fontSize: '12px',
                color: grayColor[0],
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  color: darkText,
                },
              },

              '& .cell-date': {
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                color: primaryColor[0],
                fontWeight: 700,
                fontSize: '20px',
                paddingBottom: '8px',
                position: 'relative',

                '& .cell-date-holiday-icon': {
                  position: 'absolute',
                  right: 0,
                  top: 2,
                  width: 18,
                  height: 18,
                  transform: 'rotate(90deg)',
                },

                '& .cell-event-badges': {
                  position: 'absolute',
                  left: 10,
                  top: -2,
                  '& .MuiBadge-badge': {
                    height: 18,
                    minWidth: 18,
                  },

                  '& .cell-event-badges-warning': {
                    '& .MuiBadge-badge': {
                      backgroundColor: warningColor[0] + '!important',
                      color: 'white',
                      left: 2,
                    },
                  },
                },
              },

              '& .calendar-event-wrapper': {
                borderRadius: BORDER_RADIUS,
                border: '2px solid ' + primaryColor[0],
                minHeight: '110px',
                minWidth: '90px',
                width: '100%',
                height: 'auto',
                padding: '5px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                marginBottom: '7px',
                backgroundColor: whiteColor,

                '&.proposal-wrapper': {
                  border: '2px solid ' + warningColor[0],
                },

                '&:last-child': {
                  marginBottom: 0,
                },

                '&.day-off': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexGrow: 0,
                  cursor: 'default',
                  minHeight: 'unset',
                  maxHeight: '100%',
                  height: 'auto',
                },
                '& .time-name': {
                  color: darkText,
                  fontSize: '12px',
                  flexGrow: '1',
                  '& span': {
                    paddingRight: '5px',
                    '&:last-child': {
                      paddingRight: '0px',
                    },
                  },
                },
                '& .topic': {
                  color: grayColor[0],
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px 0',
                  wordBreak: 'break-word',

                  '& svg': {
                    fill: grayColor[0],
                    height: '16px',
                    width: '16px',
                    paddingRight: '5px',
                  },
                },
                '& .organization': {
                  color: grayColor[0],
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  paddingBottom: '10px',
                  wordBreak: 'break-word',
                  '& svg': {
                    fill: grayColor[0],
                    height: '16px',
                    width: '16px',
                    paddingRight: '5px',
                  },
                },
                '& .configuration': {
                  color: darkText,
                  fontSize: '12px',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                },
              },
            },
          },
        },
      },
    },
  },
  eventFilters: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& div:not(:last-child)': {
      paddingRight: '10px',
    },
    '& .MuiFormControl-root': {
      maxWidth: '250px',
    },
  },
})

export default calendarStyle
