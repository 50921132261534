import {warningColor} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'

const warningMessageStyle = () => ({
  warningMessageWrapper: {
    padding: '0px !important',
    marginBottom: '20px',
  },
  warningMessage: {
    border: `1px solid ${warningColor[0]}`,
    color: warningColor[0],
    borderRadius: BORDER_RADIUS,
    padding: '5px 10px',
    '& svg': {
      fill: warningColor[0],
    },
  },
})
export default warningMessageStyle
