import {warningColor} from '_asset/global/jss/styleHelpers'

export const eventParticipantRowStyle = (theme) => ({
  logoutButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoutButtonLabel: {
    color: warningColor[0],
    cursor: 'pointer',
  },
})
