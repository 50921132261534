import React from 'react'
import LayoutRoute from '_component/global/route/LayoutRoute'
import PrivateRoute from '_component/global/route/PrivateRoute'

export const getRoutesForLayoutSwitch = (routes, isPrivate = false) => {
  return routes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutesForLayoutSwitch(prop.views)
    }
    const RouteComponent = isPrivate === true ? PrivateRoute : LayoutRoute
    return (
      <RouteComponent path={prop.path} component={prop.component} key={key} exact={prop.exact} />
    )
  })
}
