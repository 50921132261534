import React from 'react'
import TopicsCalendarItem from '_component/projectSpecific/topic/topicList/TopicsCalendarItem'
import PropTypes from 'prop-types'

const TopicCalendarItems = (props) => {
  const {items, actualYear, openCreateItem, openEditItem} = props
  return (
    <>
      {items &&
        items.map((month, index) => (
          <TopicsCalendarItem
            key={index}
            year={actualYear}
            month={month}
            openCreate={openCreateItem}
            openEdit={openEditItem}
          />
        ))}
    </>
  )
}

TopicCalendarItems.propTypes = {
  items: PropTypes.array,
  actualYear: PropTypes.node,
  openCreateItem: PropTypes.func,
  openEditItem: PropTypes.func,
}

export default TopicCalendarItems
