import React, {useCallback, useEffect, useState} from 'react'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import {Trans} from '@lingui/macro'
import {AccessTime, Assignment, Room, Settings} from '@material-ui/icons'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {updateGlobalLoader, updateLocalLoader} from '_redux/_action/global/loaderActions'
import DetailTableWrapper from '_component/global/common/wrapper/DetailTableWrapper'
import GridContainer from '_component/global/common/grid/GridContainer'
import DetailGridItem from '_component/global/common/grid/DetailGridItem'
import {
  confirmProposal,
  counterProposal,
  denyProposal,
  getProposal,
  getProposalConfirmData,
} from '_redux/_action/projectSpecific/proposalActions'
import ProposalDetailActionsHeader from '_component/projectSpecific/proposal/proposalDetail/ProposalDetailActionsHeader'
import GridItem from '_component/global/common/grid/GridItem'
import SpaceConfigurationItem from '_component/projectSpecific/proposal/proposalForm/SpaceConfigurationItem'
import DetailItemWrapper from '_component/global/common/wrapper/DetailItemWrapper'
import ProposalActions from '_component/projectSpecific/proposal/proposalDetail/ProposalActions'
import {
  getProposalEventGenericInfos,
  getSpaceConfigurationImage,
  requirementsData,
} from '_helper/projectSpecific/functions'
import WarningMessage from '_component/projectSpecific/common/WarningMessage'
import ProposalConfirmDialog from '_component/projectSpecific/proposal/proposalDetail/ProposalConfirmModal'
import {fireSuccessToast, hasOnlyPartnerRole} from '_helper/global/functions'
import ProposalRejectDialog from '_component/projectSpecific/proposal/proposalDetail/ProposalRejectModal'
import {getAvailableDates, isTimeFree} from '_redux/_action/projectSpecific/eventActions'
import {getTopics} from '_redux/_action/projectSpecific/topicActions'
import CounterProposalDialog from '_component/projectSpecific/proposal/proposalDetail/CounterProposalModal'
import {
  DATABASE_DATE_FORMAT,
  DATE_TIME_DATABASE_FORMAT,
  TIME_FORMAT,
} from '_helper/global/constants'
import moment from 'moment'

const ProposalDetailPage = (props) => {
  const {
    match: {
      params: {id},
    },
    proposal,
    proposalLoaded,
    proposalConfirmData,
    proposalConfirmDataLoaded,
  } = props
  const {
    getProposal,
    updateGlobalLoader,
    updateLocalLoader,
    getProposalConfirmData,
    confirmProposal,
    denyProposal,
    counterProposal,
    getAvailableDates,
    isTimeFree,
  } = props

  const [timeFree, setTimeFree] = useState(true)
  const [timeFreeCounterProposals, setTimeFreeCounterProposals] = useState(null)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openCounterModal, setOpenCounterModal] = useState(false)
  const [availableDates, setAvailableDates] = useState({})

  const fetchProposal = useCallback(() => {
    updateGlobalLoader(true)
    getProposal(id)
      .then(() => {
        updateGlobalLoader(false)
      })
      .catch(() => {
        updateGlobalLoader(false)
      })
  }, [getProposal, updateGlobalLoader, id])

  useEffect(() => {
    fetchProposal()
  }, [fetchProposal])

  const fetchProposalConfirmData = (proposalId) => {
    updateLocalLoader(true)
    getProposalConfirmData(id, proposalId)
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const fetchDateAvailability = () => {
    return getAvailableDates({
      topicId: proposal.topic.id,
      spaceConfiguration: proposal.spaceConfiguration,
    }).then((response) => {
      setAvailableDates(response)
    })
  }

  const handleAcceptProposalModal = (action) => (event) => {
    if (hasOnlyPartnerRole) {
      const data = {
        reason: 'Návrh byl přijat, administrátor nyní vytvoří akci.',
        proposalActions: [
          {
            timeSlot: action.timeSlot,
            customTimeFrom: action.customTimeFrom,
            customTimeLength: action.customTimeLength,
            date: action.date,
            customPreparationLength: action.customPreparationLength,
          },
        ],
      }
      counterProposal(id, data).then(() => {
        fireSuccessToast(<Trans>Counter proposal was accepted.</Trans>)
        fetchProposal()
      })
    } else {
      fetchProposalConfirmData(action.id)
      setOpenConfirmModal(true)
    }
  }
  const handleOpenConfirmProposalModal = () => {
    fetchProposalConfirmData(proposal.proposalActions[0].id)
    setOpenConfirmModal(true)
  }

  const handleConfirmProposal = (values) => {
    const submitValues = {
      ...values,
      from: values.from && moment(values.from).format(DATE_TIME_DATABASE_FORMAT),
      length: values.length / 60,
      preparationLength: values.preparationLength / 60,
      eventZone: values.zone && values.zone,
    }
    if (submitValues.zone !== 'EASY_EDU') {
      delete submitValues.spaceConfiguration
    }

    isTimeFree(submitValues).then((res) => {
      if (res) {
        confirmProposal(id, proposal.proposalActions[0].id, submitValues).then(() => {
          fireSuccessToast(<Trans>Proposal {proposal.name} was accepted.</Trans>)
          setOpenConfirmModal(false)
          fetchProposal()
        })
      } else {
        setTimeFree(res)
      }
    })
  }

  const handleOpenRejectProposalModal = () => {
    setOpenRejectModal(true)
  }

  const handleRejectProposal = (values) => {
    updateLocalLoader(true)
    denyProposal(id, values)
      .then(() => {
        updateLocalLoader(false)
        fetchProposal()
        fireSuccessToast(<Trans>Proposal {proposal.name} was denied.</Trans>)
      })
      .catch((err) => {
        updateLocalLoader(true)
      })
    setOpenRejectModal(false)
  }

  const handleOpenCounterProposalModal = () => {
    fetchDateAvailability().then(() => {
      setOpenCounterModal(true)
    })
  }

  const prepareCounterProposalData = (values) => {
    let data = values

    data.proposalActions.map((proposal, index) => {
      data.proposalActions[index].customTimeFrom = proposal.customTimeFrom?.format(TIME_FORMAT)
      data.proposalActions[index].customTimeLength =
        proposal.customTimeLength && Number(proposal.customTimeLength) / 60
      data.proposalActions[index].customPreparationLength =
        proposal.customPreparationLength && Number(proposal.customPreparationLength) / 60
      data.proposalActions[index].date = proposal.date.format(DATABASE_DATE_FORMAT)
    })

    return data
  }

  const handleCounterProposal = async (values) => {
    let index = 0
    const counterFreeTime = []
    while (index < values.proposalActions.length) {
      const action = values.proposalActions[index]
      if (action.timeSlot === 'CUSTOM') {
        const params = {
          from:
            action.customTimeFrom &&
            action.date &&
            moment(
              `${moment(action.date).format(DATABASE_DATE_FORMAT)}T${moment(
                action.customTimeFrom
              ).format(TIME_FORMAT)}`
            ).format(DATE_TIME_DATABASE_FORMAT),
          length: action.customTimeLength && action.customTimeLength / 60,
          preparationLength: action.customPreparationLength && action.customPreparationLength / 60,
        }

        await isTimeFree(params).then((res) => {
          counterFreeTime.push(res)
        })
      } else {
        counterFreeTime.push(true)
      }

      index++
    }

    if (!counterFreeTime.some((counter) => !counter)) {
      const data = prepareCounterProposalData(values)
      updateLocalLoader(true)
      counterProposal(id, data)
        .then(() => {
          fireSuccessToast(<Trans>Counter proposal for {proposal.name} was sent.</Trans>)
          fetchProposal()
          updateLocalLoader(false)
        })
        .catch((err) => {
          updateLocalLoader(false)
        })

      setOpenCounterModal(false)
    } else {
      setTimeFreeCounterProposals(counterFreeTime)
    }
  }

  return (
    <AdminPageWrapper>
      {proposalConfirmDataLoaded && (
        <ProposalConfirmDialog
          openConfirmDialog={openConfirmModal}
          closeConfirmDialog={() => setOpenConfirmModal(false)}
          handleConfirm={handleConfirmProposal}
          initialValues={proposalConfirmData}
          timeFree={timeFree}
        />
      )}
      <ProposalRejectDialog
        openRejectDialog={openRejectModal}
        closeRejectDialog={() => setOpenRejectModal(false)}
        handleReject={handleRejectProposal}
      />
      <CounterProposalDialog
        openCounterDialog={openCounterModal}
        closeCounterDialog={() => setOpenCounterModal(false)}
        availableProposalDates={availableDates}
        handleCounterProposal={handleCounterProposal}
        topic={proposal.topic}
        timeFreeCounterProposals={timeFreeCounterProposals}
      />
      {proposalLoaded && (
        <ContentAdminPageWrapper
          pageTitle={<Trans>Detail event proposal</Trans>}
          cardPageTitle={proposal.name ? proposal.name : ''}
          iconPageHeader={<Assignment />}
          toolbar={false}
          actionsHeader={
            <ProposalDetailActionsHeader
              openConfirmModal={handleOpenConfirmProposalModal}
              openCounterproposalModal={handleOpenCounterProposalModal}
              openDenialModal={handleOpenRejectProposalModal}
              status={proposal.status}
            />
          }
        >
          <GridContainer>
            {proposal.expiresInDays !== undefined && (
              <WarningMessage
                warningMessage={
                  proposal.expiresInDays <= 0 ? (
                    <Trans>Proposal expired.</Trans>
                  ) : (
                    <Trans>Days until proposal expiration: {proposal.expiresInDays}</Trans>
                  )
                }
              />
            )}
            {proposal.denialReason && <WarningMessage warningMessage={proposal.denialReason} />}
            <DetailGridItem
              xs={12}
              sm={6}
              icon={<Assignment />}
              title={<Trans>Event definition</Trans>}
            >
              <DetailTableWrapper data={getProposalEventGenericInfos(proposal, 'proposal')} />
            </DetailGridItem>
            <DetailGridItem xs={12} sm={6} icon={<Settings />} title={<Trans>Requirements</Trans>}>
              <DetailTableWrapper
                cellWrap={true}
                alignTop
                data={requirementsData(proposal)}
                strongValue={false}
              />
            </DetailGridItem>
            <DetailGridItem
              xs={12}
              sm={6}
              icon={<AccessTime />}
              title={<Trans>Counter proposal</Trans>}
            >
              <DetailItemWrapper>
                <ProposalActions
                  proposalActions={proposal.proposalActions}
                  proposalStatus={proposal.status}
                  openConfirmModal={handleAcceptProposalModal}
                />
              </DetailItemWrapper>
              {proposal.counterReason && <WarningMessage warningMessage={proposal.counterReason} />}
            </DetailGridItem>
            {proposal.spaceConfiguration && (
              <DetailGridItem
                xs={12}
                sm={6}
                icon={<Room />}
                title={<Trans>Space configuration</Trans>}
              >
                <GridContainer>
                  <GridItem xs={12} xl={6}>
                    <DetailItemWrapper>
                      <SpaceConfigurationItem>
                        {proposal.spaceConfiguration &&
                          getSpaceConfigurationImage(proposal.spaceConfiguration)}
                      </SpaceConfigurationItem>
                    </DetailItemWrapper>
                  </GridItem>
                </GridContainer>
              </DetailGridItem>
            )}
          </GridContainer>
        </ContentAdminPageWrapper>
      )}
    </AdminPageWrapper>
  )
}

ProposalDetailPage.propTypes = {
  proposal: PropTypes.object.isRequired,
  updateGlobalLoader: PropTypes.func,
  getProposal: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalLoader,
      updateLocalLoader,
      getProposal,
      getProposalConfirmData,
      confirmProposal,
      denyProposal,
      getAvailableDates,
      getTopics,
      counterProposal,
      isTimeFree,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      proposal: store.Proposal.data,
      proposalLoaded: !store.Proposal.isLoading,
      proposalConfirmData: store.ProposalConfirmData.data,
      proposalConfirmDataLoaded: !store.ProposalConfirmData.isLoading,
    }
  }, mapDispatchToProps)
)(ProposalDetailPage)
