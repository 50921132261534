import {
  darkText,
  dividerColor,
  grayColor,
  primaryColor,
  secondaryColor,
  whiteColor,
} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'
import {drawerWidth} from '../../styleHelpers'

const globalLayoutElementsStyle = () => ({
  footerGrid: {
    height: '100%',
    minHeight: '56px',
    borderTop: '1px solid #D9D8D8',
    padding: '0px 20px',
  },
  footerLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0 !important',
    textTransform: 'uppercase',
  },
  footerLink: {
    cursor: 'pointer',
    color: '#6c6c6c !important',
    fontWeight: '300',
    paddingRight: '10px',
    fontSize: '0.8rem',
    '&:last-child': {
      paddingRight: '0px',
    },
    '&:hover': {
      color: `${primaryColor[0]} !important`,
    },
  },
  disabledLink: {
    pointerEvents: 'none',
  },
  footerLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 !important',

    '& a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  footerLogoImg: {
    // width: '100%',
    maxWidth: '254px',
    maxHeight: '55px',
  },
  footerLaunch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '0 !important',
    '& a': {
      textDecoration: 'none',
      fontSize: '0.9rem',
      color: '#999999',
      fontWeight: '300',
    },
  },
  footerIcons: {
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerIconMiddle: {
    padding: '0 5px',
    height: '18px',
  },
  drawerOpened: {
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      transition: 'width 200ms',
      '& .MuiListItemIcon-root': {
        minWidth: '40px',
      },
    },
  },
  drawerClosed: {
    '& .MuiDrawer-paper': {
      width: '56px',
      transition: 'width 200ms',
      overflowY: 'unset',
    },
  },
  drawerWrapper: {
    '& .MuiDrawer-root': {
      backgroundColor: whiteColor,
      color: darkText,
      fontWeight: '300',
    },
    '& .MuiDivider-root': {
      backgroundColor: dividerColor,
      '&.MuiDivider-middle': {
        margin: '20px 20px 50px',
      },
    },
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      boxShadow: '4px 0px 40px #00000029',
    },
    '& .MuiCollapse-wrapperInner': {
      '& .MuiListItemText-root': {
        paddingLeft: '40px',
      },
    },
    '& .MuiListItem-root': {
      '& .MuiTypography-root': {
        fontSize: '1.2rem',
        lineHeight: '2rem',
        fontWeight: '300',
      },
      '&. MuiListItemIcon-root': {
        minWidth: '0',
      },
      '&:hover': {
        background: `linear-gradient(90deg, rgba(226,0,116,0.2) 0%,
                                            rgba(226,0,116,0.05) 40%,
                                            rgba(226,0,116,0) 100%)`,
      },
    },
  },
  drawerCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '24px',
    '& button': {
      width: '56px',
      height: '56px',
    },
  },
  drawerLogoContainer: {
    height: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& object': {
      maxHeight: '100%',
    },
    '& img': {
      height: 'auto',
      width: '75%',
    },
    '& svg': {
      fill: primaryColor[0],
      width: '100%',
    },
  },
  hiddenElement: {
    display: 'none',
  },
  adminNavbarWrapper: {
    '& .MuiToolbar-root': {
      minHeight: '60px',
      padding: '0 20px',
      display: 'flex',
      justifyContent: 'space-between',

      '& .MuiBreadcrumbs-ol': {
        color: '#999999',
        fontWeight: '400',

        '& .MuiTypography-colorTextPrimary': {
          color: darkText,
          fontWeight: '700',
        },
      },
    },
  },
  adminNavbarSettings: {
    marginRight: '-55px' /* move user account to the far right */,
    cursor: 'pointer',
    color: darkText,
    fontWeight: '700',
    '& img': {
      width: '30px',
      height: '30px',
      borderRadius: '30px',
    },
    '& .name': {
      padding: '5px',
    },
  },
  dropDownPopper: {
    zIndex: 999,
    borderRadius: BORDER_RADIUS,
    paddingTop: '4px',
    paddingBottom: '4px',
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      padding: '4px 10px',
      '& .MuiSvgIcon-root': {
        paddingRight: '10px',
        color: grayColor[0],
      },
    },
  },
  activeMenu: {
    background: `linear-gradient(90deg, rgba(226,0,116,1) 0%,
                                        rgba(226,0,116,1) 1.5%,
                                        rgba(226,0,116,0.2) 2%,
                                        rgba(226,0,116,0.05) 40%,
                                        rgba(226,0,116,0) 100%)`,
    '&:hover': {
      background: `linear-gradient(90deg, rgba(226,0,116,1) 0%,
                                        rgba(226,0,116,1) 1.5%,
                                        rgba(226,0,116,0.2) 2%,
                                        rgba(226,0,116,0.05) 40%,
                                        rgba(226,0,116,0) 100%) !important`,
    },
  },
  breadcrumbs: {
    textTransform: 'capitalize',
    color: grayColor[0] + ' !important',
    '&:hover': {
      color: darkText + ' !important',
    },
  },
  lastBreadcrumb: {
    textTransform: 'capitalize',
  },
  tosLink: {
    color: primaryColor[0],
    fontSize: '16px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  globalLink: {
    color: secondaryColor,
    textDecoration: 'none',
    fontSize: '0.8rem',
    paddingRight: '10px',
    '&:hover': {
      textDecoration: 'underline',
      color: primaryColor[0],
    },
  },
})

export default globalLayoutElementsStyle
