import {GATEWAY_URL} from '_helper/global/constants'
import {getServiceUrl} from '_helper/global/functions'

const endpoints = {
  onBoardingStart: `${GATEWAY_URL}/onboarding/start`, // initial registration, unused for now
  onBoardingFinish: `${GATEWAY_URL}/onboarding/finish`, // final registration, unused for now

  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  myAccount: `${getServiceUrl('auth')}/my-account`,
  changePassword: `${getServiceUrl('auth')}/my-account/change-password`,
  groups: `${getServiceUrl('auth')}/groups`,
  organizations: `${getServiceUrl('events')}/organizations/`,
  myOrganization: `${getServiceUrl('events')}/my-organization/`,
  logo: `${getServiceUrl('events')}/logo`,
  topicsCalendar: `${getServiceUrl('events')}/topics/calendar/`,
  topics: `${getServiceUrl('events')}/topics/`,
  proposals: `${getServiceUrl('events')}/proposals/`,
  events: `${getServiceUrl('events')}/events/`,
  statistics: `${getServiceUrl('events')}/statistics`,
  dateAvailability: `${getServiceUrl('events')}/date-availability`,
  roles: `${getServiceUrl('auth')}/roles`,
  calendar: `${getServiceUrl('events')}/events/calendar`,
  users: `${getServiceUrl('auth')}/users/`,
  isTimeFree: `${getServiceUrl('events')}/events/is-time-free`,
  satisfaction: `${getServiceUrl('events')}/satisfaction`,
  print: `${getServiceUrl('print')}`,
}

export default endpoints
