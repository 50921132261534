import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import {AccessTime, Assignment, Create, Room, Settings, Info} from '@material-ui/icons'
import PropTypes from 'prop-types'
import DetailTableWrapper from '_component/global/common/wrapper/DetailTableWrapper'
import DetailGridItem from '_component/global/common/grid/DetailGridItem'
import GridContainer from '_component/global/common/grid/GridContainer'
import {
  getProposalEventGenericInfos,
  getSpaceConfigurationImage,
  parseRRuleForRender,
  requirementsData,
} from '_helper/projectSpecific/functions'
import GridItem from '_component/global/common/grid/GridItem'
import DetailItemWrapper from '_component/global/common/wrapper/DetailItemWrapper'
import SpaceConfigurationItem from '_component/projectSpecific/proposal/proposalForm/SpaceConfigurationItem'
import EventDateTime from '_component/projectSpecific/event/eventDetail/EventDateTime'
import {withStyles} from '@material-ui/core'
import {eventDetailStyle} from '_asset/projectSpecific/jss/event/eventDetailStyle'
import Tooltip from '@material-ui/core/Tooltip'
import ConfigurationDialog from '_component/projectSpecific/proposal/proposalForm/ConfigurationDialog'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {isTimeFree} from '_redux/_action/projectSpecific/eventActions'
import WarningMessage from '_component/projectSpecific/common/WarningMessage'
import {authorizedAbility} from 'App/_provider/authorizedAbility'

const EventDetailTab = (props) => {
  const {item, classes, isTimeFree} = props
  const [timeFree, setTimeFree] = useState(true)

  const [configurationDialog, setConfigurationDialog] = useState(false)

  const openConfigurationDialog = () => {
    setConfigurationDialog(true)
  }

  const prepareParams = {
    length: item.length,
    preparationLength: item.preparationLength,
    eventZone: item.zone,
    spaceConfiguration: item.spaceConfiguration,
    from: item.from,
    eventId: item.id,
  }

  useEffect(() => {
    isTimeFree(prepareParams).then((res) => {
      setTimeFree(res)
    })
  }, [])

  return (
    <GridContainer>
      <ConfigurationDialog
        openDialog={configurationDialog}
        closeDialog={() => setConfigurationDialog(false)}
      />
      {!timeFree && (
        <WarningMessage
          warningMessage={<Trans>The time of this event overlaps with another event!</Trans>}
        />
      )}

      <DetailGridItem xs={12} sm={6} icon={<Assignment />} title={<Trans>Event definition</Trans>}>
        <DetailTableWrapper
          data={getProposalEventGenericInfos(
            item,
            'event',
            authorizedAbility.can('manage', 'Internal')
          )}
        />
      </DetailGridItem>

      <DetailGridItem xs={12} sm={6} icon={<Settings />} title={<Trans>Requirements</Trans>}>
        <DetailTableWrapper
          cellWrap={true}
          alignTop
          data={requirementsData(
            item,
            authorizedAbility.can('manage', 'Internal'),
            item?.zone === 'PODCAST'
          )}
          strongValue={false}
        />
      </DetailGridItem>

      <DetailGridItem xs={12} sm={6} icon={<AccessTime />} title={<Trans>Date and time</Trans>}>
        <DetailItemWrapper>
          <EventDateTime
            from={item.from}
            length={item.length}
            preparationLength={item.preparationLength}
            isRecurrent={item.isRecurrent}
            timeFree={timeFree}
          />
        </DetailItemWrapper>
      </DetailGridItem>

      {item.spaceConfiguration && (
        <DetailGridItem
          xs={12}
          sm={6}
          icon={<Room />}
          title={
            <div className={classes.spaceConfigurationLabel}>
              <Trans>Space configuration</Trans>
              <Tooltip title={<Trans>Configuration map</Trans>} placement="top" enterDelay={300}>
                <Info onClick={openConfigurationDialog} />
              </Tooltip>
            </div>
          }
        >
          <GridContainer>
            <GridItem xs={12}>
              <DetailItemWrapper>
                <SpaceConfigurationItem>
                  {item.spaceConfiguration && getSpaceConfigurationImage(item.spaceConfiguration)}
                </SpaceConfigurationItem>
              </DetailItemWrapper>
            </GridItem>
          </GridContainer>
        </DetailGridItem>
      )}
      {item.isRecurrent && (
        <DetailGridItem xs={12} sm={6} icon={<AccessTime />} title={<Trans>Recurrence</Trans>}>
          <DetailTableWrapper data={item.rrule && parseRRuleForRender(item.rrule)} />
        </DetailGridItem>
      )}
      {item.description && (
        <DetailGridItem xs={12} sm={6} icon={<Create />} title={<Trans>Event description</Trans>}>
          <DetailItemWrapper>
            <div
              className="description"
              dangerouslySetInnerHTML={{__html: item.description && item.description}}
            />
          </DetailItemWrapper>
        </DetailGridItem>
      )}
    </GridContainer>
  )
}

EventDetailTab.propTypes = {
  item: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isTimeFree: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      isTimeFree,
    },
    dispatch
  )
}

export default compose(
  withStyles(eventDetailStyle),
  connect(null, mapDispatchToProps)
)(EventDetailTab)
