import React from 'react'
import PropTypes from 'prop-types'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {Box} from '@material-ui/core'
import {Trans} from '@lingui/macro'

const GlobalStepperButtons = (props) => {
  const {
    handleBack,
    backButtonLabel = <Trans>Previous</Trans>,
    submitButtonLabel = <Trans>Next</Trans>,
    showBack = true,
  } = props

  return (
    <div style={{marginTop: '20px'}}>
      <Box display="flex" justifyContent={showBack ? 'space-between' : 'flex-end'} flexGrow={1}>
        {showBack && (
          <GlobalButton backgroundColor="primary" inverted onClick={handleBack}>
            {backButtonLabel}
          </GlobalButton>
        )}
        <GlobalButton type="submit" backgroundColor="primary">
          {submitButtonLabel}
        </GlobalButton>
      </Box>
    </div>
  )
}

GlobalStepperButtons.propTypes = {
  handleBack: PropTypes.func,
  backButtonLabel: PropTypes.node,
  submitButtonLabel: PropTypes.node,
  showBack: PropTypes.bool,
}

export default GlobalStepperButtons
