import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import globalDialogStyle from '_asset/global/jss/component/common/dialog/globalDialogStyle'
import cx from 'classnames'

const DialogTitle = (props) => {
  const {children, classes, onClose, dialogTitleClasses} = props

  return (
    <MuiDialogTitle disableTypography className={cx(classes.muiDialogTitle, dialogTitleClasses)}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeDialogTitleButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default withStyles(globalDialogStyle)(DialogTitle)
