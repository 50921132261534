import React, {useEffect, useState} from 'react'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import {t, Trans} from '@lingui/macro'
import {Assignment, Equalizer, EventNote, SettingsInputSvideo, Dashboard} from '@material-ui/icons'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import {unauthorizedRedirectToDashboard} from '_helper/global/functions'
import {bindActionCreators, compose} from 'redux'
import {
  getPricingStatistics,
  getSatisfactionStatistics,
  getStatistics,
} from '_redux/_action/projectSpecific/eventStatisticsActions'
import {connect} from 'react-redux'
import GridContainer from '_component/global/common/grid/GridContainer'
import PropTypes from 'prop-types'
import GridItem from '_component/global/common/grid/GridItem'
import {Field, Form} from 'react-final-form'
import DateInput from '_component/global/common/formField/DateInput'
import moment from 'moment'
import {DATABASE_DATE_FORMAT, DATE_FORMAT} from '_helper/global/constants'
import SelectField from '_component/global/common/formField/SelectField'
import {withStyles} from '@material-ui/core'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import {required} from '_helper/projectSpecific/validations'
import DetailGridItem from '_component/global/common/grid/DetailGridItem'
import DetailTableWrapper from '_component/global/common/wrapper/DetailTableWrapper'
import {EVENT_SPACE_CONFIGURATIONS, EVENT_ZONES} from '_helper/projectSpecific/constants'
import Loader from '_component/global/common/table/Loader'
import TabPanel from '_component/global/common/tab/TabPanel'
import {getListOrganizations} from '_redux/_action/projectSpecific/organizationActions'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import ListIcon from '@material-ui/icons/List'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import i18nProvider from 'App/_provider/i18n'
import StarsIcon from '@material-ui/icons/Stars'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import TheatersIcon from '@material-ui/icons/Theaters'

const StatisticPage = (props) => {
  const {
    classes,
    getStatistics,
    statistics,
    statisticsFilter,
    statisticsLoading,
    getPricingStatistics,
    getListOrganizations,
    organizations,
    pricingData,
    pricingDataLoading,
    satisfactionLoading,
    satisfactionData,
    getSatisfactionStatistics,
  } = props

  const [activeTab, setActiveTab] = useState('events')

  const [filters, setFilters] = useState({
    period: 'thisMonth',
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    organizationId: null,
  })

  const handleChangeTab = (e, tabValue) => {
    setActiveTab(tabValue)
  }

  const handleChangeFilter = (value, name) => {
    setFilters((prevState) => ({
      ...prevState,
      period: 'own',
      [name]: value ? moment(value, DATE_FORMAT).format(DATABASE_DATE_FORMAT) : null,
    }))
  }

  const handleChangeOrganization = (name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      organizationId: value,
    }))
  }

  const getInitValues = () => {
    return {
      ...filters,
    }
  }

  const handleFilters = (values) => {
    // empty submit method
  }

  const renderFilters = () => {
    return (
      <Form onSubmit={handleFilters} initialValues={getInitValues}>
        {(formProps) => {
          const handleChangePeriod = (name, value) => {
            if (value === 'thisWeek') {
              formProps.form.change('from', moment().startOf('week'))
              formProps.form.change('to', moment().endOf('week'))
              setFilters((prevState) => ({
                ...prevState,
                period: 'thisWeek',
                from: moment().startOf('week'),
                to: moment().endOf('week'),
              }))
            } else if (value === 'thisMonth') {
              formProps.form.change('from', moment().startOf('month'))
              formProps.form.change('to', moment().endOf('month'))
              setFilters((prevState) => ({
                ...prevState,
                period: 'thisMonth',
                from: moment().startOf('month'),
                to: moment().endOf('month'),
              }))
            } else if (value === 'thisYear') {
              formProps.form.change('from', moment().startOf('year'))
              formProps.form.change('to', moment().endOf('year'))
              setFilters((prevState) => ({
                ...prevState,
                period: 'thisYear',
                from: moment().startOf('year'),
                to: moment().endOf('year'),
              }))
            } else if (value === 'lastWeek') {
              formProps.form.change('from', moment().startOf('week').subtract(1, 'week'))
              formProps.form.change('to', moment().endOf('week').subtract(1, 'week'))
              setFilters((prevState) => ({
                ...prevState,
                period: 'lastWeek',
                from: moment().startOf('week').subtract(1, 'week'),
                to: moment().endOf('week').subtract(1, 'week'),
              }))
            } else if (value === 'lastMonth') {
              formProps.form.change('from', moment().startOf('month').subtract(1, 'month'))
              formProps.form.change('to', moment().endOf('month').subtract(1, 'month'))
              setFilters((prevState) => ({
                ...prevState,
                period: 'lastMonth',
                from: moment().startOf('month').subtract(1, 'month'),
                to: moment().endOf('month').subtract(1, 'month'),
              }))
            } else if (value === 'lastYear') {
              formProps.form.change('from', moment().startOf('year').subtract(1, 'year'))
              formProps.form.change('to', moment().endOf('year').subtract(1, 'year'))
              setFilters((prevState) => ({
                ...prevState,
                period: 'lastYear',
                from: moment().startOf('year').subtract(1, 'year'),
                to: moment().endOf('year').subtract(1, 'year'),
              }))
            }
          }

          return (
            <form onSubmit={formProps.onSubmit}>
              <GridContainer direction={'row'}>
                {activeTab !== 'events' && (
                  <GridItem>
                    <Field
                      name={'organizationId'}
                      label={<Trans>Organization</Trans>}
                      component={SelectField}
                      options={organizations.map((org) => ({value: org.id, label: org.name}))}
                      classNames={classes.fieldWidth}
                      customOnChange={handleChangeOrganization}
                      emptyOption={true}
                      emptyOptionLabel={<Trans>None</Trans>}
                    />
                  </GridItem>
                )}
                <GridItem>
                  <Field
                    name="period"
                    label={<Trans>Period</Trans>}
                    component={SelectField}
                    options={[
                      {value: 'thisWeek', label: <Trans>This week</Trans>},
                      {value: 'thisMonth', label: <Trans>This month</Trans>},
                      {value: 'thisYear', label: <Trans>This year</Trans>},
                      {value: 'lastWeek', label: <Trans>Last week</Trans>},
                      {value: 'lastMonth', label: <Trans>Last month</Trans>},
                      {value: 'lastYear', label: <Trans>Last year</Trans>},
                      {value: 'own', label: <Trans>Own</Trans>},
                    ]}
                    classNames={classes.fieldWidth}
                    customOnChange={handleChangePeriod}
                  />
                </GridItem>
                <GridItem>
                  <Field
                    name="from"
                    label={<Trans>From</Trans>}
                    component={DateInput}
                    validate={required}
                    onCustomChange={handleChangeFilter}
                    maxDate={formProps.values?.to || undefined}
                    clearable={true}
                    classNames={classes.fieldWidth}
                    allowKeyboardControl={false}
                  />
                </GridItem>
                <GridItem>
                  <Field
                    name="to"
                    label={<Trans>To</Trans>}
                    component={DateInput}
                    onCustomChange={handleChangeFilter}
                    minDate={formProps.values?.from || undefined}
                    clearable={true}
                    classNames={classes.fieldWidth}
                    allowKeyboardControl={false}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    )
  }

  const paymentData = [
    {
      field: <Trans>Space rent</Trans>,
      value: pricingData?.spaceRent ? `${Math.round(pricingData.spaceRent / 100)} Kč` : '0 Kč',
    },
    {
      field: <Trans>Equipment rent</Trans>,
      value: pricingData?.equipmentRent
        ? `${Math.round(pricingData.equipmentRent / 100)} Kč`
        : '0 Kč',
    },
    {
      field: <Trans>Video production</Trans>,
      value: pricingData?.videoProduction
        ? `${Math.round(pricingData.videoProduction / 100)} Kč`
        : '0 Kč',
    },
    {
      field: <Trans>Other payments</Trans>,
      value: pricingData?.otherPayments
        ? `${Math.round(pricingData.otherPayments / 100)} Kč`
        : '0 Kč',
    },
    {
      field: <Trans>Marketing counter</Trans>,
      value: pricingData?.marketingCounter
        ? `${Math.round(pricingData.marketingCounter / 100)} Kč`
        : '',
    },
    {
      field: <Trans>Total price</Trans>,
      value: pricingData?.total ? `${Math.round(pricingData.total / 100)} Kč` : '0 Kč',
    },
  ]

  const getTabList = () => {
    const tabList = []
    if (authorizedAbility.can('read', 'Statistic')) {
      tabList.push({label: <Trans>Events</Trans>, value: 'events'})
      tabList.push({label: <Trans>Prices</Trans>, value: 'price'})
      tabList.push({label: <Trans>Satisfaction</Trans>, value: 'satisfaction'})
    }
    return tabList
  }

  useEffect(() => {
    getListOrganizations(0, 300, 'name', 'ASC')
  }, [])

  useEffect(() => {
    if (activeTab === 'price') {
      getPricingStatistics(filters)
    } else if (activeTab === 'satisfaction') {
      getSatisfactionStatistics(filters)
    } else {
      getStatistics(filters)
    }
  }, [filters, activeTab])

  useEffect(() => {
    if (authorizedAbility.cannot('read', 'Statistic')) {
      unauthorizedRedirectToDashboard(<Trans>You are not authorized to read statistics.</Trans>)
    }
  }, [])

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper
        tabsValue={activeTab}
        tabList={getTabList()}
        handleChangeTab={handleChangeTab}
        pageTitle={<Trans>Statistics</Trans>}
        iconPageHeader={<Equalizer />}
        toolbar={false}
        actionsHeader={renderFilters()}
      >
        <>
          <TabPanel value={activeTab} index={'events'}>
            <GridContainer>
              {statisticsLoading ? (
                <Loader />
              ) : (
                <>
                  <DetailGridItem xs={12} sm={6} icon={<EventNote />} title={<Trans>Events</Trans>}>
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={[
                        {
                          field: <Trans>Number of events</Trans>,
                          value: statistics.totalEvents ? statistics.totalEvents : 0,
                        },
                        {
                          field: <Trans>Number of online events</Trans>,
                          value: statistics.onlineEventsCount ? statistics.onlineEventsCount : 0,
                        },
                        {
                          field: <Trans>Number of internal events</Trans>,
                          value: statistics.internalEventsCount
                            ? statistics.internalEventsCount
                            : 0,
                        },
                        {
                          field: <Trans>Event capacity summary</Trans>,
                          value: statistics.eventsCapacity ? statistics.eventsCapacity : 0,
                        },
                        {
                          field: <Trans>Event participants summary</Trans>,
                          value: statistics.eventsParticipants ? statistics.eventsParticipants : 0,
                        },
                        {
                          field: <Trans>Online event viewers summary</Trans>,
                          value: statistics.eventsOnlineViewers
                            ? statistics.eventsOnlineViewers
                            : 0,
                        },
                        {
                          field: <Trans>Actual participants summary</Trans>,
                          value: statistics.actualParticipantsCount
                            ? statistics.actualParticipantsCount
                            : 0,
                        },
                      ]}
                    />
                  </DetailGridItem>

                  <DetailGridItem
                    xs={12}
                    sm={6}
                    icon={<Assignment />}
                    title={<Trans>Proposals</Trans>}
                  >
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={[
                        {
                          field: <Trans>Number of confirmed proposals</Trans>,
                          value: statistics.confirmedProposals ? statistics.confirmedProposals : 0,
                        },
                        {
                          field: <Trans>Number of denied proposals</Trans>,
                          value: statistics.deniedProposals ? statistics.deniedProposals : 0,
                        },
                      ]}
                    />
                  </DetailGridItem>

                  <DetailGridItem
                    xs={12}
                    sm={6}
                    icon={<SettingsInputSvideo />}
                    title={<Trans>Events per space configuration</Trans>}
                  >
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={
                        statistics?.eventsPerSpaceConfiguration &&
                        Object.keys(statistics.eventsPerSpaceConfiguration).map((conf) => ({
                          field: EVENT_SPACE_CONFIGURATIONS.find((c) => c.id === conf)
                            ?.configuration
                            ? EVENT_SPACE_CONFIGURATIONS.find((c) => c.id === conf)?.configuration
                            : conf,
                          value: statistics.eventsPerSpaceConfiguration[conf],
                        }))
                      }
                    />
                  </DetailGridItem>

                  <DetailGridItem
                    xs={12}
                    sm={6}
                    icon={<Dashboard />}
                    title={<Trans>Events per zone</Trans>}
                  >
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={
                        statistics?.eventsPerZone &&
                        Object.keys(statistics.eventsPerZone).map((zone) => ({
                          field: EVENT_ZONES.find((c) => c.value === zone)?.label
                            ? EVENT_ZONES.find((c) => c.value === zone)?.label
                            : zone,
                          value: statistics.eventsPerZone[zone],
                        }))
                      }
                    />
                  </DetailGridItem>
                </>
              )}
            </GridContainer>
          </TabPanel>
          <TabPanel value={activeTab} index={'price'}>
            {pricingDataLoading ? (
              <Loader />
            ) : (
              <GridContainer>
                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<MonetizationOnIcon />}
                  title={<Trans>Payments (without dph)</Trans>}
                >
                  <DetailTableWrapper classNames={classes.statisticsTable} data={paymentData} />
                </DetailGridItem>

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<ListIcon />}
                  title={<Trans>Marketing counter</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={pricingData?.marketingCounters?.map((counter) => ({
                      field: (
                        <span
                          style={{whiteSpace: 'normal'}}
                          dangerouslySetInnerHTML={{
                            __html:
                              counter.marketingCounterDescription &&
                              counter.marketingCounterDescription,
                          }}
                        />
                      ),
                      value: counter.marketingCounter
                        ? `${Math.round(counter.marketingCounter / 100)} Kč`
                        : '0 Kč',
                    }))}
                  />
                </DetailGridItem>
              </GridContainer>
            )}
          </TabPanel>

          <TabPanel value={activeTab} index={'satisfaction'}>
            {satisfactionLoading ? (
              <Loader />
            ) : (
              <GridContainer>
                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<QuestionAnswerIcon />}
                  title={<Trans>Satisfaction survey</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={[
                      {
                        field: i18nProvider._(t`Celkem odpovědí`),
                        value: satisfactionData.totalResponses || 0,
                      },
                    ]}
                  />
                </DetailGridItem>
                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<StarsIcon />}
                  title={
                    <Trans>
                      Zajišťovali jsme vám služby, které nejsou běžně součástí pronájmů prostor a
                      vnímáte je jako benefit Magenta Experience Centra?
                    </Trans>
                  }
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={[
                      {
                        field: i18nProvider._(t`Yes`),
                        value: satisfactionData.nonStandardServicesConfirmed || 0,
                      },
                      {
                        field: i18nProvider._(t`No`),
                        value:
                          satisfactionData.totalResponses -
                          satisfactionData.nonStandardServicesConfirmed,
                      },
                    ]}
                  />
                </DetailGridItem>

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<ThumbsUpDownIcon />}
                  title={<Trans>Co Vám nevyhovovalo, nebo máme zlepšit?</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={satisfactionData?.improvementSuggestions
                      ?.filter((row) => !!row)
                      ?.map((row) => ({
                        field: row,
                        value: null,
                      }))}
                  />
                </DetailGridItem>

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<SentimentVerySatisfiedIcon />}
                  title={<Trans>Satisfaction</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={[
                      {
                        field: i18nProvider._(
                          t`Jak jste byli celkově spokojeni s Magenta Experience Centrem?`
                        ),
                        value: satisfactionData.overAllSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(
                          t`Jak byli s prostorem spokojeni účastníci vašeho eventu?`
                        ),
                        value: satisfactionData.participantSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(
                          t`Jak jste byli spokojeni s organizačním zajištěním?`
                        ),
                        value: satisfactionData.organizationalSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(
                          t`Jak jste byli spokojeni s technickým zajištěním akce?`
                        ),
                        value:
                          satisfactionData.technicalSupportSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(t`Doporučili by jste MEC kolegům pro konání eventů?`),
                        value: satisfactionData.likelihoodOfRecommendationAverage?.toFixed(2) || 0,
                      },
                    ]}
                  />
                </DetailGridItem>

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<TheatersIcon />}
                  title={
                    <Trans>
                      Zajišťovali jsme vám live stream, záznam z akce či dokonce postprodukční práce
                      a grafický servis? Jak jste byli spokojeni?
                    </Trans>
                  }
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={satisfactionData?.audioVideoServicesCommentaries
                      ?.filter((row) => !!row)
                      ?.map((row) => ({
                        field: row,
                        value: null,
                      }))}
                  />
                </DetailGridItem>
              </GridContainer>
            )}
          </TabPanel>
        </>
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

StatisticPage.propTypes = {
  getStatistics: PropTypes.func,
  getPricingStatistics: PropTypes.func,
  statistics: PropTypes.object,
  statisticsLoading: PropTypes.bool,
  getListOrganizations: PropTypes.func,
  getSatisfactionStatistics: PropTypes.func,
  organizations: PropTypes.array,
  pricingData: PropTypes.object,
  pricingDataLoading: PropTypes.bool,
  satisfactionData: PropTypes.object,
  satisfactionLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStatistics,
      getPricingStatistics,
      getListOrganizations,
      getSatisfactionStatistics,
    },
    dispatch
  )
}

export default compose(
  withStyles(globalWrapperStyles),
  connect((store) => {
    return {
      statistics: store.Statistics.data,
      statisticsLoading: store.Statistics.isLoading,
      statisticsFilter: store.AppFilterStates.statisticsFilters,
      organizations: store.Organizations.data,
      pricingData: store.Statistics.pricingData,
      pricingDataLoading: store.Statistics.pricingDataLoading,
      satisfactionData: store.Statistics.satisfactionData,
      satisfactionLoading: store.Statistics.satisfactionLoading,
    }
  }, mapDispatchToProps)
)(StatisticPage)
