const organizationDetailStyle = (theme) => ({
  logoWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '30px',
  },
  logo: {
    maxHeight: '200px',
    width: 'auto',
    maxWidth: '100%',
  },
})

export default organizationDetailStyle
