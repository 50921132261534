// LOG IN
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_FAIL = 'LOG_IN_FAIL'

// LOG OUT
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL'

// LOADER
export const UPDATE_LOADER_SUCCESS = 'UPDATE_LOADER_SUCCESS'
export const UPDATE_LOCAL_LOADER_SUCCESS = 'UPDATE_LOCAL_LOADER_SUCCESS'

// SETTINGS
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS'

// FILTERS
export const UPDATE_FILTERS_STATE = 'UPDATE_FILTERS_STATE'
