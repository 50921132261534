import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import ProposalConfirmForm from '_component/projectSpecific/proposal/proposalForm/ProposalConfirmForm'

const ProposalConfirmDialog = (props) => {
  const {openConfirmDialog, closeConfirmDialog, handleConfirm, timeFree, ...rest} = props
  return (
    <DialogWrapper
      open={openConfirmDialog}
      handleClose={closeConfirmDialog}
      title={<Trans>Confirm event</Trans>}
      maxWidth="md"
    >
      <ProposalConfirmForm onSubmitConfirm={handleConfirm} timeFree={timeFree} {...rest} />
    </DialogWrapper>
  )
}

ProposalConfirmDialog.propTypes = {
  openConfirmDialog: PropTypes.bool,
  timeFree: PropTypes.bool,
  closeConfirmDialog: PropTypes.func,
  handleConfirm: PropTypes.func,
}

export default ProposalConfirmDialog
