import React from 'react'
import {Trans} from '@lingui/macro'

export const EVENT_PARTICIPANTS_LIST_COLUMNS = [
  {
    id: 'name',
    sortable: true,
    order_key: 'name',
    label: <Trans>Participant name</Trans>,
  },
  {
    id: 'email',
    sortable: true,
    order_key: 'email',
    label: <Trans>Participant email</Trans>,
  },
  {
    id: 'createdAt',
    sortable: true,
    order_key: 'createdAt',
    label: <Trans>Logged at</Trans>,
  },
  {
    id: 'isUnderLine',
    sortable: true,
    order_key: 'isUnderLine',
    label: <Trans>Is underline</Trans>,
  },
  {
    id: 'actions',
    sortable: false,
    order_key: 'actions',
    label: '',
  },
]
