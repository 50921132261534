import React, {useState} from 'react'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {Trans} from '@lingui/macro'
import feedbackStyle from '_asset/global/jss/component/feedback/feedbackStyle'
import {withStyles} from '@material-ui/core/styles'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {feedbackQuestions} from '_component/projectSpecific/auth/feedbackQuestions'
import {Box, Fade, Grid, IconButton, Switch, TextField} from '@material-ui/core'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined'
import MoodIcon from '@material-ui/icons/Mood'
import MoodBadIcon from '@material-ui/icons/MoodBad'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined'
import SentimentVeryDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined'
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined'
import cx from 'classnames'
import {bindActionCreators, compose} from 'redux'
import {createSatisfaction} from '_redux/_action/projectSpecific/eventActions'
import {connect} from 'react-redux'

const FeedbackForm = (props) => {
  const {classes, token, createSatisfaction} = props

  const [step, setStep] = useState(1)
  const [showThankYou, setShowThankYou] = useState(false)
  const numOfQuestions = 8
  const [answers, setAnswers] = useState({
    nonStandardServices: false,
    audioVideoServicesCommentary: '',
    improvementSuggestion: '',
  })

  const handleBack = () => {
    if (step !== 1) {
      setStep((prevState) => prevState - 1)
    }
  }

  const handleNext = () => {
    if (step !== numOfQuestions) {
      setStep((prevState) => prevState + 1)
    }
  }

  const setValueOfQuestion = (value, name) => {
    setAnswers((prevState) => ({...prevState, [name]: value}))
  }

  const renderFieldByType = ({type, name}) => {
    if (type === 'range') {
      const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      const isSelected = (val) => val === answers?.[name]

      return (
        <GridContainer justifyContent={'center'}>
          {values.map((val) => (
            <Grid key={val} item xs={false} className={classes.columnBox}>
              <Grid item xs={12}>
                <IconButton
                  onClick={() => setValueOfQuestion(val, name)}
                  size={'small'}
                  className={cx(
                    isSelected(val)
                      ? val <= 3
                        ? classes.successColor
                        : val > 3 && val <= 7
                        ? classes.neutralColor
                        : classes.errorColor
                      : '',
                    classes.mediumSizedIcons
                  )}
                >
                  {val === 1 ? (
                    <SentimentVerySatisfiedOutlinedIcon />
                  ) : val === 2 ? (
                    <MoodIcon />
                  ) : val === 3 ? (
                    <SentimentSatisfiedOutlinedIcon />
                  ) : val === 4 || val === 5 ? (
                    <SentimentSatisfiedIcon />
                  ) : val === 6 || val === 7 ? (
                    <SentimentDissatisfiedIcon />
                  ) : val === 8 ? (
                    <SentimentDissatisfiedOutlinedIcon />
                  ) : val === 9 ? (
                    <MoodBadIcon />
                  ) : (
                    <SentimentVeryDissatisfiedOutlinedIcon />
                  )}
                </IconButton>
              </Grid>
              <Grid item xs={12} className={classes.textCenter}>
                {val}
              </Grid>
            </Grid>
          ))}
        </GridContainer>
      )
    } else if (type === 'text') {
      return (
        <GridContainer>
          <TextField
            variant={'outlined'}
            fullWidth={true}
            multiline
            rows={4}
            onChange={(e) => setValueOfQuestion(e.target.value, name)}
          />
        </GridContainer>
      )
    } else if (type === 'bool') {
      return (
        <GridContainer alignItems={'center'}>
          <Box sx={{pr: 1}}>
            <Trans>No</Trans>
          </Box>
          <Switch
            color={'primary'}
            checked={answers?.[name] === undefined ? false : answers?.[name]}
            size={'small'}
            onChange={(e) => setValueOfQuestion(e.target.checked, name)}
          />
          <Box sx={{pl: 1}}>
            <Trans>Yes</Trans>
          </Box>
        </GridContainer>
      )
    }
  }

  const isDisabledNext =
    feedbackQuestions?.[step - 1]?.required && !answers[feedbackQuestions?.[step - 1]?.name]

  const handleSubmit = () => {
    return createSatisfaction(answers, token).then(() => {
      setShowThankYou(true)
    })
  }

  return (
    <GridContainer>
      {showThankYou ? (
        <GridContainer justifyContent={'center'} alignItems={'center'}>
          <Box sx={{fontSize: 22, p: 8}}>
            <Trans>Thank you for your response.</Trans>
          </Box>
        </GridContainer>
      ) : (
        <>
          <GridContainer justifyContent={'space-between'}>
            <GridItem xs={false} className={classes.feedbackTitle}>
              <Trans>Satisfaction survey</Trans>
            </GridItem>
            <GridItem
              xs={false}
              className={classes.feedbackQuestionNumber}
            >{`${step}/${numOfQuestions}`}</GridItem>
          </GridContainer>

          <GridContainer className={classes.relativePosition}>
            {feedbackQuestions.map((question, index) => {
              return (
                <Fade key={index} in={step === index + 1} className={classes.absolutePosition}>
                  <GridContainer>
                    <GridItem xs={12} className={classes.heightOfQuestionText}>
                      {`${step}. ${question.question}`}
                      {question.required ? <span className={classes.requiredAstrix}>*</span> : ''}
                    </GridItem>
                    <GridItem xs={12}>{renderFieldByType(question)}</GridItem>
                  </GridContainer>
                </Fade>
              )
            })}
          </GridContainer>

          <GridContainer justifyContent={'space-between'}>
            <GridItem>
              {step !== 1 && (
                <GlobalButton backgroundColor="primary" inverted onClick={handleBack}>
                  <Trans>Back</Trans>
                </GlobalButton>
              )}
            </GridItem>

            {feedbackQuestions?.length === step ? (
              <GridItem>
                <GlobalButton
                  onClick={handleSubmit}
                  backgroundColor="primary"
                  disabled={isDisabledNext}
                >
                  <Trans>Submit</Trans>
                </GlobalButton>
              </GridItem>
            ) : (
              <GridItem>
                <GlobalButton
                  onClick={handleNext}
                  backgroundColor="primary"
                  disabled={isDisabledNext}
                >
                  <Trans>Next</Trans>
                </GlobalButton>
              </GridItem>
            )}
          </GridContainer>
        </>
      )}
    </GridContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({createSatisfaction}, dispatch)
}

export default compose(
  withStyles(feedbackStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(FeedbackForm)
