import React from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import {Box, Button, Card, CardActions, CardContent, CardHeader, Grid} from '@material-ui/core'
import {
  composeValidators,
  required,
  requiredCheckbox,
  validateEmail,
  validateLength,
} from '_helper/projectSpecific/validations'
import TextInput from '_component/global/common/formField/TextInput'
import CustomCheckbox from '_component/global/common/formField/CustomCheckbox'

const NewUserForm = (props) => {
  const {onSubmit} = props

  return (
    <Card>
      <CardHeader title={<Trans>Enter registration information</Trans>} />
      <CardContent>
        <Form
          onSubmit={onSubmit}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Field
                    name="organization_name"
                    label={<Trans>Organization name</Trans>}
                    validate={composeValidators(required, validateLength(100))}
                    component={TextInput}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="first_name"
                    label={<Trans>Administrator name</Trans>}
                    validate={composeValidators(required, validateLength(100))}
                    component={TextInput}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="last_name"
                    label={<Trans>Administrator surname</Trans>}
                    validate={composeValidators(required, validateLength(100))}
                    component={TextInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    label={<Trans>Administrator e-mail</Trans>}
                    validate={composeValidators(required, validateEmail, validateLength(100))}
                    component={TextInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="agrees_to_tos"
                    type="checkbox"
                    label={
                      <Trans>
                        I agree with&nbsp;
                        <a
                          href="https://admin.eventito.com/tos/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          terms of service
                        </a>
                        .
                      </Trans>
                    }
                    validate={composeValidators(requiredCheckbox)}
                    component={CustomCheckbox}
                  />
                </Grid>
              </Grid>
              <CardActions>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                    <Button type="submit" variant="contained" color="primary">
                      <Trans>Register</Trans>
                    </Button>
                  </Box>
                </Grid>
              </CardActions>
            </form>
          )}
        />
      </CardContent>
    </Card>
  )
}

export default NewUserForm

NewUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
