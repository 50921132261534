export const routesMap = {
  tenant: {
    admin: {
      event: '/admin/events',
      calendar: '/admin/events/calendar',
      proposal: '/admin/events/proposals',
      organization: '/admin/organizations',
      topic: '/admin/topics',
      statistic: '/admin/statistics',
    },
    public: {
      onBoarding: '/on-boarding/:token(^.{1,}$)',
    },
  },
  nonTenant: {
    public: {
      registration: '/registration/new-user',
    },
  },
  common: {
    auth: {
      login: '/auth/login',
      forgottenPassword: '/auth/forgotten-password',
      resetPassword: '/auth/reset-password',
      satisfaction: '/auth/satisfaction',
    },
  },
}
