import React from 'react'
import {Trans} from '@lingui/macro'
import {Check, Close, Reply} from '@material-ui/icons'
import GlobalButton from '_component/global/common/button/GlobalButton'
import PropTypes from 'prop-types'
import {Can} from 'App/_provider/authorizedAbility'
import {hasOnlyPartnerRole} from '_helper/global/functions'
import ProposalStatus from '_component/projectSpecific/proposal/proposalList/ProposalStatus'

const ProposalDetailActionsHeader = (props) => {
  const {openConfirmModal, openCounterproposalModal, openDenialModal, status} = props

  const renderProposalDetailActionsHeader = () => {
    if (status === 'COUNTER_PROPOSAL') {
      return (
        <>
          <Can I="counter" a="Proposals">
            <GlobalButton
              backgroundColor="success"
              startIcon={<Reply />}
              onClick={openCounterproposalModal}
            >
              <Trans>Another counter proposal</Trans>
            </GlobalButton>
          </Can>
          <Can I="deny" a="Proposals">
            <GlobalButton backgroundColor="warning" startIcon={<Close />} onClick={openDenialModal}>
              <Trans>Cancel counter proposal</Trans>
            </GlobalButton>
          </Can>
        </>
      )
    } else if (status === 'PENDING_CONFIRMATION') {
      if (hasOnlyPartnerRole) {
        return <ProposalStatus status={status} size="medium" />
      } else {
        return (
          <>
            <Can I="confirm" a="Proposals">
              <GlobalButton
                backgroundColor="success"
                startIcon={<Check />}
                onClick={openConfirmModal}
              >
                <Trans>Confirm</Trans>
              </GlobalButton>
            </Can>
            <Can I="counter" a="Proposals">
              <GlobalButton
                backgroundColor="success"
                startIcon={<Reply />}
                onClick={openCounterproposalModal}
              >
                <Trans>Counter proposal</Trans>
              </GlobalButton>
            </Can>
            <Can I="deny" a="Proposals">
              <GlobalButton
                backgroundColor="warning"
                startIcon={<Close />}
                onClick={openDenialModal}
              >
                <Trans>Reject</Trans>
              </GlobalButton>
            </Can>
          </>
        )
      }
    } else if (status === 'DENIED' || status === 'CONFIRMED') {
      return <ProposalStatus status={status} size="medium" />
    } else {
      return <></>
    }
  }

  return renderProposalDetailActionsHeader()
}

ProposalDetailActionsHeader.propTypes = {
  openConfirmModal: PropTypes.func,
  openCounterproposalModal: PropTypes.func,
  openDenialModal: PropTypes.func,
}

export default ProposalDetailActionsHeader
