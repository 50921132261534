import React, {useEffect, useState} from 'react'
import {getDate} from '_helper/projectSpecific/calendarFunctions'
import {connect} from 'react-redux'
import {compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import calendarStyle from '_asset/global/jss/component/calendar/calendarStyle'
import moment from 'moment'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'
import CalendarDayEvents from '_component/projectSpecific/event/eventList/CalendarDayEvents'
import {routesMap} from '_config/routesMap'
import {Trans} from '@lingui/macro'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import {redirect} from '_helper/global/functions'
import BeachAccessIcon from '@material-ui/icons/BeachAccess'
import {Badge, Tooltip} from '@material-ui/core'
import CalendarDayProposal from '_component/projectSpecific/event/eventList/CalendarDayProposal'

const ReservationCalendarCell = (props) => {
  const {cell, showAllEvents, renderAll, proposalCal, proposalCalLoading} = props

  const [cellState, setCellState] = useState()

  useEffect(() => {
    if (!proposalCalLoading) {
      const newProposalState = proposalCal?.find(
        (day) =>
          moment(day.date).format(DATABASE_DATE_FORMAT) ===
          (cell.date && cell.date.format(DATABASE_DATE_FORMAT))
      )

      const hadProposals = !!newProposalState?.eventProposals

      const events = hadProposals
        ? newProposalState?.eventProposals?.sort((a, b) => moment(a.from) - moment(b.from))
        : []

      setCellState({
        date: newProposalState?.date,
        events: events,
      })
    }
  }, [cell, proposalCal, proposalCalLoading])

  const openEventDetail = (event) => () => {
    if (authorizedAbility.can('read', 'Event')) {
      redirect(`${routesMap.tenant.admin.calendar}/${event.id}/date/${getDate(event.from)}`)
    }
  }

  const openProposalDetail = (proposal) => () => {
    if (authorizedAbility.can('read', 'Proposals')) {
      redirect(`${routesMap.tenant.admin.proposal}/${proposal.id}`)
    }
  }

  const isTodayClass = () => {
    if (getDate(cell.date) === moment().format(DATABASE_DATE_FORMAT)) {
      return 'today-cell'
    }
  }

  const renderCalendarDayEvents = () => {
    const calendarDaysToRender = []
    const numberOfEvents = cellState.events?.length

    const daysToRender = numberOfEvents > 2 ? (renderAll ? numberOfEvents : 2) : numberOfEvents

    for (let i = 0; i < daysToRender; i++) {
      if (cellState.events?.[i]?.type === 'EVENT') {
        calendarDaysToRender.push(
          <CalendarDayEvents
            openEventDetail={openEventDetail}
            key={`event-${i}`}
            event={cellState.events?.[i]}
          />
        )
      } else {
        calendarDaysToRender.push(
          <CalendarDayProposal
            openEventDetail={openProposalDetail}
            key={`proposal-${i}`}
            proposal={cellState.events?.[i]}
          />
        )
      }
    }

    if (numberOfEvents > 2) {
      if (!renderAll) {
        calendarDaysToRender.push(
          <div className="show-more-less" key="show-all" onClick={showAllEvents}>
            <Trans>show more</Trans>
          </div>
        )
      } else {
        calendarDaysToRender.push(
          <div className="show-more-less" key="show-all" onClick={showAllEvents}>
            <Trans>show less</Trans>
          </div>
        )
      }
    }

    return calendarDaysToRender
  }

  return (
    <td className={isTodayClass()}>
      <div className="cell-wrapper">
        <div className="cell-date">
          <span className="cell-event-badges">
            <Tooltip title={<Trans>Events</Trans>}>
              <Badge
                badgeContent={cellState?.events?.filter((e) => e.type === 'EVENT')?.length}
                color="primary"
              />
            </Tooltip>
            <Tooltip title={<Trans>Proposals</Trans>}>
              <Badge
                badgeContent={cellState?.events?.filter((e) => e.type === 'PROPOSAL')?.length}
                className="cell-event-badges-warning"
              />
            </Tooltip>
          </span>
          {cell.date && cell.date.date()}
          {cellState && cellState.businessDayOff && (
            <BeachAccessIcon className={'cell-date-holiday-icon'} />
          )}
        </div>
        {cellState && cellState.businessDayOff && (
          <div className="calendar-event-wrapper day-off">{cellState.businessDayOff.name}</div>
        )}
        {cellState && renderCalendarDayEvents()}
      </div>
    </td>
  )
}

export default compose(
  connect((store) => {
    return {
      proposalCal: store.Proposals.calendar,
      proposalCalLoading: store.Proposals.calendarLoading,
    }
  }, {}),
  withStyles(calendarStyle)
)(ReservationCalendarCell)
