import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import TableToolbarActionsSelectedWrapper from '_component/global/common/wrapper/TableToolbarActionsSelectedWrapper'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'

const GlobalTableToolbarSelectedWrapper = (props) => {
  const {classes, numSelected, desktopActions, mobileActions} = props

  const actionsWrapper = (
    <TableToolbarActionsSelectedWrapper desktopActions={desktopActions} mobileActions={mobileActions} />
  )

  return (
    <>
      {/*view for Mobile*/}
      <Hidden mdUp implementation="css">
        <Toolbar
          className={cx(classes.toolbar, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <div className={classes.toolbarTitle}>
            {numSelected > 0 ? (
              <>
                <div>{actionsWrapper}</div>
                <Typography color="inherit" variant="subtitle1">
                  {numSelected} selected
                </Typography>
              </>
            ) : (
              <div className={cx(classes.toolbarActions)}>{actionsWrapper}</div>
            )}
          </div>
        </Toolbar>
      </Hidden>

      {/*view for Desktop*/}
      <Hidden mdDown implementation="css">
        <Toolbar
          className={cx(classes.toolbar, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          {numSelected > 0 ? (
            <div className={cx(classes.toolbarTitle, classes.toolbarSelected)}>
              <Typography color="inherit" variant="subtitle1">
                {numSelected} selected
              </Typography>
              <div className={classes.toolbarActions}>{actionsWrapper}</div>
            </div>
          ) : (
            <div className={cx(classes.toolbarActions, classes.toolbarContent)}>{actionsWrapper}</div>
          )}
        </Toolbar>
      </Hidden>
    </>
  )
}

GlobalTableToolbarSelectedWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number,
  desktopActions: PropTypes.node,
  mobileActions: PropTypes.node,
}

export default withStyles(globalWrapperStyles)(GlobalTableToolbarSelectedWrapper)
