import React from 'react'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import {withStyles} from '@material-ui/core'

const DetailItemWrapper = (props) => {
  const {classes, children} = props

  return <div className={classes.detailItemWrapper}>{children}</div>
}

export default withStyles(globalWrapperStyles)(DetailItemWrapper)
