import {
  dangerColor,
  grayColor,
  hexToRgb,
  primaryColor,
  validationErrorFontColor,
  whiteColor,
} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'

export const globalFormFieldStyles = (theme) => ({
  root: {
    '& .MuiFormControlLabel-root': {
      color: grayColor[0],
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid ' + grayColor[4],
    },
    '& .MuiInput-underline:not(.Mui-error):after': {
      borderBottom: '2px solid ' + grayColor[0],
      transitionDuration: '150ms',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid ' + grayColor[4],
      transition: 'border-bottom 50ms',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: primaryColor[0],
    },
    '& .MuiFormHelperText-root': {
      ...validationErrorFontColor,
    },
    '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
      {
        '-webkit-box-shadow': '0 0 0 30px white inset !important',
      },
  },
  numberAlignRight: {
    '& input':{
      textAlign: 'right'
    }
  },
  passwordIcon: {
    '& svg': {
      fill: 'rgba(0, 0, 0, 0.26)',
    },
  },
  // datetime
  dateTimePicker: {
    width: '100%',
    '& > div': {
      width: '100%',
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid ' + grayColor[4],
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid ' + grayColor[4],
      },
    },
    '& .MuiFormHelperText-root': {
      ...validationErrorFontColor,
    },
  },
  helperTextMargin: {
    margin: 0,
  },
  labelRootError: {
    color: dangerColor[0] + ' !important',
  },
  // select
  formSelectControl: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      ...validationErrorFontColor,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  customSelectClasses: {
    '&.MuiInput-underline:before': {
      borderBottom: '1px solid ' + grayColor[4],
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid ' + grayColor[4],
    },
  },
  itemStyle: {
    '& .MuiIconButton-root': {
      padding: '6px !important',
    },
  },
  // switcher
  checked: {},
  helperTextSwitcherMargin: {
    marginTop: '0px !important',
  },
  formSwitchControl: {
    position: 'relative',
    width: '100%',
  },
  switcher: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    color: grayColor[0],
    fontSize: '1rem',
    fontWeight: 400,
  },
  switcherJustifyStart: {
    justifyContent: 'flex-start',
  },
  switcherJustifyEnd: {
    justifyContent: 'flex-end',
  },
  switcherZeroMarginTop: {
    marginTop: 0,
  },
  // fulltext
  fullTextSearch: {
    fontSize: '1.1rem',
    width: '250px',
    '& .MuiInput-input': {
      fontSize: '1rem',
      height: '1.5rem',
      // width: '20rem',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '12px',
    },
    '& .MuiInputBase-input': {
      paddingBottom: '6px',
    },
  },
  // checkbox
  noCheckboxMargin: {
    '& .MuiFormControlLabel-root': {
      margin: '0px',
    },
  },
  checkboxJustifyStart: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  checkboxJustifyEnd: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  },
  customRadioGroupWrapper: {
    width: '100%',
    '& .MuiFormControlLabel-root': {
      color: grayColor[0],
      whiteSpace: 'nowrap',
    },
    '& .PrivateRadioButtonIcon-root.PrivateRadioButtonIcon-checked': {
      fill: primaryColor[0],
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: grayColor[5],
    },
    '& .MuiFormHelperText-root': {
      ...validationErrorFontColor,
    },
  },
  flexRowDir: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioGroupFlexDirection: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      // flexWrap: 'nowrap',
    },
  },
  resetPadding: {
    padding: '0px !important',
  },
  // upload image / logo styles
  logoContainer: {
    margin: '10px 0',
  },
  logoResponsive: {
    maxWidth: '100%',
  },
  logo: {
    maxHeight: '200px',
    width: 'auto',
    maxWidth: '100%',
  },
  uploadWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& label': {
      minHeight: 'auto',
      minWidth: 'auto',
      backgroundColor: whiteColor,
      color: primaryColor[0],
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(primaryColor[0]) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(primaryColor[0]) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(primaryColor[0]) +
        ', 0.12)',
      border: 'none',
      borderRadius: BORDER_RADIUS,
      position: 'relative',
      padding: '8px 30px',
      margin: '.3125rem 1px',
      fontSize: '12px',
      fontWeight: '400',
      textTransform: 'uppercase',
      letterSpacing: '0',
      willChange: 'box-shadow, transform',
      transition:
        'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      lineHeight: '1.42',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      '&:hover,&:focus': {
        color: whiteColor,
        backgroundColor: primaryColor[0],
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(primaryColor[0]) +
          ', 0.42), 0 4px 23px 0px rgba(' +
          hexToRgb(primaryColor[0]) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(primaryColor[0]) +
          ', 0.2)',
      },
      '&:first-of-type': {
        marginRight: '10px',
      },
    },
  },
  fileInput: {
    display: 'none',
  },
  // chip field
  chipWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '90%',
  },
})
