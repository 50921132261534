import React, {useCallback, useEffect, useState} from 'react'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import {Business} from '@material-ui/icons'
import TableWrapper from '_component/global/common/wrapper/TableWrapper'
import {bindActionCreators, compose} from 'redux'
import {
  createOrganization,
  deleteOrganization,
  editOrganization,
  getListOrganizations,
} from '_redux/_action/projectSpecific/organizationActions'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import WarningDialog from '_component/global/common/dialog/WarningDialog'
import Pagination from '_component/global/common/table/Pagination'
import {routesMap} from '_config/routesMap'
import {fireSuccessToast, redirect, unauthorizedRedirectToDashboard} from '_helper/global/functions'
import {ORGANIZATION_LIST_COLUMNS} from '_component/projectSpecific/organization/organizationList/_organizationTableColumns'
import OrganizationListActionsHeader from '_component/projectSpecific/organization/organizationList/OrganizationListActionsHeader'
import OrganizationCreateDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationCreateDialog'
import OrganizationEditDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationEditDialog'
import OrganizationRow from '_component/projectSpecific/organization/organizationList/OrganizationRow'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import {exportAllOrganizationsAddresses} from '_redux/_action/projectSpecific/organizationAddressAction'
import {exportAllOrganizationsContacts} from '_redux/_action/projectSpecific/organizationContactAction'
import download from 'downloadjs'

const OrganizationListPage = (props) => {
  const {organizations, organizationsMeta, organizationsFilter, globalSettings} = props
  const {
    getListOrganizations,
    deleteOrganization,
    updateLocalLoader,
    createOrganization,
    editOrganization,
    exportAllOrganizationsAddresses,
    exportAllOrganizationsContacts,
  } = props

  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [editId, setEditId] = useState(null)
  const [warningDialog, setWarningDialog] = useState({visible: false, row: null})
  const [pageNumber, setPageNumber] = useState(0)
  const [filter, setFilter] = useState(organizationsFilter.filter)
  const [logoFile, setLogoFile] = useState(null)
  const [logoChanged, setLogoChanged] = useState(false)
  const [sort, setSort] = useState({
    orderBy: organizationsFilter.orderBy,
    orderDirection: organizationsFilter.orderDirection,
  })

  const fetchListOrganization = useCallback(() => {
    updateLocalLoader(true)
    return getListOrganizations(
      pageNumber * globalSettings.rowsPerPage,
      globalSettings.rowsPerPage,
      sort.orderBy,
      sort.orderDirection,
      filter && filter.filter
    )
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [getListOrganizations, globalSettings, pageNumber, sort, filter, updateLocalLoader])

  useEffect(() => {
    if (authorizedAbility.cannot('read', 'Organizations')) {
      unauthorizedRedirectToDashboard(<Trans>You are not authorized to list organizations.</Trans>)
    }
    fetchListOrganization()
  }, [fetchListOrganization, sort, pageNumber, filter])

  const handleOpenCreate = () => {
    setOpenCreate(true)
  }
  const handleCloseCreate = () => {
    setOpenCreate(false)
  }

  const handleOrganizationCreate = (values) => {
    delete values.logoUrl
    if (logoChanged) {
      values.logoData = logoFile
    }
    createOrganization(values).then(() => {
      setOpenCreate(false)
      fetchListOrganization()
      fireSuccessToast(<Trans>New organization created.</Trans>)
    })
  }

  const handleOpenEdit = (organizationId) => () => {
    setOpenEdit(true)
    setEditId(organizationId)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
    setEditId(null)
  }

  const handleOrganizationEdit = (values) => {
    delete values.logoUrl
    if (logoChanged) {
      values.logoData = logoFile
    }
    editOrganization(editId, values).then(() => {
      setOpenEdit(false)
      fetchListOrganization()
      fireSuccessToast(<Trans>Organization updated.</Trans>)
    })
  }

  const confirmDeleteOrganization = () => {
    updateLocalLoader(true)
    deleteOrganization(warningDialog.row.id)
      .then(() => {
        setOpenEdit(false)
        setWarningDialog({visible: false, row: null})
        updateLocalLoader(false)
        fetchListOrganization()
        fireSuccessToast(<Trans>Organization {warningDialog.row.name} deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const goToDetailPage = (id) => () => {
    return redirect(`${routesMap.tenant.admin.organization}/${id}`)
  }

  const setWarningDialogState = (row) => (event) => {
    setWarningDialog({visible: true, row: row})
  }

  const handleSetLogoFile = (file) => {
    setLogoFile(file)
  }

  const handleSetFilter = (values) => {
    setFilter(values)
  }

  const handleExportAllContacts = () => {
    updateLocalLoader(true)
    return exportAllOrganizationsContacts()
      .then((res) => {
        updateLocalLoader(false)
        download(
          res.data,
          `All contacts.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
        fireSuccessToast(<Trans>All contacts exported.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const handleExportAllAddresses = () => {
    updateLocalLoader(true)
    return exportAllOrganizationsAddresses()
      .then((res) => {
        updateLocalLoader(false)
        download(
          res.data,
          `All addresses.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
        fireSuccessToast(<Trans>All addresses exported.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  return (
    <AdminPageWrapper>
      <WarningDialog
        open={warningDialog.visible}
        handleClose={() => setWarningDialog({visible: false, row: null})}
        handleConfirm={confirmDeleteOrganization}
        message={
          warningDialog.row && <Trans>You will delete organization {warningDialog.row.name}!</Trans>
        }
      />
      <OrganizationCreateDialog
        openCreate={openCreate}
        closeCreate={handleCloseCreate}
        handleCreateSubmit={handleOrganizationCreate}
        logoChanged={logoChanged}
        logoFile={logoFile}
        handleLogoChanged={setLogoChanged}
        handleLogoFile={setLogoFile}
      />
      <OrganizationEditDialog
        openEdit={openEdit}
        closeEdit={handleCloseEdit}
        handleEditSubmit={handleOrganizationEdit}
        id={editId}
        logoChanged={logoChanged}
        logoFile={logoFile}
        handleLogoChanged={setLogoChanged}
        handleLogoFile={handleSetLogoFile}
        deleteItem={setWarningDialogState}
      />
      <ContentAdminPageWrapper
        pageTitle={<Trans>Partner organizations</Trans>}
        iconPageHeader={<Business />}
        toolbar={false}
        actionsHeader={
          <OrganizationListActionsHeader
            handleSearch={handleSetFilter}
            openDialog={handleOpenCreate}
            exportAllContacts={handleExportAllContacts}
            exportAllAddresses={handleExportAllAddresses}
          />
        }
      >
        <TableWrapper
          onRequestSort={setSort}
          selectable={false}
          columns={ORGANIZATION_LIST_COLUMNS}
          orderDirection={organizationsFilter.orderDirection}
          orderBy={organizationsFilter.orderBy}
          tablePagination={
            <Pagination
              count={organizationsMeta.totalCount}
              page={pageNumber}
              onChangePage={setPageNumber}
              rowsPerPage={globalSettings.rowsPerPage}
            />
          }
        >
          {organizations?.map((row) => (
            <OrganizationRow
              row={row}
              key={row.id}
              onDetailClick={goToDetailPage(row.id)}
              onEditClick={handleOpenEdit(row.id)}
              onDeleteClick={setWarningDialogState(row)}
            />
          ))}
        </TableWrapper>
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

OrganizationListPage.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationsMeta: PropTypes.object,
  organizationsFilter: PropTypes.object,
  getListOrganizations: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  globalSettings: PropTypes.object,
  updateLocalLoader: PropTypes.func,
  createOrganization: PropTypes.func,
  editOrganization: PropTypes.func,
  exportAllOrganizationsAddresses: PropTypes.func,
  exportAllOrganizationContacts: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteOrganization,
      getListOrganizations,
      updateLocalLoader,
      createOrganization,
      editOrganization,
      exportAllOrganizationsAddresses,
      exportAllOrganizationsContacts,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      organizations: store.Organizations.data,
      organizationsMeta: store.Organizations.meta,
      organizationsFilter: store.AppFilterStates.listOrganizationsFilters,
      globalSettings: store.GlobalSettings,
    }
  }, mapDispatchToProps)
)(OrganizationListPage)
