import {
  FETCH_ORGANIZATION_USERS_REQUEST,
  FETCH_ORGANIZATION_USERS_SUCCESS,
  FETCH_ORGANIZATION_USER_REQUEST,
  FETCH_ORGANIZATION_USER_SUCCESS,
} from '_redux/_action/projectSpecific/types'

const initListOrganizationUsersState = {
  isLoading: true,
  data: [],
  meta: {},
}
const initOrganizationUserState = {
  isLoading: true,
  data: {},
}

export const ListOrganizationUsersReducer = (state = initListOrganizationUsersState, action = null) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_USERS_REQUEST:
      return {...state, isLoading: true}
    case FETCH_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        meta: action.data.meta,
        isLoading: false,
      }
    default:
      return state
  }
}

export const OrganizationUserReducer = (state = initOrganizationUserState, action = null) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_USER_REQUEST:
      return {...state, isLoading: true}
    case FETCH_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }

    default:
      return state
  }
}
