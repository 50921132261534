import GridItem from '_component/global/common/grid/GridItem'
import {Field} from 'react-final-form'
import {Trans} from '@lingui/macro'
import DateInput from '_component/global/common/formField/DateInput'
import {composeValidators, required, validateMaxValue} from '_helper/projectSpecific/validations'
import CustomRadioGroup from '_component/global/common/formField/CustomRadioGroup'
import {TIME_SLOTS_LIST} from '_helper/projectSpecific/constants'
import TimeInput from '_component/global/common/formField/TimeInput'
import React from 'react'
import moment from 'moment'
import NumberInput from '_component/global/common/formField/NumberInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import GridContainer from '_component/global/common/grid/GridContainer'
import WarningMessage from '_component/projectSpecific/common/WarningMessage'

const ProposalActionsFormFields = (props) => {
  const {
    index,
    topic,
    handleDisableDates,
    timeSlotOptions,
    selectedDate,
    customTimeSlot,
    timeFreeCounterProposals,
  } = props
  const nameSpace = index !== undefined ? `proposalActions[${index}]` : `proposalActions[${0}]`

  return (
    <>
      <GridItem xs={12} sm={6}>
        <Field
          name={`${nameSpace}.date`}
          label={<Trans>Date</Trans>}
          component={DateInput}
          shouldDisableDate={handleDisableDates}
          minDate={moment(topic.from)}
          maxDate={moment(topic.to)}
          disablePast
          validate={required}
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <Field
          name={`${nameSpace}.customPreparationLength`}
          label={<Trans>Length of preparation</Trans>}
          component={NumberInput}
          type="number"
          max={720}
          min={0}
          step={1}
          validate={validateMaxValue(720)}
          endAdornment={
            <InputAdornment position="end">
              <Trans>Min.</Trans>
            </InputAdornment>
          }
        />
      </GridItem>
      <GridItem xs={12}>
        <Field
          name={`${nameSpace}.timeSlot`}
          component={CustomRadioGroup}
          options={TIME_SLOTS_LIST}
          availableOptions={timeSlotOptions}
          validate={required}
          disabled={!selectedDate}
          direction="row"
          xs={6}
        />
      </GridItem>
      {customTimeSlot && (
        <GridContainer>
          <GridItem xs={6}>
            <Field
              name={`${nameSpace}.customTimeFrom`}
              label={<Trans>Custom time</Trans>}
              component={TimeInput}
              validate={required}
            />
          </GridItem>
          <GridItem xs={6}>
            <Field
              name={`${nameSpace}.customTimeLength`}
              label={<Trans>Length of event</Trans>}
              component={NumberInput}
              type="number"
              max={720}
              min={30}
              step={1}
              validate={composeValidators(required, validateMaxValue(720))}
              endAdornment={
                <InputAdornment position="end">
                  <Trans>Min.</Trans>
                </InputAdornment>
              }
            />
          </GridItem>
        </GridContainer>
      )}
      {timeFreeCounterProposals === false && (
        <WarningMessage
          warningMessage={<Trans>The time of this event overlaps with another event!</Trans>}
        />
      )}
    </>
  )
}

export default ProposalActionsFormFields
