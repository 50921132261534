import React, {useCallback, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Trans} from '@lingui/macro'
import {Assignment} from '@material-ui/icons'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import GridContainer from '_component/global/common/grid/GridContainer'
import YearSwitcher from '_component/projectSpecific/common/YearSwitcher'
import {fireSuccessToast, unauthorizedRedirectToDashboard} from '_helper/global/functions'
import {
  createTopic,
  deleteTopic,
  editTopic,
  getTopicsCalendar,
} from '_redux/_action/projectSpecific/topicActions'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import TopicCalendarItems from '_component/projectSpecific/topic/topicList/TopicCalendarItems'
import TopicCreateDialog from '_component/projectSpecific/topic/topicDetail/TopicCreatDialog'
import TopicEditDialog from '_component/projectSpecific/topic/topicDetail/TopicEditDialog'
import PropTypes from 'prop-types'
import WarningDialog from '_component/global/common/dialog/WarningDialog'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'
import moment from 'moment'

const TopicsCalendarPage = (props) => {
  const {year, isCalendarLoaded, topicsCalendar} = props
  const {getTopicsCalendar, createTopic, editTopic, deleteTopic, updateLocalLoader} = props

  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [editId, setEditId] = useState(null)
  const [warningDialog, setWarningDialog] = useState({visible: false, topic: null})
  const [createMonth, setCreateMonth] = useState(null)

  const fetchTopicsCalendar = useCallback(() => {
    updateLocalLoader(true)
    getTopicsCalendar(year)
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [getTopicsCalendar, updateLocalLoader, year])

  useEffect(() => {
    if (authorizedAbility.can('read', 'Topics')) {
      fetchTopicsCalendar()
    }
  }, [fetchTopicsCalendar, year])

  const handleOpenCreate = (month) => (event) => {
    setCreateMonth(month)
    setOpenCreate(true)
  }
  const handleOpenEdit = (topic) => () => {
    setOpenEdit(true)
    setEditId(topic.id)
  }

  const prepareData = (data) => {
    return {
      ...data,
      from: moment(data.from).format(DATABASE_DATE_FORMAT),
      to: moment(data.to).format(DATABASE_DATE_FORMAT),
    }
  }

  const handleTopicCreate = (data) => {
    const dataToSubmit = prepareData(data)
    createTopic(dataToSubmit).then(() => {
      setOpenCreate(false)
      fetchTopicsCalendar()
      fireSuccessToast(<Trans>New topic created.</Trans>)
    })
  }

  const handleTopicEdit = (editId) => (data) => {
    const dataToSubmit = prepareData(data)
    editTopic(editId, dataToSubmit).then(() => {
      setOpenEdit(false)
      fetchTopicsCalendar()
      fireSuccessToast(<Trans>Topic updated.</Trans>)
    })
  }

  const confirmDeleteTopic = () => {
    updateLocalLoader(true)
    deleteTopic(editId)
      .then(() => {
        setOpenEdit(false)
        setWarningDialog({visible: false, topic: null})
        updateLocalLoader(false)
        fetchTopicsCalendar()
        fireSuccessToast(<Trans>Topic deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const setWarningDialogData = (topic) => (event) => {
    setWarningDialog({visible: true, topic: topic})
  }

  useEffect(() => {
    if (authorizedAbility.cannot('read', 'Topics')) {
      unauthorizedRedirectToDashboard(<Trans>You are not authorized to list topics.</Trans>)
    }
  })

  return (
    <AdminPageWrapper>
      <WarningDialog
        open={warningDialog.visible}
        handleClose={() => setWarningDialog({visible: false, topic: null})}
        handleConfirm={confirmDeleteTopic}
        message={
          warningDialog.topic && <Trans>You will delete topic {warningDialog.topic.name}!</Trans>
        }
      />
      <TopicCreateDialog
        year={year}
        month={createMonth}
        openCreate={openCreate}
        closeCreate={() => setOpenCreate(false)}
        handleCreateSubmit={handleTopicCreate}
      />
      <TopicEditDialog
        openEdit={openEdit}
        closeEdit={() => setOpenEdit(false)}
        handleEditSubmit={handleTopicEdit(editId)}
        editId={editId}
        handleDeleteItem={setWarningDialogData}
      />
      <ContentAdminPageWrapper pageTitle={<Trans>Topics</Trans>} iconPageHeader={<Assignment />}>
        <GridContainer>
          <YearSwitcher />
          {isCalendarLoaded && (
            <TopicCalendarItems
              items={topicsCalendar.data}
              actualYear={year}
              openCreateItem={handleOpenCreate}
              openEditItem={handleOpenEdit}
            />
          )}
        </GridContainer>
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

TopicsCalendarPage.propTypes = {
  year: PropTypes.node,
  isCalendarLoading: PropTypes.bool,
  topicsCalendar: PropTypes.object,
  getTopicsCalendar: PropTypes.func,
  createTopic: PropTypes.func,
  editTopic: PropTypes.func,
  deleteTopic: PropTypes.func,
  updateLocalLoader: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocalLoader,
      getTopicsCalendar,
      createTopic,
      editTopic,
      deleteTopic,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      year: store.AppFilterStates.topicsCalendarFilters.year,
      topicsCalendar: store.TopicsCalendar.data,
      isCalendarLoaded: !store.TopicsCalendar.isLoading,
    }
  }, mapDispatchToProps)
)(TopicsCalendarPage)
