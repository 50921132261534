import httpClient from '_helper/global/httpClient'
import {
  FETCH_ORGANIZATION_ADDRESS_REQUEST,
  FETCH_ORGANIZATION_ADDRESS_SUCCESS,
  FETCH_ORGANIZATION_ADDRESSES_REQUEST,
  FETCH_ORGANIZATION_ADDRESSES_SUCCESS,
} from '_redux/_action/projectSpecific/types'
import {globalApiErrorHandler} from '_helper/global/functions'
import endpoints from '_config/endpoints'
import {UPDATE_FILTERS_STATE} from '_redux/_action/global/types'
import React from 'react'

export function getOrganizationAddresses(
  id,
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc'
) {
  return (dispatch) => {
    const listOrganizationAddressesFilters = {
      listOrganizationAddressesFilters: {
        orderBy,
        orderDirection,
      },
    }
    dispatch({type: UPDATE_FILTERS_STATE, data: listOrganizationAddressesFilters})
    dispatch({type: FETCH_ORGANIZATION_ADDRESSES_REQUEST})
    return httpClient
      .get(`${endpoints.organizations}${id}/addresses`, {
        limit,
        offset,
        orderBy: orderBy ? orderBy : null,
        orderDirection: orderBy ? orderDirection.toUpperCase() : null,
      })
      .then((response) => {
        dispatch({
          type: FETCH_ORGANIZATION_ADDRESSES_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getOrganizationAddress(id, addressId) {
  return (dispatch) => {
    dispatch({type: FETCH_ORGANIZATION_ADDRESS_REQUEST})
    return httpClient
      .get(`${endpoints.organizations}${id}/addresses/${addressId}`)
      .then((response) => {
        dispatch({
          type: FETCH_ORGANIZATION_ADDRESS_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function createOrganizationAddress(organizationId, formParams) {
  return function () {
    return httpClient
      .post(`${endpoints.organizations}${organizationId}/addresses`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function editOrganizationAddress(organizationId, addressId, formParams) {
  return function () {
    return httpClient
      .put(`${endpoints.organizations}${organizationId}/addresses/${addressId}`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function deleteOrganizationAddress(organizationId, addressId) {
  return function () {
    return httpClient
      .del(`${endpoints.organizations}${organizationId}/addresses/${addressId}`)
      .catch(globalApiErrorHandler)
  }
}

export function exportOrganizationAddresses(organizationId) {
  return function () {
    return httpClient
      .get(
        `${endpoints.organizations}${organizationId}/addresses/export`,
        {}, // empty data params
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      )
      .catch(globalApiErrorHandler)
  }
}

export function exportAllOrganizationsAddresses() {
  return function () {
    return httpClient
      .get(
        `${endpoints.organizations}addresses/export`,
        {}, // empty data params
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      )
      .catch(globalApiErrorHandler)
  }
}
