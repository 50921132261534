import {
  grayColor,
  primaryColor,
  validationErrorFontColor,
  whiteColor,
} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'

export const stepperFormStyle = (theme) => ({
  spaceConfigurationContent: {
    backgroundColor: grayColor[3] + '30',
    border: `1px solid ${grayColor[3]}`,
    color: whiteColor,
    borderRadius: BORDER_RADIUS,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '40px',
    height: '100%',
    padding: '5px',
    '& img': {
      height: 'auto',
      width: '100%',
    },
  },
  spaceConfigurationItemClickable: {
    cursor: 'pointer',
  },
  spaceConfigurationItem: {
    padding: '5px !important',
    height: '100%',
  },
  spaceConfigurationItemSelected: {
    border: `1px solid ${primaryColor[0]}`,
    '-webkit-box-shadow': '0px 0px 4px 0px rgba(226,0,116,1)',
    '-moz-box-shadow': '0px 0px 4px 0px rgba(226,0,116,1)',
    'box-shadow': '0px 0px 4px 0px rgba(226,0,116,1)',
  },
  spaceConfigurationItemNonSelected: {
    border: `1px solid ${grayColor[3]}`,
  },
  spaceConfigurationLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      color: primaryColor[0],
      cursor: 'pointer',
    },
  },
  spaceConfigurationContainer: {
    margin: '10px 0',
  },
  spaceConfigurationError: {
    ...validationErrorFontColor,
  },
  resetPadding: {
    padding: '0px !important',
  },
  privacyPadding: {
    padding: '0px 0px 0px 20px!important',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementBox: {
    border: `1px solid ${primaryColor[0]}`,
    borderRadius: BORDER_RADIUS,
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '10px',
    '&:last-of-type': {
      marginBottom: '0px',
    },
  },
  requirementNoPaddingBox: {
    border: `1px solid ${primaryColor[0]}`,
    borderRadius: BORDER_RADIUS,
    marginBottom: '10px',
    '&:last-of-type': {
      marginBottom: '0px',
    },
  },
  paddingRight: {
    padding: '0px 10px 0px 0px!important',
  },
  paddingLeft: {
    padding: '0px 0px 0px 10px!important',
  },
  recurrenceHeaders: {
    color: primaryColor[0],
  },
  proposalPlatformSwitcher: {
    marginBottom: '20px',
  },
})
