import React, {useCallback, useEffect, useState} from 'react'
import calendarStyle from '_asset/global/jss/component/calendar/calendarStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  createCalendarGrid,
  fillCalendarWithDates,
  getFirstDayOfWeek,
} from '_helper/projectSpecific/calendarFunctions'
import PropTypes from 'prop-types'
import {DAYS} from '_helper/projectSpecific/constants'
import EventCalendarCell from '_component/projectSpecific/event/eventList/EventCalendarCell'
import {bindActionCreators, compose} from 'redux'
import {getEventsCalendar} from '_redux/_action/projectSpecific/eventActions'
import {connect} from 'react-redux'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'

const EventCalendar = (props) => {
  const {selectedYear, selectedMonth, getEventsCalendar, updateLocalLoader, classes, filter} = props

  const [grid, setGrid] = useState(createCalendarGrid())
  const [showAllRow, setShownRows] = useState([])

  const fetchEventsCalendar = useCallback(() => {
    const from = getFirstDayOfWeek(selectedYear, selectedMonth)
    const to = from.clone().add(6, 'weeks').subtract(1, 'day')
    updateLocalLoader(true)

    getEventsCalendar(
      from.format(DATABASE_DATE_FORMAT),
      to.format(DATABASE_DATE_FORMAT),
      filter && filter.organizationId,
      filter && filter.topicId,
      filter && filter.spaceConfiguration,
      filter && filter.organizer
    ).finally(() => updateLocalLoader(false))
  }, [filter, getEventsCalendar, selectedYear, selectedMonth, updateLocalLoader])

  const showAllEvents = (rowIndex) => () => {
    const isAlreadyShown = showAllRow.includes(rowIndex)
    if (isAlreadyShown) {
      setShownRows((prevState) => [...prevState.filter((index) => index !== rowIndex)])
    } else {
      setShownRows((prevState) => [...prevState, rowIndex])
    }
  }

  useEffect(() => {
    fillCalendarWithDates(selectedYear, selectedMonth, grid, setGrid)
    fetchEventsCalendar()
    return () => {
      setShownRows([])
    }
  }, [fetchEventsCalendar, selectedMonth, selectedYear])

  return (
    <div className={classes.tableWrapper}>
      <table>
        <thead>
          <tr>
            {DAYS.map((day, dayIndex) => (
              <th key={dayIndex}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {grid?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row?.map((cell, colIndex) => (
                <EventCalendarCell
                  cell={cell}
                  key={`${rowIndex}-${colIndex}`}
                  renderAll={showAllRow.includes(rowIndex)}
                  showAllEvents={showAllEvents(rowIndex)}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

EventCalendar.propTypes = {
  selectedYear: PropTypes.number,
  selectedMonth: PropTypes.number,
  classes: PropTypes.object,
  getEventsCalendar: PropTypes.func,
  updateLocalLoader: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEventsCalendar,
      updateLocalLoader,
    },
    dispatch
  )
}

export default compose(
  connect(() => {
    return {}
  }, mapDispatchToProps),
  withStyles(calendarStyle)
)(EventCalendar)
