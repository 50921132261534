import {darkText} from '_asset/global/jss/styleHelpers'
import {grayColor, primaryColor, whiteColor} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'

export const topicsCalendarStyle = (theme) => ({
  topicGridItem: {
    padding: '3px !important',
    position: 'relative',
  },
  topicItem: {
    minHeight: '120px',
    padding: '5px 10px 5px 10px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    color: darkText,
    width: 'inherit',
    '& h4': {
      margin: '15px 0',
      fontSize: '1.4rem',
      flexGrow: '1',
    },
    '& p': {
      fontSize: '1rem',
      fontWeight: '300',
      marginTop: '0.8rem',
    },
  },
  topicItemEmpty: {
    backgroundColor: grayColor[5],
  },
  topicItemFull: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
  },
  topicYearSwitcher: {
    color: primaryColor[0],
    zIndex: 9,
  },
  topicName: {
    marginLeft: '5px',
    flexGrow: 2,
  },
  topicItemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0 0',
    '& svg': {
      fontSize: '1rem',
      cursor: 'pointer',
    },
  },
  addTopicButton: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  nonBreakableSpace: {
    whiteSpace: 'nowrap',
    minWidth: '55px',
    display: 'flex',
    '& span': {
      textAlign: 'center',
      minWidth: '20px',
    },
  },
  topicList: {
    flexGrow: 1,
  },
  topicStretch: {
    height: '100%',
    display: 'flex',
    width: '100%',
  },
})
