import React, {useCallback, useEffect, useState} from 'react'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import {Trans} from '@lingui/macro'
import {EventNote} from '@material-ui/icons'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import {deleteEvent, eventSharing, getEvent} from '_redux/_action/projectSpecific/eventActions'
import TabPanel from '_component/global/common/tab/TabPanel'
import EventDetailActionsHeader from '_component/projectSpecific/event/eventDetail/EventDetailActionHeaders'
import EventDetailTab from '_component/projectSpecific/event/eventDetail/EventDetailTab'
import EventDetailTitle from '_component/projectSpecific/event/eventDetail/EventDetailTitle'
import {fireSuccessToast, redirect} from '_helper/global/functions'
import moment from 'moment'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'
import EventDeleteFormDialog from '_component/projectSpecific/event/eventDetail/EventDeleteFormDialog'
import WarningDialog from '_component/global/common/dialog/WarningDialog'
import EventParticipantsTab from '_component/projectSpecific/event/eventParticipant/EventParticipantsTab'
import {
  getEventParticipants,
  getEventParticipantsExport,
  logoutEventParticipant,
} from '_redux/_action/projectSpecific/eventParticipantActions'
import download from 'downloadjs'
import EventQrCodeDialog from '_component/projectSpecific/event/eventDetail/EventQrCodeDialog'
import copy from 'copy-to-clipboard'
import {routesMap} from '_config/routesMap'
import {authorizedAbility, Can} from 'App/_provider/authorizedAbility'
import MediaServerVisualsDialog from '_component/projectSpecific/event/eventDetail/MediaServerVisualsDialog'
import EventDetailPrintDialog from '_component/projectSpecific/event/eventDetail/EventDetailPrintDialog'
import EventPriceTab from '_component/projectSpecific/event/eventPrice/EventPriceTab'

const EventDetailPage = (props) => {
  const {
    match: {
      params: {id, date},
    },
    event,
    eventLoaded,
    eventParticipants,
    eventParticipantsMeta,
    eventParticipantsFilters,
    globalSettings,
    eventSharingDataLoaded,
    eventSharingData,
  } = props
  const {
    getEvent,
    updateLocalLoader,
    deleteEvent,
    getEventParticipants,
    logoutEventParticipant,
    getEventParticipantsExport,
    eventSharing,
  } = props

  const [tabsValue, setTabsValue] = useState('detail')
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [openQrCodeDialog, setOpenQrCodeDialog] = useState(false)
  const [openPrintDialog, setOpenPrintDialog] = useState(false)
  const [deletedData, setDeletedData] = useState({})
  const [warningDeleteDialog, setWarningDeleteDialog] = useState(false)
  const [warningParticipantDialog, setWarningParticipantDialog] = useState({
    visible: false,
    row: null,
  })
  const [pageNumber, setPageNumber] = useState(0)
  const [sort, setSort] = useState({
    orderBy: eventParticipantsFilters.orderBy,
    orderDirection: eventParticipantsFilters.orderDirection,
  })
  const [mediaServerDialog, setMediaServerDialog] = useState(false)
  const [openPricingDialog, setOpenPricingDialog] = useState(false)

  const openMediaServerDialog = () => {
    fetchEvent().then(setMediaServerDialog(true))
  }

  const fetchEvent = useCallback(() => {
    updateLocalLoader(true)
    return getEvent(id, date)
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [getEvent, updateLocalLoader, id, date])

  const fetchEventParticipants = useCallback(() => {
    updateLocalLoader(true)
    getEventParticipants(
      id,
      date,
      pageNumber * globalSettings.rowsPerPage,
      globalSettings.rowsPerPage,
      sort.orderBy,
      sort.orderDirection
    )
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [id, date, getEventParticipants, sort, globalSettings, pageNumber, updateLocalLoader])

  useEffect(() => {
    fetchEvent()
    fetchEventParticipants()
  }, [fetchEvent, fetchEventParticipants, tabsValue, sort, pageNumber])

  const fetchEventSharing = () => {
    updateLocalLoader(true)
    return eventSharing(id, date)
      .then((shareData) => {
        updateLocalLoader(false)
        return shareData
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const handleChangeTab = (event, newValue) => {
    setTabsValue(newValue)
  }

  const handleShareOnFacebook = () => {
    fetchEventSharing().then((shareData) => {
      const encodedUrl = encodeURI(shareData.shortUrl)
      const FBlink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
      window.open(FBlink, '_blank')
    })
  }

  const handleCopyUrl = () => {
    fetchEventSharing().then((shareData) => {
      copy(shareData.shortUrl, {format: 'text/plain'})
      fireSuccessToast(<Trans>Url: {shareData.shortUrl} copied.</Trans>)
    })
  }

  const handleOpenGenerateQrCode = () => {
    fetchEventSharing().then(() => {
      setOpenQrCodeDialog(true)
    })
  }

  const handleExportToXls = () => {
    updateLocalLoader(true)
    return getEventParticipantsExport(
      id,
      date,
      pageNumber * globalSettings.rowsPerPage,
      globalSettings.rowsPerPage,
      sort.orderBy,
      sort.orderDirection
    )
      .then((response) => {
        updateLocalLoader(false)
        download(
          response.data,
          `Participants of ${event.name}.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
        fireSuccessToast(<Trans>Participants of {event.name} exported.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const handleEditEvent = () => {
    redirect(`${routesMap.tenant.admin.calendar}/${id}/edit/${date}`)
  }

  const handleOpenDeleteWarningDialog = (e) => {
    setWarningDeleteDialog(true)
  }

  const handleDeleteEvent = () => {
    handleOpenDeleteWarningDialog(true)
  }

  const handleOpenFormDialog = () => {
    setOpenFormDialog(true)
  }

  const confirmDeleteEvent = () => {
    const data = event.isRecurrent
      ? deletedData
      : {method: 'ALL_OCCURENCES', date: moment(event.from).format(DATABASE_DATE_FORMAT)}
    updateLocalLoader(true)

    deleteEvent(id, data)
      .then(() => {
        setWarningDeleteDialog(false)
        setOpenFormDialog(false)
        updateLocalLoader(false)
        redirect(routesMap.tenant.admin.calendar)
        fireSuccessToast(<Trans>Event {event.name} deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(true)
      })
  }

  const handleDeletedData = (values) => {
    setDeletedData(values)
    setWarningDeleteDialog(true)
  }

  const setWarningDialogState = (row) => () => {
    setWarningParticipantDialog({visible: true, row: row})
  }

  const confirmLogoutParticipant = () => {
    updateLocalLoader(true)
    logoutEventParticipant(id, date, warningParticipantDialog.row.id)
      .then(() => {
        setWarningParticipantDialog({visible: false, row: null})
        updateLocalLoader(false)
        fetchEventParticipants()
        fireSuccessToast(<Trans>Participant {warningParticipantDialog.row.name} logged out.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const handleOpenPrintDialog = () => {
    setOpenPrintDialog(true)
  }

  const handleOpenPricingDialog = () => {
    setOpenPricingDialog(true)
  }

  const getTabList = () => {
    const tabList = []
    if (authorizedAbility.can('read', 'Event')) {
      tabList.push({label: <Trans>Event detail</Trans>, value: 'detail'})
      tabList.push({label: <Trans>Event price</Trans>, value: 'price'})
    }

    if (event?.zone !== 'PODCAST' && authorizedAbility.can('read', 'Participants')) {
      tabList.push({label: <Trans>Participants</Trans>, value: 'participant'})
    }
    return tabList
  }

  return (
    <AdminPageWrapper>
      {/* DELETE EVENT */}
      <WarningDialog
        open={warningDeleteDialog}
        handleClose={() => setWarningDeleteDialog(false)}
        handleConfirm={confirmDeleteEvent}
        message={<Trans>You will delete event {event.name}!</Trans>}
      />
      {/* LOGOUT PARTICIPANT */}
      <WarningDialog
        open={warningParticipantDialog.visible}
        handleClose={() => setWarningParticipantDialog({visible: false, row: null})}
        handleConfirm={confirmLogoutParticipant}
        message={
          warningParticipantDialog.row && (
            <Trans>You will logout participant {warningParticipantDialog.row.name}!</Trans>
          )
        }
      />
      <EventDetailPrintDialog
        openDialog={openPrintDialog}
        closeDialog={() => setOpenPrintDialog(false)}
        eventId={id}
        eventDate={date}
      />
      <EventDeleteFormDialog
        openDialog={openFormDialog}
        closeDialog={() => setOpenFormDialog(false)}
        handleDeleteSubmit={handleDeletedData}
        name={event.name}
        eventDate={event.from}
      />
      {eventSharingDataLoaded && (
        <EventQrCodeDialog
          name={event.name}
          openDialog={openQrCodeDialog}
          closeDialog={() => setOpenQrCodeDialog(false)}
          qrCode={eventSharingData.qrCode}
        />
      )}
      <MediaServerVisualsDialog
        event={event}
        openDialog={mediaServerDialog}
        closeDialog={() => setMediaServerDialog(false)}
      />

      {eventLoaded && (
        <ContentAdminPageWrapper
          tabsValue={tabsValue}
          tabList={getTabList()}
          handleChangeTab={handleChangeTab}
          cardPageTitle={event.isTopEvent ? <EventDetailTitle name={event.name} /> : event.name}
          iconPageHeader={<EventNote />}
          toolbar={false}
          actionsHeader={
            <EventDetailActionsHeader
              tabsValue={tabsValue}
              openMediaServerDialog={openMediaServerDialog}
              shareOnFacebook={handleShareOnFacebook}
              copyUrl={handleCopyUrl}
              generateQr={handleOpenGenerateQrCode}
              exportToXls={handleExportToXls}
              editEventDetail={handleEditEvent}
              hardDeleteEvent={event.isRecurrent ? handleOpenFormDialog : handleDeleteEvent}
              openPrintDialog={handleOpenPrintDialog}
              hasPricing={event.pricing?.total !== undefined}
              handleOpenPricingDialog={handleOpenPricingDialog}
              isEventPodcast={event.zone === 'PODCAST'}
            />
          }
        >
          <Can I="read" an="Event">
            <TabPanel value={tabsValue} index={'detail'}>
              <EventDetailTab item={event} />
            </TabPanel>

            <TabPanel value={tabsValue} index={'price'}>
              <EventPriceTab
                event={event.pricing}
                setOpenDialog={setOpenPricingDialog}
                open={openPricingDialog}
              />
            </TabPanel>
          </Can>

          {event?.zone !== 'PODCAST' && (
            <Can I="read" a="Participants">
              <TabPanel value={tabsValue} index={'participant'}>
                <EventParticipantsTab
                  requestSort={setSort}
                  order={eventParticipantsFilters.orderDirection}
                  orderBy={eventParticipantsFilters.orderBy}
                  countParticipants={eventParticipantsMeta.totalCount}
                  participantPage={pageNumber}
                  onParticipantChangePage={setPageNumber}
                  rowsPerParticipantPage={globalSettings.rowsPerPage}
                  participants={eventParticipants}
                  handleParticipantLogout={setWarningDialogState}
                />
              </TabPanel>
            </Can>
          )}
        </ContentAdminPageWrapper>
      )}
    </AdminPageWrapper>
  )
}

EventDetailPage.propTypes = {
  event: PropTypes.object.isRequired,
  updateGlobalLoader: PropTypes.func,
  getEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  updateLocalLoader: PropTypes.func,
  getEventParticipants: PropTypes.func,
  logoutEventParticipant: PropTypes.func,
  getEventParticipantsExport: PropTypes.func,
  eventSharing: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocalLoader,
      getEvent,
      deleteEvent,
      getEventParticipants,
      logoutEventParticipant,
      getEventParticipantsExport,
      eventSharing,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      event: store.Event.data,
      eventLoaded: !store.Event.isLoading,
      eventParticipants: store.EventParticipants.data,
      eventParticipantsMeta: store.EventParticipants.meta,
      eventParticipantsFilters: store.AppFilterStates.listEventParticipantsFilters,
      globalSettings: store.GlobalSettings,
      eventSharingData: store.EventSharing.data,
      eventSharingDataLoaded: !store.EventSharing.isLoading,
    }
  }, mapDispatchToProps)
)(EventDetailPage)
