import React from 'react'
import {Trans} from '@lingui/macro'
import LoginForm from '_component/projectSpecific/auth/LoginForm'
import ContentAuthPageWrapper from '_component/global/common/wrapper/ContentAuthPageWrapper'
import AuthPageWrapper from '_component/global/common/wrapper/AuthPageWrapper'

const LoginPage = () => {
  return (
    <AuthPageWrapper>
      <ContentAuthPageWrapper pageTitle={<Trans>experience center</Trans>}>
        <LoginForm />
      </ContentAuthPageWrapper>
    </AuthPageWrapper>
  )
}

export default LoginPage
