import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Hidden from '@material-ui/core/Hidden'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'

const TableToolbarActionsSelectedWrapper = (props) => {
  const {classes, desktopActions, mobileActions} = props

  return (
    <div>
      <Hidden mdDown implementation="css">
        <div className={classes.flexEnd}>{desktopActions}</div>
      </Hidden>

      <Hidden mdUp implementation="css">
        <div className={cx(classes.smallIcons, classes.flexStart)}>{mobileActions}</div>
      </Hidden>
    </div>
  )
}

TableToolbarActionsSelectedWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  desktopActions: PropTypes.node,
  mobileActions: PropTypes.node,
}

export default withStyles(globalWrapperStyles)(TableToolbarActionsSelectedWrapper)
