import React from 'react'
import PropTypes from 'prop-types'
import GridContainer from '_component/global/common/grid/GridContainer'

const AdminPageWrapper = (props) => {
  const {alert, children} = props

  return (
    <GridContainer>
      {alert}
      {children}
    </GridContainer>
  )
}

AdminPageWrapper.propTypes = {
  alert: PropTypes.node,
  children: PropTypes.node,
}

export default AdminPageWrapper
