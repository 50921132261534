import React from 'react'
import Cell from '_component/global/common/table/Cell'
import {TableRow, Tooltip, withStyles} from '@material-ui/core'
import {Can} from 'App/_provider/authorizedAbility'
import TableActionsButton from '_component/global/common/button/TableActionsButton'
import {Trans} from '@lingui/macro'
import {DeleteForever, Edit} from '@material-ui/icons'
import PropTypes from 'prop-types'
import globalTableStyle from '_asset/global/jss/component/common/table/globalTableStyle'

const OrganizationContactRow = (props) => {
  const {row, classes, onEditClick, onDeleteClick} = props

  return (
    <TableRow>
      <Cell align="left">{row.name}</Cell>
      <Cell>{row.email}</Cell>
      <Cell>{row.phone}</Cell>
      <Cell>{row.description}</Cell>
      <Cell>
        {row.userId ? (
          <div className={classes.yesText}>
            <Trans>Yes</Trans>
          </div>
        ) : (
          <div className={classes.noText}>
            <Trans>No</Trans>
          </div>
        )}
      </Cell>

      <Cell isActions={true} isRowAction>
        <Can I="update" an="Organizations">
          <TableActionsButton onClick={onEditClick(row)} backgroundColor="success">
            <Tooltip title={<Trans>Edit</Trans>}>
              <Edit />
            </Tooltip>
          </TableActionsButton>
        </Can>
        <Can I="delete" an="Organizations">
          <TableActionsButton onClick={onDeleteClick(row)} backgroundColor="warning">
            <Tooltip title={<Trans>Delete</Trans>}>
              <DeleteForever />
            </Tooltip>
          </TableActionsButton>
        </Can>
      </Cell>
    </TableRow>
  )
}

OrganizationContactRow.propTypes = {
  classes: PropTypes.object,
  row: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default withStyles(globalTableStyle)(OrganizationContactRow)
