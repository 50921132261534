import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Box} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import {required} from '_helper/projectSpecific/validations'
import PropTypes from 'prop-types'
import TextInput from '_component/global/common/formField/TextInput'
import {getTopic} from '_redux/_action/projectSpecific/topicActions'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import DateInput from '_component/global/common/formField/DateInput'
import DialogCreateFormButtons from '_component/global/common/dialog/DialogCreateFormButton'
import DialogEditFormButtons from '_component/global/common/dialog/DialogEditFormButtons'
import GridItem from '_component/global/common/grid/GridItem'
import moment from 'moment'

const TopicForm = (props) => {
  const {
    onSubmit,
    form,
    getTopic,
    editId,
    topic,
    isTopicLoading,
    deleteItem,
    updateLocalLoader,
    month,
    year,
  } = props

  useEffect(() => {
    if (form === 'edit') {
      getTopic(editId)
    }
  }, [form, getTopic, editId, updateLocalLoader])

  const getInitialValues = () => {
    if (editId && !isTopicLoading) {
      return topic
    }
    if (month && year) {
      return {
        from: moment()
          .year(year)
          .month(month - 1)
          .startOf('month'),
        to: moment(year)
          .year(year)
          .month(month - 1)
          .endOf('month'),
      }
    }
  }

  return (
    <Form onSubmit={onSubmit} initialValues={getInitialValues()}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridItem xs={12}>
              <Field
                name="name"
                label={<Trans>Topic Name</Trans>}
                component={TextInput}
                validate={required}
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="description"
                label={<Trans>Topic Description</Trans>}
                component={TextInput}
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="from"
                label={<Trans>Available from</Trans>}
                component={DateInput}
                validate={required}
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="to"
                label={<Trans>Available to</Trans>}
                component={DateInput}
                validate={required}
              />
            </GridItem>
            <Box pt={1}>
              {form === 'edit' ? (
                <DialogEditFormButtons permissionAsset="Topics" deleteItem={deleteItem(topic)} />
              ) : (
                <DialogCreateFormButtons />
              )}
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTopic,
      updateLocalLoader,
    },
    dispatch
  )
}

TopicForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.string,
  getTopic: PropTypes.func,
  editId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  topic: PropTypes.object,
  isTopicLoading: PropTypes.bool,
  deleteItem: PropTypes.func,
  updateGlobalLoader: PropTypes.func,
  month: PropTypes.number,
  year: PropTypes.number,
}

export default compose(
  connect((store) => {
    return {
      topic: store.Topic.data,
      isTopicLoading: store.Topic.isLoading,
    }
  }, mapDispatchToProps)
)(TopicForm)
