import React, {useRef, useState} from 'react'
import {
  Breadcrumbs,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
} from '@material-ui/core'
import {ChevronRight, ExitToApp, Lock, Person, SupervisorAccount} from '@material-ui/icons'
import {withStyles} from '@material-ui/core/styles'
import globalLayoutElementsStyle from '_asset/global/jss/component/layoutElement/globalLayoutElementsStyle'
import PropTypes from 'prop-types'
import {fireSuccessToast, getActualUser, prepareBreadcrumbs} from '_helper/global/functions'
import {Trans} from '@lingui/macro'
import {
  changeMyAccount,
  changePassword,
  getMyAccount,
  logout,
} from '_redux/_action/global/authActions'
import {useDispatch} from 'react-redux'
import OrganizationEditDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationEditDialog'
import {changeMyOrganization} from '_redux/_action/projectSpecific/organizationActions'
import OrganizationUserEditDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationUserEditDialog'
import UserChangePasswordDialog from '_component/projectSpecific/auth/UserChangePasswordDialog'

const AdminNavbar = (props) => {
  const {classes} = props
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [openOrganization, setOpenOrganization] = useState(false)
  const [logoFile, setLogoFile] = useState(null)
  const [logoChanged, setLogoChanged] = useState(false)
  const [openUser, setOpenUser] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [userName, setUserName] = useState(getActualUser()?.user?.name)
  const [errorMessage, setErrorMessage] = useState(null)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    setOpen(false)
  }

  const handleOpenOrganization = () => {
    setOpenOrganization(true)
    handleClose()
  }

  const handleCloseOrganization = () => {
    setOpenOrganization(false)
  }

  const handleOpenUser = () => {
    setOpenUser(true)
    handleClose()
  }

  const handleCloseUser = () => {
    setOpenUser(false)
  }

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true)
    handleClose()
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false)
  }

  const handleOrganizationEdit = (values) => {
    if (logoChanged) {
      if (!logoFile) {
        values.logoUrl = null
      } else {
        values.logoData = logoFile
      }
    }
    dispatch(changeMyOrganization(values)).then(() => {
      handleCloseOrganization()
      fireSuccessToast(<Trans>Organization updated.</Trans>)
    })
  }

  const handleEditMyAccount = (values) => {
    const data = {
      name: values.name,
      email: values.email,
    }
    dispatch(changeMyAccount(data)).then(() => {
      handleCloseUser()
      dispatch(getMyAccount()).then(() => {
        setUserName(getActualUser()?.user?.name)
      })
    })
  }

  const handleSetLogoFile = (file) => {
    setLogoFile(file)
  }

  const handleChangePassword = (values) => {
    dispatch(changePassword(values))
      .then(() => {
        handleCloseChangePassword()
      })
      .catch((err) => setErrorMessage(err?.response?.data?.message))
  }

  return (
    <div className={classes.adminNavbarWrapper}>
      <OrganizationEditDialog
        openEdit={openOrganization}
        closeEdit={handleCloseOrganization}
        handleEditSubmit={handleOrganizationEdit}
        id={null}
        logoChanged={logoChanged}
        logoFile={logoFile}
        handleLogoChanged={setLogoChanged}
        handleLogoFile={handleSetLogoFile}
        deleteItem={null}
      />
      <OrganizationUserEditDialog
        openUserEdit={openUser}
        closeUserEdit={handleCloseUser}
        handleUserEditSubmit={handleEditMyAccount}
        organizationId={null}
        userId={null}
        deleteUser={null}
        disableRolesEdit
      />
      <UserChangePasswordDialog
        errorMessage={errorMessage}
        openDialog={openChangePassword}
        closeDialog={handleCloseChangePassword}
        handleSubmit={handleChangePassword}
      />
      <Toolbar>
        <Breadcrumbs separator={<ChevronRight />} aria-label="breadcrumb">
          {prepareBreadcrumbs(classes)}
        </Breadcrumbs>

        <div className={classes.adminNavbarSettings} ref={anchorRef} onClick={handleToggle}>
          <span className="name">{userName}</span>
          <IconButton aria-label="account of current user" aria-haspopup="true" color="inherit">
            <Person />
          </IconButton>
        </div>

        <Popper
          className={classes.dropDownPopper}
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          role={undefined}
          transition
          disablePortal
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" variant="selectedMenu">
                    <MenuItem onClick={handleOpenOrganization}>
                      <SupervisorAccount />
                      <Trans>Edit my organization</Trans>
                    </MenuItem>
                    <MenuItem onClick={handleOpenUser}>
                      <Person />
                      <Trans>Edit my account</Trans>
                    </MenuItem>
                    <MenuItem onClick={handleOpenChangePassword}>
                      <Lock />
                      <Trans>Change my password</Trans>
                    </MenuItem>
                    <MenuItem onClick={dispatch(logout)}>
                      <ExitToApp />
                      <Trans>Logout</Trans>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </div>
  )
}

AdminNavbar.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(globalLayoutElementsStyle)(AdminNavbar)
