import {
  FETCH_MY_ORGANIZATION_REQUEST,
  FETCH_MY_ORGANIZATION_SUCCESS,
} from '_redux/_action/projectSpecific/types'

const initMyOrganizationState = {
  isLoading: true,
  data: {},
}

export const MyOrganizationReducer = (state = initMyOrganizationState, action = null) => {
  switch (action.type) {
    case FETCH_MY_ORGANIZATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }

    default:
      return state
  }
}
