import Toast from '_component/global/common/toast/Toast'
import React from 'react'
import {withStyles} from '@material-ui/core'
import warningMessageStyle from '_asset/projectSpecific/jss/common/warningMessageStyle'
import GridItem from '_component/global/common/grid/GridItem'

const WarningMessage = (props) => {
  const {warningMessage, classes} = props
  return (
    <GridItem xs={12} className={classes.warningMessageWrapper}>
      <div className={classes.warningMessage}>
        <Toast type="warning" message={warningMessage} />
      </div>
    </GridItem>
  )
}

export default withStyles(warningMessageStyle)(WarningMessage)
