import {createTheme} from '@material-ui/core'

const globalPaginationStyle = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiToolbar: {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
      },
    },
    MuiTablePagination: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
})

export default globalPaginationStyle
