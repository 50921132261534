import React from 'react'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {withStyles} from '@material-ui/core'
import globalButtonStyle from '_asset/global/jss/component/common/button/globalButtonStyle'
import PropTypes from 'prop-types'

const TableActionsButton = (props) => {
  const {children, classes, ...rest} = props

  return (
    <GlobalButton className={classes.tableActionsButton} justIcon round simple {...rest}>
      {children}
    </GlobalButton>
  )
}

TableActionsButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
}

export default withStyles(globalButtonStyle)(TableActionsButton)
