import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import GridItem from '_component/global/common/grid/GridItem'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import Card from '_component/global/common/card/Card'
import CardHeader from '_component/global/common/card/CardHeader'
import CardBody from '_component/global/common/card/CardBody'
import logo from '_asset/projectSpecific/img/MEC_static_logo_RGB.svg'
import logoGif from '_asset/projectSpecific/gif/logo_white.gif'

const ContentAuthPageWrapper = (props) => {
  const {
    cardBodyClassName,
    classes,
    children,
    login,
    profile,
    color,
    xs = 12,
    sm = 8,
    md = 5,
    xl = 3,
    gridContainerClass,
  } = props

  return (
    <GridItem xs={xs} sm={sm} md={md} xl={xl} className={gridContainerClass}>
      <Card className={classes.authHeader} login={login} profile={profile} color={color}>
        <CardHeader className={classes.authCardHeader}>
          <div className={classes.cardAuthHeaderWrapper}>
            <object data={logoGif} type="image/gif">
              <img src={logo} alt="logo" className={classes.logo} />
            </object>
          </div>
        </CardHeader>
        <CardBody className={cardBodyClassName}>{children}</CardBody>
      </Card>
    </GridItem>
  )
}

ContentAuthPageWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  cardBodyClassName: PropTypes.object,
  iconPageHeader: PropTypes.object,
  pageTitle: PropTypes.node,
  companyName: PropTypes.node,
  toolbar: PropTypes.bool,
  actionsHeader: PropTypes.node,
  children: PropTypes.node,
}

export default withStyles(globalWrapperStyles)(ContentAuthPageWrapper)
