import {primaryColor} from '_asset/global/jss/styleHelpers'

const globalGridStyle = (theme) => ({
  gridContainer: {},
  gridItem: {
    padding: '0 15px !important',
  },
  detailGridItem: {
    padding: '0px',
    '&:nth-child(odd)': {
      paddingRight: '10px !important',
    },
    '&:nth-child(even)': {
      paddingLeft: '10px !important',
    },
  },
  detailGridItemHeader: {
    color: primaryColor[0],
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      height: '20px !important',
      width: '20px !important',
      paddingRight: '10px',
    },
  },
})

export default globalGridStyle
