import React, {useCallback, useEffect, useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
  deleteOrganization,
  editOrganization,
  getOrganization,
} from '_redux/_action/projectSpecific/organizationActions'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import {Business} from '@material-ui/icons'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import WarningDialog from '_component/global/common/dialog/WarningDialog'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import {fireSuccessToast, redirect} from '_helper/global/functions'
import updateAppFilterStates from '_redux/_action/global/appFilterStatesAction'
import {
  createOrganizationUser,
  deleteOrganizationUser,
  editOrganizationUser,
  getOrganizationUsers,
  sendWelcomeEmail,
} from '_redux/_action/projectSpecific/organizationUserActions'
import TabPanel from '_component/global/common/tab/TabPanel'
import OrganizationDetailActionsHeader from '_component/projectSpecific/organization/organizationDetail/OrganizationDetailActionsHeader'
import {getOrganizationData} from '_component/projectSpecific/organization/organizationDetail/_organizationDetailTableColumns'
import OrganizationDetailTab from '_component/projectSpecific/organization/organizationDetail/OrganizationDetailTab'
import OrganizationUsersDetailTab from '_component/projectSpecific/organization/organizationDetail/OrganizationUsersDetailTab'
import OrganizationEditDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationEditDialog'
import OrganizationUserCreateDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationUserCreateDialog'
import OrganizationUserEditDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationUserEditDialog'
import {forgottenPasswordRequest} from '_redux/_action/global/authActions'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import {routesMap} from '_config/routesMap'
import OrganizationAddressDetailTab from '_component/projectSpecific/organization/organizationDetail/OrganizationAddressDetailTab'
import {
  createOrganizationAddress,
  deleteOrganizationAddress,
  editOrganizationAddress,
  exportOrganizationAddresses,
  getOrganizationAddresses,
} from '_redux/_action/projectSpecific/organizationAddressAction'
import OrganizationAddressDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationAddressDialog'
import {
  createOrganizationContact,
  deleteOrganizationContact,
  editOrganizationContact,
  exportOrganizationContacts,
  getOrganizationContacts,
} from '_redux/_action/projectSpecific/organizationContactAction'
import OrganizationContactDetailTab from '_component/projectSpecific/organization/organizationDetail/OrganizationContactDetailTab'
import OrganizationContactDialog from '_component/projectSpecific/organization/organizationDetail/OrganizationContactDialog'
import download from 'downloadjs'

const OrganizationDetailPage = (props) => {
  const {
    match: {
      params: {id},
    },
    organization,
    organizationUsersFilter,
    organizationUsersMeta,
    organizationUsers,
    globalSettings,
    organizationLoading,
    organizationAddressFilter,
  } = props
  const {
    getOrganization,
    editOrganization,
    deleteOrganization,
    updateLocalLoader,
    getOrganizationUsers,
    createOrganizationUser,
    editOrganizationUser,
    deleteOrganizationUser,
    forgottenPasswordRequest,
    sendWelcomeEmail,
    organizationAddresses,
    organizationAddressesMeta,
    getOrganizationAddresses,
    createOrganizationAddress,
    editOrganizationAddress,
    deleteOrganizationAddress,
    organizationContactFilter,
    getOrganizationContacts,
    createOrganizationContact,
    editOrganizationContact,
    deleteOrganizationContact,
    organizationContacts,
    organizationContactsMeta,
    exportOrganizationAddresses,
    exportOrganizationContacts,
  } = props

  const [editOrganizationDialog, setEditOrganizationDialog] = useState(false)
  const [createUserDialog, setCreateUserDialog] = useState(false)
  const [editUserDialog, setEditUserDialog] = useState(false)
  const [editedUser, setEditedUser] = useState(null)
  const [warningOrganizationDialog, setWarningOrganizationDialog] = useState(false)
  const [warningWelcomeDialog, setWarningWelcomeDialog] = useState({visible: false, row: null})
  const [warningUserDialog, setWarningUserDialog] = useState({visible: false, row: null})
  const [pageNumber, setPageNumber] = useState(0)
  const [tabsValue, setTabsValue] = useState('detail')
  const [logoFile, setLogoFile] = useState(null)
  const [logoChanged, setLogoChanged] = useState(false)
  const [sort, setSort] = useState({
    orderBy: organizationUsersFilter.orderBy,
    orderDirection: organizationUsersFilter.orderDirection,
  })

  const [warningUserResetPasswordDialog, setWarningUserResetPasswordDialog] = useState({
    visible: false,
    row: null,
  })

  // address state
  const [pageNumberAddress, setPageNumberAddress] = useState(0)
  const [sortAddress, setSortAddress] = useState({
    orderBy: organizationAddressFilter.orderBy,
    orderDirection: organizationAddressFilter.orderDirection,
  })
  const [createAddressDialog, setCreateAddressDialog] = useState(false)
  const [editAddressDialog, setEditAddressDialog] = useState({visible: false, row: null})
  const [warningAddressDialog, setWarningAddressDialog] = useState({visible: false, row: null})

  // contact state
  const [pageNumberContact, setPageNumberContact] = useState(0)
  const [sortContact, setSortContact] = useState({
    orderBy: organizationContactFilter.orderBy,
    orderDirection: organizationContactFilter.orderDirection,
  })
  const [createContactDialog, setCreateContactDialog] = useState(false)
  const [editContactDialog, setEditContactDialog] = useState({visible: false, row: null})
  const [warningContactDialog, setWarningContactDialog] = useState({visible: false, row: null})

  const openCreateContactDialog = () => {
    setCreateContactDialog(true)
  }

  const fetchOrganizationContacts = useCallback(() => {
    updateLocalLoader(true)
    getOrganizationContacts(
      id,
      pageNumberContact * globalSettings.rowsPerPage,
      globalSettings.rowsPerPage,
      sortContact.orderBy,
      sortContact.orderDirection
    )
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [
    id,
    getOrganizationContacts,
    sortContact,
    globalSettings,
    pageNumberContact,
    updateLocalLoader,
  ])

  const handleCreateOrganizationContact = (values) => {
    return createOrganizationContact(id, values).then(() => {
      setCreateContactDialog(false)
      fetchOrganizationContacts()
      fireSuccessToast(<Trans>Contact {values?.name} created.</Trans>)
    })
  }

  const handleOpenEditContactDialog = (row) => (e) => {
    setEditContactDialog({visible: true, row: row})
  }

  const handleEditContact = (values) => {
    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      description: values.description,
      userId: values.userId,
    }
    return editOrganizationContact(id, values?.id, data).then(() => {
      setEditContactDialog({visible: false, row: null})
      fetchOrganizationContacts()
      fireSuccessToast(<Trans>Contact {values.name} updated.</Trans>)
    })
  }

  const handleOpenDeleteContactDialog = (row) => (e) => {
    setWarningContactDialog({visible: true, row: row})
  }

  const confirmDeleteContact = () => {
    updateLocalLoader(true)
    return deleteOrganizationContact(id, warningContactDialog?.row?.id)
      .then(() => {
        setWarningContactDialog({visible: false, row: null})
        updateLocalLoader(false)
        fetchOrganizationContacts()
        fireSuccessToast(<Trans>Contact {warningUserDialog?.row?.name} deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const handleExportAddresses = () => {
    updateLocalLoader(true)
    return exportOrganizationAddresses(id)
      .then((response) => {
        updateLocalLoader(false)
        download(
          response.data,
          `Addresses of ${organization?.name}.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
        fireSuccessToast(<Trans>Addresses of {organization.name} exported.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const handleExportsContacts = () => {
    updateLocalLoader(true)
    return exportOrganizationContacts(id)
      .then((response) => {
        updateLocalLoader(false)
        download(
          response.data,
          `Contacts of ${organization?.name}.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
        fireSuccessToast(<Trans>Contacts of {organization.name} exported.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const openCreateAddressDialog = () => {
    setCreateAddressDialog(true)
  }

  const fetchOrganizationAddresses = useCallback(() => {
    updateLocalLoader(true)
    getOrganizationAddresses(
      id,
      pageNumberAddress * globalSettings.rowsPerPage,
      globalSettings.rowsPerPage,
      sortAddress.orderBy,
      sortAddress.orderDirection
    )
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [
    id,
    getOrganizationAddresses,
    sortAddress,
    globalSettings,
    pageNumberAddress,
    updateLocalLoader,
  ])

  const handleCreateOrganizationAddress = (values) => {
    return createOrganizationAddress(id, values).then(() => {
      setCreateAddressDialog(false)
      fetchOrganizationAddresses()
      fireSuccessToast(<Trans>Address {values?.name} created.</Trans>)
    })
  }

  const handleOpenEditAddressDialog = (row) => (e) => {
    setEditAddressDialog({visible: true, row: row})
  }

  const handleEditAddress = (values) => {
    const data = {
      city: values.city,
      addressType: values.addressType,
      name: values.name,
      postalCode: values.postalCode,
      street: values.street,
    }
    return editOrganizationAddress(id, values?.id, data).then(() => {
      setEditAddressDialog({visible: false, row: null})
      fetchOrganizationAddresses()
      fireSuccessToast(<Trans>Address {values.name} updated.</Trans>)
    })
  }

  const handleOpenDeleteAddressDialog = (row) => (e) => {
    setWarningAddressDialog({visible: true, row: row})
  }

  const confirmDeleteAddress = () => {
    updateLocalLoader(true)
    return deleteOrganizationAddress(id, warningAddressDialog?.row?.id)
      .then(() => {
        setWarningAddressDialog({visible: false, row: null})
        updateLocalLoader(false)
        fetchOrganizationAddresses()
        fireSuccessToast(<Trans>Address {warningUserDialog?.row?.name} deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const fetchOrganization = useCallback(() => {
    getOrganization(id)
  }, [getOrganization, id])

  const fetchOrganizationUsers = useCallback(() => {
    updateLocalLoader(true)
    return getOrganizationUsers(
      id,
      pageNumber * globalSettings.rowsPerPage,
      globalSettings.rowsPerPage,
      sort.orderBy,
      sort.orderDirection
    )
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [id, getOrganizationUsers, sort, globalSettings, pageNumber, updateLocalLoader])

  const openWarningDeleteOrganization = () => (event) => {
    setWarningOrganizationDialog(true)
  }

  const openWarningDeleteOrganizationDetail = () => {
    setWarningOrganizationDialog(true)
  }

  const confirmDeleteOrganization = () => {
    updateLocalLoader(true)
    return deleteOrganization(id)
      .then(() => {
        setWarningOrganizationDialog(false)
        setEditOrganizationDialog(false)
        updateLocalLoader(false)
        redirect(routesMap.tenant.admin.organization)
        fireSuccessToast(<Trans>Organization {organization?.name} deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const confirmDeleteUser = () => {
    updateLocalLoader(true)
    return deleteOrganizationUser(id, warningUserDialog.row.id)
      .then(() => {
        setWarningUserDialog({visible: false, row: null})
        setEditUserDialog(false)
        updateLocalLoader(false)
        fetchOrganizationUsers()
        fireSuccessToast(<Trans>User {warningUserDialog.row.name} deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const openOrganizationEdit = () => {
    setEditOrganizationDialog(true)
  }

  const handleOrganizationEdit = (values) => {
    if (logoChanged) {
      delete values.logoUrl
      values.logoData = logoFile
    }
    return editOrganization(id, values).then(() => {
      setEditOrganizationDialog(false)
      fetchOrganization()
      fireSuccessToast(<Trans>Organization {values.name} updated.</Trans>)
    })
  }

  const openCreateUserDialog = () => {
    setCreateUserDialog(true)
  }

  const handleCreateOrganizationUser = (values) => {
    return createOrganizationUser(id, values).then(() => {
      setCreateUserDialog(false)
      fetchOrganizationUsers()
      fireSuccessToast(<Trans>User {values.name} created.</Trans>)
    })
  }

  const handleEditOrganizationUser = (values) => {
    const data = {
      name: values.name,
      email: values.email,
      roleIds: values.roleIds,
    }
    return editOrganizationUser(id, values.id, data).then(() => {
      setEditUserDialog(false)
      fetchOrganizationUsers()
      fireSuccessToast(<Trans>User {editedUser.name} updated.</Trans>)
    })
  }

  const handleSendWelcomeEmail = (user) => (e) => {
    setWarningWelcomeDialog({visible: true, row: user})
  }

  const confirmSendWelcomeEmail = () => {
    if (warningWelcomeDialog.row) {
      updateLocalLoader(true)
      sendWelcomeEmail(warningWelcomeDialog.row).then(() => {
        updateLocalLoader(false)
        setWarningWelcomeDialog({visible: false, row: null})
      })
    }
  }

  const openEditUserDialog = (user) => () => {
    setEditedUser(user)
    setEditUserDialog(true)
  }

  const handleChangeTab = (event, newValue) => {
    setTabsValue(newValue)
  }

  const setWarningDialogState = (row) => (event) => {
    setWarningUserDialog({visible: true, row: row})
  }

  const handleResetUserPassword = (row) => (event) => {
    setWarningUserResetPasswordDialog({visible: true, row: row})
  }

  const handleConfirmResetUserPassword = () => {
    updateLocalLoader(true)
    const userEmail = warningUserResetPasswordDialog.row.email
    forgottenPasswordRequest({email: userEmail}, false).then(() => {
      updateLocalLoader(false)
      setWarningUserResetPasswordDialog({visible: false, row: null})
    })
  }

  const getTabList = () => {
    const tabList = []
    if (authorizedAbility.can('read', 'Organization')) {
      tabList.push({label: <Trans>Organization detail</Trans>, value: 'detail'})
    }
    if (authorizedAbility.can('read', 'Users')) {
      tabList.push({label: <Trans>Users accounts</Trans>, value: 'users'})
    }
    if (authorizedAbility.can('read', 'Organization')) {
      tabList.push({label: <Trans>Addresses</Trans>, value: 'addresses'})
    }
    if (authorizedAbility.can('read', 'Organization')) {
      tabList.push({label: <Trans>Contacts</Trans>, value: 'contacts'})
    }
    return tabList
  }

  useEffect(() => {
    fetchOrganization()
    fetchOrganizationUsers()
    fetchOrganizationAddresses()
    fetchOrganizationContacts()
  }, [
    fetchOrganization,
    fetchOrganizationUsers,
    fetchOrganizationAddresses,
    fetchOrganizationContacts,
    sort,
    pageNumber,
    globalSettings,
  ])

  return (
    <AdminPageWrapper>
      <WarningDialog
        open={warningUserDialog.visible}
        handleClose={() => setWarningUserDialog({visible: false, row: null})}
        handleConfirm={confirmDeleteUser}
        message={
          warningUserDialog.row && (
            <Trans>You will delete user {warningUserDialog.row?.name}!</Trans>
          )
        }
      />
      <WarningDialog
        open={warningWelcomeDialog.visible}
        handleClose={() => setWarningWelcomeDialog({visible: false, row: null})}
        handleConfirm={confirmSendWelcomeEmail}
        message={
          warningWelcomeDialog.row && (
            <Trans>You will send welcome email to {warningWelcomeDialog.row?.email}!</Trans>
          )
        }
      />
      <WarningDialog
        open={warningUserResetPasswordDialog.visible}
        handleClose={() => setWarningUserResetPasswordDialog({visible: false, row: null})}
        handleConfirm={handleConfirmResetUserPassword}
        message={
          warningUserResetPasswordDialog.row && (
            <Trans>
              You will reset password password for user {warningUserResetPasswordDialog.row?.name}!
            </Trans>
          )
        }
      />
      <WarningDialog
        open={warningOrganizationDialog}
        handleClose={() => setWarningOrganizationDialog(false)}
        handleConfirm={confirmDeleteOrganization}
        message={<Trans>You will delete organization {organization.name}!</Trans>}
      />

      <OrganizationEditDialog
        openEdit={editOrganizationDialog}
        closeEdit={() => setEditOrganizationDialog(false)}
        handleEditSubmit={handleOrganizationEdit}
        id={id}
        logoChanged={logoChanged}
        logoFile={logoFile}
        handleLogoChanged={setLogoChanged}
        handleLogoFile={setLogoFile}
        deleteItem={openWarningDeleteOrganization}
      />

      {/* USER DIALOGS */}
      <OrganizationUserCreateDialog
        openCreate={createUserDialog}
        closeCreate={() => setCreateUserDialog(false)}
        handleCreateSubmit={handleCreateOrganizationUser}
      />
      <OrganizationUserEditDialog
        openUserEdit={editUserDialog}
        closeUserEdit={() => setEditUserDialog(false)}
        handleUserEditSubmit={handleEditOrganizationUser}
        organizationId={organization.id}
        userId={editedUser && editedUser.id}
        deleteUser={setWarningDialogState}
      />

      {/* NEW ADDRESS DIALOGS */}
      <OrganizationAddressDialog
        open={createAddressDialog}
        close={() => setCreateAddressDialog(false)}
        handleSubmit={handleCreateOrganizationAddress}
        form={'new'}
      />
      {/* EDIT ADDRESS DIALOGS */}
      <OrganizationAddressDialog
        open={editAddressDialog.visible}
        address={editAddressDialog.row}
        close={() => setEditAddressDialog({visible: false, row: null})}
        handleSubmit={handleEditAddress}
        form={'edit'}
      />
      <WarningDialog
        open={warningAddressDialog.visible}
        handleClose={() => setWarningAddressDialog({visible: false, row: null})}
        handleConfirm={confirmDeleteAddress}
        message={
          warningAddressDialog.row && (
            <Trans>You will delete address {warningAddressDialog.row?.name}!</Trans>
          )
        }
      />

      {/* CONTACT DIALOGS*/}
      <OrganizationContactDialog
        open={createContactDialog}
        close={() => setCreateContactDialog(false)}
        handleSubmit={handleCreateOrganizationContact}
        form={'new'}
      />
      <OrganizationContactDialog
        open={editContactDialog.visible}
        contact={editContactDialog.row}
        close={() => setEditContactDialog({visible: false, row: null})}
        handleSubmit={handleEditContact}
        form={'edit'}
      />
      <WarningDialog
        open={warningContactDialog.visible}
        handleClose={() => setWarningContactDialog({visible: false, row: null})}
        handleConfirm={confirmDeleteContact}
        message={
          warningContactDialog.row && (
            <Trans>You will delete contact {warningContactDialog.row?.name}!</Trans>
          )
        }
      />

      <ContentAdminPageWrapper
        tabsValue={tabsValue}
        tabList={getTabList()}
        handleChangeTab={handleChangeTab}
        cardPageTitle={organization.name}
        iconPageHeader={<Business />}
        toolbar={false}
        actionsHeader={
          <OrganizationDetailActionsHeader
            tabsValue={tabsValue}
            onEditClick={openOrganizationEdit}
            odDeleteClick={openWarningDeleteOrganizationDetail}
            onCreateUserClick={openCreateUserDialog}
            onCreateAddressClick={openCreateAddressDialog}
            onCreateContactClick={openCreateContactDialog}
            onExportAddressesClick={handleExportAddresses}
            onExportContactsClick={handleExportsContacts}
          />
        }
      >
        <TabPanel value={tabsValue} index={'detail'}>
          <OrganizationDetailTab
            detailOrganizationData={getOrganizationData(organization)}
            logo={organization.logoUrl}
            organizationLoading={organizationLoading}
          />
        </TabPanel>

        <TabPanel value={tabsValue} index={'users'}>
          <OrganizationUsersDetailTab
            requestSort={setSort}
            order={organizationUsersFilter.orderDirection}
            orderBy={organizationUsersFilter.orderBy}
            countOrganizationUsers={organizationUsersMeta.totalCount}
            organizationPage={pageNumber}
            onOrganizationChangePage={setPageNumber}
            rowsPerOrganizationPage={globalSettings.rowsPerPage}
            users={organizationUsers}
            handleUserEdit={openEditUserDialog}
            handleUserDelete={setWarningDialogState}
            handleResetPasswordClick={handleResetUserPassword}
            handleSendWelcomeEmail={handleSendWelcomeEmail}
            fetchOrganizationUsers={fetchOrganizationUsers}
            fetchOrganizationContacts={fetchOrganizationContacts}
          />
        </TabPanel>

        <TabPanel value={tabsValue} index={'addresses'}>
          <OrganizationAddressDetailTab
            requestSort={setSortAddress}
            order={organizationAddressFilter.orderDirection}
            orderBy={organizationAddressFilter.orderBy}
            count={organizationAddressesMeta.totalCount}
            page={pageNumberAddress}
            onChangePage={setPageNumberAddress}
            rowsPerOrganizationPage={globalSettings.rowsPerPage}
            addresses={organizationAddresses}
            onEditAddressClick={handleOpenEditAddressDialog}
            onDeleteAddressClick={handleOpenDeleteAddressDialog}
          />
        </TabPanel>

        <TabPanel value={tabsValue} index={'contacts'}>
          <OrganizationContactDetailTab
            requestSort={setSortContact}
            order={organizationContactFilter.orderDirection}
            orderBy={organizationContactFilter.orderBy}
            count={organizationContactsMeta.totalCount}
            page={pageNumberContact}
            onChangePage={setPageNumberContact}
            rowsPerOrganizationPage={globalSettings.rowsPerPage}
            contacts={organizationContacts}
            onEditContactClick={handleOpenEditContactDialog}
            onDeleteContactClick={handleOpenDeleteContactDialog}
          />
        </TabPanel>
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

OrganizationDetailPage.propTypes = {
  organization: PropTypes.object.isRequired,
  getOrganization: PropTypes.func.isRequired,
  organizationUsersFilter: PropTypes.object,
  organizationAddressFilter: PropTypes.object,
  organizationContactFilter: PropTypes.object,
  organizationUsersMeta: PropTypes.object,
  organizationUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalSettings: PropTypes.object,
  id: PropTypes.number,
  updateAppFilterStates: PropTypes.func,
  updateLocalLoader: PropTypes.func,
  sendWelcomeEmail: PropTypes.func,
  organizationAddresses: PropTypes.arrayOf(PropTypes.object),
  organizationAddressesMeta: PropTypes.object,
  getOrganizationAddresses: PropTypes.func,
  createOrganizationAddress: PropTypes.func,
  editOrganizationAddress: PropTypes.func,
  deleteOrganizationAddress: PropTypes.func,
  getOrganizationContacts: PropTypes.func,
  createOrganizationContact: PropTypes.func,
  editOrganizationContact: PropTypes.func,
  deleteOrganizationContact: PropTypes.func,
  organizationContacts: PropTypes.arrayOf(PropTypes.object),
  organizationContactsMeta: PropTypes.object,
  exportOrganizationAddresses: PropTypes.func,
  exportOrganizationContacts: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganization,
      deleteOrganization,
      editOrganization,
      updateLocalLoader,
      updateAppFilterStates,
      getOrganizationUsers,
      editOrganizationUser,
      createOrganizationUser,
      deleteOrganizationUser,
      forgottenPasswordRequest,
      sendWelcomeEmail,
      getOrganizationAddresses,
      createOrganizationAddress,
      editOrganizationAddress,
      deleteOrganizationAddress,
      getOrganizationContacts,
      createOrganizationContact,
      editOrganizationContact,
      deleteOrganizationContact,
      exportOrganizationAddresses,
      exportOrganizationContacts,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      organization: store.Organization.data,
      organizationLoading: store.Organization.isLoading,
      organizationUsersFilter: store.AppFilterStates.listOrganizationUsersFilters,
      organizationAddressFilter: store.AppFilterStates.listOrganizationAddressesFilters,
      organizationContactFilter: store.AppFilterStates.listOrganizationContactsFilters,
      organizationUsers: store.OrganizationUsers.data,
      organizationUsersMeta: store.OrganizationUsers.meta,
      organizationAddresses: store.OrganizationAddresses.data,
      organizationAddressesMeta: store.OrganizationAddresses.meta,
      organizationContacts: store.OrganizationContacts.data,
      organizationContactsMeta: store.OrganizationContacts.meta,
      globalSettings: store.GlobalSettings,
    }
  }, mapDispatchToProps)
)(OrganizationDetailPage)
