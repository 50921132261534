import React from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {required, samePasswordValidation} from '_helper/projectSpecific/validations'
import {bindActionCreators, compose} from 'redux'
import {resetPassword} from '_redux/_action/global/authActions'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import GlobalButton from '_component/global/common/button/GlobalButton'
import PasswordInput from '_component/global/common/formField/PasswordInput'
import Box from '@material-ui/core/Box'

const ResetPasswordForm = (props) => {
  const {resetPassword, token} = props

  const handleSubmit = (values) => {
    return resetPassword(token, {password: values.password})
  }

  return (
    <Form onSubmit={handleSubmit} validate={samePasswordValidation}>
      {(props) => {
        const {handleSubmit, submitting} = props

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Field
              name="password"
              label={<Trans>Password</Trans>}
              component={PasswordInput}
              validate={required}
            />
            <Field
              name="password_confirmation"
              label={<Trans>Password again</Trans>}
              component={PasswordInput}
              validate={required}
            />
            <Box pt={1}>
              <GlobalButton fullWidth backgroundColor="primary" type="submit" loading={submitting}>
                <Trans>Set new password</Trans>
              </GlobalButton>
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPassword,
    },
    dispatch
  )
}

ResetPasswordForm.propTypes = {
  classes: PropTypes.object,
  resetPassword: PropTypes.func.isRequired,
}

export default compose(connect(null, mapDispatchToProps))(ResetPasswordForm)
