import {Trans} from '@lingui/macro'
import React from 'react'
import AuthPageWrapper from '_component/global/common/wrapper/AuthPageWrapper'
import ContentAuthPageWrapper from '_component/global/common/wrapper/ContentAuthPageWrapper'
import FeedbackForm from '_component/projectSpecific/auth/FeedbackForm'
import {withStyles} from '@material-ui/core/styles'
import feedbackStyle from '_asset/global/jss/component/feedback/feedbackStyle'

const FeedbackPage = (props) => {
  const {
    classes,
    match: {
      params: {token},
    },
  } = props

  return (
    <AuthPageWrapper>
      <ContentAuthPageWrapper
        pageTitle={<Trans>Reset password</Trans>}
        gridContainerClass={classes.feedbackBoxWidth}
      >
        <FeedbackForm token={token} />
      </ContentAuthPageWrapper>
    </AuthPageWrapper>
  )
}

export default withStyles(feedbackStyle)(FeedbackPage)
