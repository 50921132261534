import {Trans} from '@lingui/macro'
import React from 'react'

export const ORGANIZATION_ADDRESSES_LIST_COLUMNS = [
  {
    id: 'name',
    sortable: true,
    order_key: 'name',
    label: <Trans>User name</Trans>,
  },
  {
    id: 'street',
    sortable: true,
    order_key: 'street',
    label: <Trans>Street</Trans>,
  },
  {
    id: 'postalCode',
    sortable: true,
    order_key: 'postalCode',
    label: <Trans>Postal code</Trans>,
  },
  {
    id: 'city',
    sortable: true,
    order_key: 'city',
    label: <Trans>City</Trans>,
  },
  {
    id: 'addressType',
    sortable: true,
    order_key: 'addressType',
    label: <Trans>Address description</Trans>,
  },
  {
    id: 'actions',
    sortable: false,
    order_key: 'actions',
    label: '',
  },
]
