import React from 'react'
import {Chip, withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import globalTableStyle from '_asset/global/jss/component/common/table/globalTableStyle'
import cx from 'classnames'

const GlobalChip = (props) => {
  const {classes, label, size, color, className} = props

  const chipClasses = cx({
    [className]: !!className,
    [classes.chip]: true,
    [classes[color]]: color,
  })

  return <Chip label={label} size={size ? size : 'small'} className={chipClasses} />
}

GlobalChip.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  size: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'danger']),
}

export default withStyles(globalTableStyle)(GlobalChip)
