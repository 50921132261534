import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import CounterProposalForm from '_component/projectSpecific/proposal/proposalForm/CounterProposalForm'

const CounterProposalDialog = (props) => {
  const {topic, availableProposalDates} = props
  const {
    openCounterDialog,
    closeCounterDialog,
    handleCounterProposal,
    addFields,
    removeFields,
    timeFreeCounterProposals,
  } = props

  return (
    <DialogWrapper
      open={openCounterDialog}
      handleClose={closeCounterDialog}
      title={<Trans>Counter proposal</Trans>}
      fullWidth={true}
      maxWidth="md"
    >
      <CounterProposalForm
        onSubmitCounterProposal={handleCounterProposal}
        topic={topic}
        addCounterProposalSet={addFields}
        removeCounterProposalSet={removeFields}
        availableDates={availableProposalDates}
        timeFreeCounterProposals={timeFreeCounterProposals}
      />
    </DialogWrapper>
  )
}

CounterProposalDialog.propTypes = {
  topic: PropTypes.object,
  openCounterDialog: PropTypes.bool,
  closeCounterDialog: PropTypes.func,
  handleCounterProposal: PropTypes.func,
  addFields: PropTypes.func,
  removeFields: PropTypes.func,
}

export default CounterProposalDialog
