import React from 'react'
import {FormControl, FormControlLabel, FormHelperText, withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from 'classnames'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'

const CustomRadioGroup = (props) => {
  const {input, meta, label, options, classes, direction, availableOptions, xs, disabled} = props
  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  const isDisabled = (value) => {
    if (availableOptions) {
      return !availableOptions.includes(value)
    }
    return false
  }

  const radioGroupClasses = cx(classes.customRadioGroupWrapper, {
    [classes.radioGroupFlexDirection]: direction === 'row',
  })

  return (
    <FormControl component="fieldset" className={radioGroupClasses}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <GridContainer className={cx(classes.resetPadding, classes.flexRowDir)}>
        <RadioGroup {...input}>
          {options?.map((item, index) => (
            <GridItem xs={xs} key={index} className={classes.resetPadding}>
              <FormControlLabel
                value={item.value}
                control={<Radio color={'primary'} />}
                label={item.label}
                disabled={disabled || isDisabled(item.value)}
              />
            </GridItem>
          ))}
        </RadioGroup>
      </GridContainer>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

CustomRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.node,
  options: PropTypes.array,
  direction: PropTypes.string,
}

export default withStyles(globalFormFieldStyles)(CustomRadioGroup)
