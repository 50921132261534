import React, {useEffect, useRef, useState} from 'react'
import Divider from '@material-ui/core/Divider'
import {ExpandMore} from '@material-ui/icons'
import globalButtonStyle from '_asset/global/jss/component/common/button/globalButtonStyle'
import {withStyles} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import Button from '@material-ui/core/Button'
import cx from 'classnames'
import PropTypes from 'prop-types'

const DropDownButton = (props) => {
  const {classes, label, backgroundColor, inverted, dropDownItems} = props

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const btnClasses = cx({
    [classes.button]: true,
    [classes.dropDownButton]: true,
    [classes[backgroundColor]]: backgroundColor && !inverted,
    [classes[backgroundColor + 'Inverted']]: backgroundColor && inverted,
  })

  return (
    <>
      <Button
        className={btnClasses}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <span className={classes.dropDownLabel}>{label}</span>
        <Divider orientation="vertical" className={classes.dropDownDivider} />
        <ExpandMore />
      </Button>
      <Popper
        className={classes.dropDownPopper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {dropDownItems?.map((item, index) => {
                    return (
                      <MenuItem key={index} onClick={item.onClick}>
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

DropDownButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.node,
  backgroundColor: PropTypes.string,
  inverted: PropTypes.bool,
  dropDownItems: PropTypes.array,
}

export default withStyles(globalButtonStyle)(DropDownButton)
