import {Trans} from '@lingui/macro'
import React from 'react'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {Field, Form} from 'react-final-form'
import SelectField from '_component/global/common/formField/SelectField'
import {RECURRING_EVENT_PAYMENT_TYPES} from '_helper/projectSpecific/constants'
import NumberInput from '_component/global/common/formField/NumberInput'
import WysiwygInput from '_component/global/common/formField/WysiwygInput'
import {Box, withStyles} from '@material-ui/core'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {createPricing, getEvent, patchPricing} from '_redux/_action/projectSpecific/eventActions'
import {connect} from 'react-redux'
import {required} from '_helper/projectSpecific/validations'
import {eventDetailStyle} from '_asset/projectSpecific/jss/event/eventDetailStyle'
import cx from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'

const EventPricingDialog = (props) => {
  const {event, open, closeDialog, classes, patchPricing, createPricing, getEvent} = props

  const {id, date} = useParams()

  const prepareData = (values) => {
    return {
      recurringEventPayment: values.recurringEventPayment,
      spaceRent: values.spaceRent ? Number(values.spaceRent * 100) : 0,
      equipmentRent: values.equipmentRent ? Number(values.equipmentRent * 100) : 0,
      videoProduction: values.videoProduction ? Number(values.videoProduction * 100) : 0,
      otherPayments: values.otherPayments ? Number(values.otherPayments * 100) : 0,
      marketingCounter: values.marketingCounter ? Number(values.marketingCounter * 100) : 0,
      marketingCounterDescription: values.marketingCounterDescription,
    }
  }

  const handleSubmit = (values) => {
    const data = prepareData(values)
    if (event?.total) {
      return patchPricing(id, date, data).then(() => {
        getEvent(id, date)
        closeDialog()
      })
    } else {
      return createPricing(id, date, data).then(() => {
        getEvent(id, date)
        closeDialog()
      })
    }
  }

  const getInitialValues = () => {
    return {
      recurringEventPayment: event?.recurringEventPayment,
      spaceRent: event?.spaceRent && event.spaceRent / 100,
      equipmentRent: event?.equipmentRent && event.equipmentRent / 100,
      videoProduction: event?.videoProduction && event.videoProduction / 100,
      otherPayments: event?.otherPayments && event.otherPayments / 100,
      marketingCounter: event?.marketingCounter && event.marketingCounter / 100,
      marketingCounterDescription: event?.marketingCounterDescription,
    }
  }

  return (
    <DialogWrapper
      open={open}
      maxWidth="md"
      handleClose={closeDialog}
      title={<Trans>Price items (prices without vat)</Trans>}
    >
      <Form onSubmit={handleSubmit} initialValues={getInitialValues()}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer direction={'row'}>
                <GridItem xs={6}>
                  <Field
                    name="recurringEventPayment"
                    label={<Trans>Recurrent event</Trans>}
                    component={SelectField}
                    options={RECURRING_EVENT_PAYMENT_TYPES}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    name="spaceRent"
                    label={<Trans>Space rent</Trans>}
                    component={NumberInput}
                    min={0}
                    endAdornment={
                      <InputAdornment position="end">
                        <Trans>CZK</Trans>
                      </InputAdornment>
                    }
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    name="equipmentRent"
                    label={<Trans>Equipment rent</Trans>}
                    component={NumberInput}
                    min={0}
                    endAdornment={
                      <InputAdornment position="end">
                        <Trans>CZK</Trans>
                      </InputAdornment>
                    }
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    name="videoProduction"
                    label={<Trans>Video production</Trans>}
                    component={NumberInput}
                    min={0}
                    endAdornment={
                      <InputAdornment position="end">
                        <Trans>CZK</Trans>
                      </InputAdornment>
                    }
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    name="otherPayments"
                    label={<Trans>Other payments</Trans>}
                    component={NumberInput}
                    min={0}
                    endAdornment={
                      <InputAdornment position="end">
                        <Trans>CZK</Trans>
                      </InputAdornment>
                    }
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    name="marketingCounter"
                    label={<Trans>Marketing counter</Trans>}
                    component={NumberInput}
                    min={0}
                    endAdornment={
                      <InputAdornment position="end">
                        <Trans>CZK</Trans>
                      </InputAdornment>
                    }
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Box
                    py={1}
                    className={cx(
                      classes.labelColor,
                      formProps.values.marketingCounterDescription === undefined
                        ? !!formProps.values.marketingCounter &&
                          !isNaN(formProps.values.marketingCounter)
                          ? classes.requiredColor
                          : null
                        : null
                    )}
                  >
                    <Trans>Marketing counter description</Trans>
                  </Box>
                  <Field
                    name="marketingCounterDescription"
                    component={WysiwygInput}
                    validate={
                      !!formProps.values.marketingCounter &&
                      !isNaN(formProps.values.marketingCounter)
                        ? required
                        : undefined
                    }
                  />
                  <Box className={cx(classes.requiredColor, classes.requiredHelperText)}>
                    {formProps.values.marketingCounterDescription === undefined &&
                      !!formProps.values.marketingCounter &&
                      !isNaN(formProps.values.marketingCounter) && (
                        <Trans>This field is required</Trans>
                      )}
                  </Box>
                </GridItem>
                <GridItem xs={12}>
                  <Box pt={3} display="flex" justifyContent={'flex-end'} flexGrow={1}>
                    <GlobalButton type="submit" backgroundColor="primary">
                      {event?.total ? <Trans>Edit</Trans> : <Trans>Create</Trans>}
                    </GlobalButton>
                  </Box>
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </DialogWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createPricing,
      patchPricing,
      getEvent,
    },
    dispatch
  )
}

export default compose(
  withStyles(eventDetailStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(EventPricingDialog)
