import React, {useEffect, useState} from 'react'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import {Trans} from '@lingui/macro'
import {EventNote} from '@material-ui/icons'
import TabPanel from '_component/global/common/tab/TabPanel'
import {routesMap} from '_config/routesMap'
import ProposalsListTab from '_component/projectSpecific/proposal/proposalList/ProposalsListTab'
import EventsCalendarTab from '_component/projectSpecific/event/eventList/EventCalendarTab'
import EventPageActionHeader from '_component/projectSpecific/event/eventList/EventPageActionHeader'
import {authorizedAbility, Can} from 'App/_provider/authorizedAbility'
import {redirect, unauthorizedRedirectToDashboard} from '_helper/global/functions'
import moment from 'moment'
import ReservationCalendarTab from '_component/projectSpecific/event/eventList/ReservationCalendarTab'

const EventsPage = (props) => {
  const {
    match: {
      params: {tab},
    },
    eventsFilterQuery,
  } = props

  const [activeTab, setActiveTab] = useState(tab)
  const [proposalsLoaded, setProposalsLoaded] = useState(false)
  const [eventsFilter, setEventsFilter] = useState(eventsFilterQuery)
  const [eventsProposalsFilter, setEventsProposalsFilter] = useState()
  const [selectedYear, setSelectedYear] = useState(moment().year())

  const handleChangeTab = (e, tabValue) => {
    setActiveTab(tabValue)
    redirect(`${routesMap.tenant.admin.event}/${tabValue}`)
  }

  const openNewForm = () => {
    if (activeTab === 'proposals') {
      return redirect(`${routesMap.tenant.admin.proposal}/new`)
    }
    return redirect(`${routesMap.tenant.admin.calendar}/new`)
  }

  const canReadProposals = authorizedAbility.can('read', 'Proposals')
  const canReadEvents = authorizedAbility.can('read', 'Events')

  useEffect(() => {
    if (canReadProposals || canReadEvents) {
      if (activeTab === 'proposals' && !canReadProposals) {
        setActiveTab('calendar')
        redirect(routesMap.tenant.admin.calendar)
      } else if (activeTab === 'calendar' && !canReadEvents) {
        setActiveTab('proposals')
        redirect(routesMap.tenant.admin.proposal)
      } else {
        setActiveTab(tab)
      }
    } else {
      unauthorizedRedirectToDashboard(<Trans>You are not authorized to list events.</Trans>)
    }
  }, [tab])

  const getTabList = () => {
    const tabList = []
    if (canReadProposals) {
      tabList.push({label: <Trans>Event proposals</Trans>, value: 'proposals'})
    }
    if (canReadEvents) {
      tabList.push({label: <Trans>Events calendar</Trans>, value: 'calendar'})
    }
    if (canReadProposals && canReadEvents) {
      tabList.push({label: <Trans>Reservation overview</Trans>, value: 'reservations'})
    }
    return tabList
  }

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper
        tabsValue={activeTab}
        tabList={getTabList()}
        handleChangeTab={handleChangeTab}
        iconPageHeader={<EventNote />}
        toolbar={false}
        actionsHeader={
          <EventPageActionHeader
            tab={activeTab}
            openNewForm={openNewForm}
            setEventsFilter={setEventsFilter}
            eventsFilter={eventsFilter}
            setEventsProposalsFilter={setEventsProposalsFilter}
            eventsProposalsFilter={eventsProposalsFilter}
            selectedYear={selectedYear}
          />
        }
      >
        <Can I="read" a="Proposals">
          <TabPanel value={activeTab} index={'proposals'}>
            <ProposalsListTab
              proposalsLoaded={proposalsLoaded}
              setProposalsLoaded={setProposalsLoaded}
            />
          </TabPanel>
        </Can>
        <Can I="read" an="Events">
          <TabPanel value={activeTab} index={'calendar'}>
            <EventsCalendarTab
              filter={eventsFilter}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </TabPanel>
        </Can>
        {authorizedAbility.can('read', 'Proposals') && authorizedAbility.can('read', 'Events') && (
          <TabPanel value={activeTab} index={'reservations'}>
            <ReservationCalendarTab
              filter={eventsProposalsFilter}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </TabPanel>
        )}
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

EventsPage.propTypes = {}

export default EventsPage
