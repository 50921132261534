import React from 'react'
import {List, ListItem, ListItemIcon, ListItemText, SvgIcon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import globalLayoutElementsStyle from '_asset/global/jss/component/layoutElement/globalLayoutElementsStyle'
import browserHistoryAccessor from 'App/_provider/history'
import {adminRoutes} from '_config/_route/tenant/adminRoutes'
import PropTypes from 'prop-types'
import {Can} from 'App/_provider/authorizedAbility'
import {redirect} from '_helper/global/functions'

const DrawerMenu = (props) => {
  const {classes, openedDrawer} = props

  const handleLinkClick = (route) => (event) => {
    redirect(route)
  }

  const hiddenMenuItem = cx({
    [classes.hiddenElement]: !openedDrawer,
  })

  const isMenuActive = (path) => {
    let historyPath = browserHistoryAccessor.location.pathname
    const baseHistoryPath = historyPath.split('/').slice(0, 3).join('/')
    const basePath = path.split('/').slice(0, 3).join('/')
    if (baseHistoryPath === basePath) {
      return classes.activeMenu
    }
    return null
  }

  return (
    <List>
      {adminRoutes
        .filter((adminRoute) => adminRoute.visible)
        .map((route, index) => {
          const linkRoute = route.sidebarPath ?? route.path
          const linkItem = (
            <ListItem
              key={index}
              className={isMenuActive(route.path)}
              button
              onClick={handleLinkClick(linkRoute)}
            >
              <ListItemIcon>
                <SvgIcon component={route.icon} />
              </ListItemIcon>
              <ListItemText primary={route.name} className={hiddenMenuItem} />
            </ListItem>
          )
          if (route.authorized) {
            return (
              <Can key={index} I={route.authorized.actions} a={route.authorized.subject}>
                {linkItem}
              </Can>
            )
          } else {
            return linkItem
          }
        })}
    </List>
  )
}

DrawerMenu.propTypes = {
  classes: PropTypes.object,
  openedDrawer: PropTypes.bool,
}

export default withStyles(globalLayoutElementsStyle)(DrawerMenu)
