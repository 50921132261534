import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import HorizontalStepFormWrapper from '_component/global/common/stepper/HorrizontalStepFormWrapper'
import {withStyles} from '@material-ui/core/styles'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import CustomCheckbox from '_component/global/common/formField/CustomCheckbox'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import TextInput from '_component/global/common/formField/TextInput'
import {ORGANIZATIONAL_SETUP, PROPOSAL_REQUIREMENTS} from '_helper/projectSpecific/constants'
import GlobalStepperButtons from '_component/global/common/button/GlobalStepperButtons'
import SelectField from '_component/global/common/formField/SelectField'
import Tooltip from '@material-ui/core/Tooltip'
import {Info} from '@material-ui/icons'
import ConfigurationDialog from '_component/projectSpecific/proposal/proposalForm/ConfigurationDialog'

const ProposalRequirementsForm = (props) => {
  const {onSubmitRequirements, goStepBack, initialValues, classes} = props

  const [configurationDialog, setConfigurationDialog] = useState(false)

  return (
    <HorizontalStepFormWrapper mediumWidth={8} largeWidth={6}>
      <ConfigurationDialog
        openDialog={configurationDialog}
        closeDialog={() => setConfigurationDialog(false)}
      />
      <Form onSubmit={onSubmitRequirements} initialValues={initialValues}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              {PROPOSAL_REQUIREMENTS.map((proposalRequirement, indexProposal) => {
                return (
                  <div key={indexProposal} className={classes.requirementBox}>
                    <div className={classes.spaceConfigurationLabel}>
                      <h4>{proposalRequirement.header}</h4>
                      {proposalRequirement.group === 'organizationalRequirements' && (
                        <Tooltip
                          title={<Trans>Configuration map</Trans>}
                          placement="top"
                          enterDelay={300}
                        >
                          <a href="/planek-usporadani.pdf" target="_blank">
                            <Info />
                          </a>
                        </Tooltip>
                      )}
                    </div>
                    <GridContainer className={classes.resetPadding}>
                      {proposalRequirement.requirements.map((requirement, index) => {
                        return (
                          <GridItem key={index} xs={3} className={classes.resetPadding}>
                            <Field
                              name={proposalRequirement.group}
                              value={requirement.value}
                              type="checkbox"
                              label={requirement.label}
                              component={CustomCheckbox}
                            />
                          </GridItem>
                        )
                      })}
                      {proposalRequirement.setUp && (
                        <GridItem xs={6} className={classes.resetPadding}>
                          <Field
                            name={proposalRequirement.setUp.name}
                            label={proposalRequirement.setUp.label}
                            component={SelectField}
                            emptyOption={true}
                            options={ORGANIZATIONAL_SETUP}
                          />
                        </GridItem>
                      )}
                    </GridContainer>
                    <Field
                      name={proposalRequirement.note.name}
                      label={proposalRequirement.note.label}
                      component={TextInput}
                      variant={'outlined'}
                      multiline={true}
                    />
                  </div>
                )
              })}
              <GlobalStepperButtons
                handleBack={goStepBack}
                submitButtonLabel={<Trans>Create</Trans>}
              />
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

ProposalRequirementsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmitRequirements: PropTypes.func,
  goStepBack: PropTypes.func,
}

export default withStyles(stepperFormStyle)(ProposalRequirementsForm)
