import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import globalTableStyle from '_asset/global/jss/component/common/table/globalTableStyle'

const Loader = (props) => {
  const {classes, className, wrapper = false} = props
  return wrapper ? (
    <div className={classes.loaderWrapper}>
      <div className={cx(classes.loader, className)} />
    </div>
  ) : (
    <div className={cx(classes.loader, className)} />
  )
}

Loader.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  wrapper: PropTypes.bool,
}

export default withStyles(globalTableStyle)(Loader)
