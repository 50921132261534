import React from 'react'
import 'App/appStyle.scss'
import {Provider} from 'react-redux'
import store from 'App/_provider/store'
import {AbilityContextProvider, authorizedAbility} from 'App/_provider/authorizedAbility'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import App from 'App/App'
import {ThemeProvider} from '@material-ui/core/styles'
import defaultTheme from 'App/_theme/defaultTheme'

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <AbilityContextProvider value={authorizedAbility}>
        <ThemeProvider theme={defaultTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AbilityContextProvider>
    </Provider>
  )
}

export default AppWrapper
