import {combineReducers} from 'redux'
import globalSettingsReducer from '_redux/_reducer/global/globalSettingsReducer'
import {LoaderReducer} from '_redux/_reducer/global/loaderReducer'
import {AppFilterStatesReducer} from '_redux/_reducer/global/appFilterStatesReducer'
import {
  SimpleListTopicsReducer,
  TopicReducer,
  TopicsCalendarReducer,
} from '_redux/_reducer/projectSpecific/topicsReducer'
import {
  ListOrganizationsReducer,
  OrganizationsReducer,
} from '_redux/_reducer/projectSpecific/organizationsReducer'
import {
  ListOrganizationUsersReducer,
  OrganizationUserReducer,
} from '_redux/_reducer/projectSpecific/organizationUsersReducer'
import {
  EventReducer,
  EventsCalendarReducer,
  EventSharingReducer,
  ListEventsReducer,
} from '_redux/_reducer/projectSpecific/eventReducer'
import {
  ListProposalsReducer,
  ProposalConfirmDataReducer,
  ProposalReducer,
} from '_redux/_reducer/projectSpecific/proposalsReducer'
import {RolesReducer} from '_redux/_reducer/projectSpecific/rolesReducer'
import {ListEventParticipantsReducer} from '_redux/_reducer/projectSpecific/eventParticipantsReducer'
import {MyOrganizationReducer} from './projectSpecific/myOrganizationReducer'
import {MyAccountReducer} from './projectSpecific/myAccountReducer'
import {
  ListOrganizationAddressesReducer,
  OrganizationAddressReducer,
} from '_redux/_reducer/projectSpecific/organizationAddressReducer'
import {
  ListOrganizationContactsReducer,
  OrganizationContactReducer,
} from '_redux/_reducer/projectSpecific/organizationContactReducer'
import {EventStatisticsReducer} from '_redux/_reducer/projectSpecific/eventStatisticsReducer'

const rootReducer = combineReducers({
  // global reducers
  GlobalSettings: globalSettingsReducer,
  Loader: LoaderReducer,
  AppFilterStates: AppFilterStatesReducer,

  // project reducers
  TopicsCalendar: TopicsCalendarReducer,
  SimpleListTopics: SimpleListTopicsReducer,
  Topic: TopicReducer,
  Organization: OrganizationsReducer,
  MyOrganization: MyOrganizationReducer,
  MyAccount: MyAccountReducer,
  Organizations: ListOrganizationsReducer,
  OrganizationUser: OrganizationUserReducer,
  OrganizationUsers: ListOrganizationUsersReducer,
  OrganizationAddress: OrganizationAddressReducer,
  OrganizationAddresses: ListOrganizationAddressesReducer,
  OrganizationContact: OrganizationContactReducer,
  OrganizationContacts: ListOrganizationContactsReducer,
  Event: EventReducer,
  Events: ListEventsReducer,
  EventParticipants: ListEventParticipantsReducer,
  EventSharing: EventSharingReducer,
  Proposal: ProposalReducer,
  Proposals: ListProposalsReducer,
  ProposalConfirmData: ProposalConfirmDataReducer,
  Roles: RolesReducer,
  EventsCalendar: EventsCalendarReducer,
  Statistics: EventStatisticsReducer,
})

export default rootReducer
