import {Trans} from '@lingui/macro'
import React from 'react'

export const ORGANIZATION_LIST_COLUMNS = [
  {
    id: 'name',
    sortable: true,
    order_key: 'name',
    label: <Trans>Organization name</Trans>,
  },
  {
    id: 'admin.name',
    sortable: true,
    order_key: 'admin.name',
    label: <Trans>Administrator name</Trans>,
  },
  {
    id: 'admin.email',
    sortable: true,
    order_key: 'admin.email',
    label: <Trans>Administrator e-mail</Trans>,
  },
  {
    id: 'admin.lastLogin',
    sortable: false,
    order_key: 'admin.lastLogin',
    label: <Trans>Last login</Trans>,
  },
  {
    id: 'actions',
    sortable: false,
    order_key: 'actions',
    label: '',
  },
]
