import {
  blackColor,
  secondaryColor,
  dangerColor,
  grayColor,
  hexToRgb,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
  whiteColor,
  buttonBackgroundColorVariant,
  buttonBackgroundColorVariantInverted,
  buttonColorVariant,
} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'

const globalButtonStyle = (theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '20px 0 0 0',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: grayColor[0],
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.12)',
    border: 'none',
    borderRadius: BORDER_RADIUS,
    position: 'relative',
    padding: '8px 30px',
    margin: '.3125rem 1px',
    fontSize: '12px !important',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition:
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: '1.42',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: grayColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.2)',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      marginTop: '-1em',
      marginBottom: '-1em',
      fontSize: '1.1rem',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& svg': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      width: '18px',
      height: '18px',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        marginTop: '0px',
        position: 'absolute',
        width: '100%',
        transform: 'none',
        left: '0px',
        top: '0px',
        height: '100%',
        lineHeight: '41px',
        fontSize: '20px',
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  primary: buttonBackgroundColorVariant(primaryColor[0]),
  primaryInverted: buttonBackgroundColorVariantInverted(primaryColor[0], true),
  secondary: buttonBackgroundColorVariant(secondaryColor[0]),
  secondaryInverted: buttonBackgroundColorVariantInverted(secondaryColor[0]),
  info: buttonBackgroundColorVariant(infoColor[0]),
  infoInverted: buttonBackgroundColorVariantInverted(infoColor[0]),
  success: buttonBackgroundColorVariant(successColor[0]),
  successInverted: buttonBackgroundColorVariantInverted(successColor[0]),
  warning: buttonBackgroundColorVariant(warningColor[0]),
  warningInverted: buttonBackgroundColorVariantInverted(warningColor[0]),
  danger: buttonBackgroundColorVariant(dangerColor[0]),
  dangerInverted: buttonBackgroundColorVariantInverted(dangerColor[0], true),
  iconPrimary: buttonColorVariant(primaryColor[0]),
  iconSecondary: buttonColorVariant(secondaryColor[0]),
  iconInfo: buttonColorVariant(infoColor[0]),
  iconSuccess: buttonColorVariant(successColor[0]),
  iconWarning: buttonColorVariant(warningColor[0]),
  iconDanger: buttonColorVariant(dangerColor[0]),
  simple: {
    '&,&:focus,&:hover': {
      color: primaryColor[0],
      background: 'transparent',
      boxShadow: 'none',
    },
    '&$primary': {
      '&,&:focus,&:hover,&:visited': {
        color: primaryColor[0],
      },
    },
    '&$secondary': {
      '&,&:focus,&:hover,&:visited': {
        color: secondaryColor[0],
      },
    },
    '&$info': {
      '&,&:focus,&:hover,&:visited': {
        color: infoColor[0],
      },
    },
    '&$success': {
      '&,&:focus,&:hover,&:visited': {
        color: successColor[0],
      },
    },
    '&$warning': {
      '&,&:focus,&:hover,&:visited': {
        color: warningColor[0],
      },
    },
    '&$danger': {
      '&,&:focus,&:hover,&:visited': {
        color: dangerColor[0],
      },
    },
  },
  disabled: {
    color: whiteColor + '!important',
    opacity: '0.65',
    pointerEvents: 'none',
  },
  round: {
    borderRadius: '30px',
  },
  block: {
    width: '100% !important',
  },
  link: {
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: grayColor[0],
      boxShadow: 'none',
    },
  },
  justIcon: {
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
    height: '41px',
    minWidth: '41px',
    width: '41px',
    '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
      marginRight: '0px',
    },
  },
  loadingWrapper: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
    color: whiteColor,
    '& svg': {
      width: 'initial',
      height: 'initial',
      marginRight: 'initial',
    },
  },
  linearProgress: {
    backgroundColor: primaryColor[0],
    position: 'absolute',
    top: 10,
    left: 1,
    opacity: 0.3,
    width: 'calc(100%)',
    height: 'calc(100% - 10px)',
    borderRadius: BORDER_RADIUS,
    margin: '-5px 0',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: whiteColor,
    },
  },
  tableActionsButton: {
    borderColor: 'transparent',
  },
  actionHeaderButton: {
    height: '41px',
  },
  dropDownDivider: {
    height: '41px',
    width: '2px',
    backgroundColor: whiteColor,
    marginLeft: '30px',
  },
  dropDownLabel: {
    marginTop: 4,
  },
  dropDownButton: {
    border: '0px',
    padding: '0 0 0 30px',
    maxHeight: '41px',
    '& svg': {
      paddingLeft: '0px !important',
      width: '24px',
    },
  },
  dropDownPopper: {
    zIndex: 999,
    borderRadius: BORDER_RADIUS,
    paddingTop: '4px',
    paddingBottom: '4px',
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      padding: '4px 10px',
      '& .MuiSvgIcon-root': {
        paddingRight: '10px',
        color: grayColor[0],
      },
    },
  },
  newButton: {
    height: 41,
    fontSize: 12,
    boxSizing: 'border-box',

    '& svg': {
      width: 20,
      height: 20,
    },

    '& span': {
      alignItems: 'end',
    },
  },
})

export default globalButtonStyle
