import {Ability} from '@casl/ability'
import {getActualUser} from '_helper/global/functions'
import {createContext} from 'react'
import {createContextualCan} from '@casl/react'
import mapPermissionsToAbilities from '_config/mapPermissionsToAbilities'

const currentUser = getActualUser()
const permissions = currentUser?.user?.permissions || []

export const authorizedAbility = new Ability(mapPermissionsToAbilities(permissions))

const abilityContext = createContext()
export const AbilityContextProvider = abilityContext.Provider
export const Can = createContextualCan(abilityContext.Consumer)
