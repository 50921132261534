import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import GridItem from '_component/global/common/grid/GridItem'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import Card from '_component/global/common/card/Card'
import CardHeader from '_component/global/common/card/CardHeader'
import CardBody from '_component/global/common/card/CardBody'
import CardIcon from '_component/global/common/card/CardIcon'
import {Divider} from '@material-ui/core'
import GlobalTabs from '_component/global/common/tab/GlobalTabs'
import {redirect} from '_helper/global/functions'
import GlobalButton from '_component/global/common/button/GlobalButton'

const ContentAdminPageWrapper = (props) => {
  const {
    iconPageHeader,
    pageTitle,
    cardPageTitle,
    actionsHeader,
    bodyClasses,
    classes,
    toolbar,
    children,
    profile,
    color,
    noCard,
    handleChangeTab,
    tabList,
    tabsValue,
    hasBackButton,
    backButtonUrl,
    backButtonText,
  } = props

  const renderCard = () => {
    return (
      <Card className={cx({[classes.cardWrapper]: !toolbar})} profile={profile} color={color}>
        <CardHeader icon className={classes.contentCardHeader}>
          <CardIcon color="primary">{iconPageHeader}</CardIcon>
          <h4 className={classes.cardIconTitle}>{cardPageTitle}</h4>
          {!toolbar && <div className={classes.actionCardButtons}>{actionsHeader}</div>}
        </CardHeader>
        <CardBody
          className={cx(bodyClasses, !actionsHeader && !toolbar && classes.cardBodyWithoutToolbar)}
        >
          {children}
        </CardBody>
      </Card>
    )
  }

  const renderPageContent = () => {
    return noCard ? children : renderCard()
  }

  const renderPageTitle = () => {
    const handleBack = () => {
      redirect(backButtonUrl)
    }
    if (hasBackButton) {
      return (
        <>
          <span className="page-title">{pageTitle}</span>
          <GlobalButton
            backgroundColor="primary"
            className="page-title-button"
            onClick={handleBack}
          >
            {backButtonText}
          </GlobalButton>
        </>
      )
    }
    return pageTitle
  }

  const adminPageTitleStyle = cx({
    [classes.adminPageTitle]: true,
    [classes.adminPageTitleWithBackButton]: hasBackButton,
  })

  return (
    <GridItem xs={12}>
      <div className={classes.adminPageTitleWrapper}>
        {tabList && tabList.length !== 0 ? (
          <GlobalTabs tabsValue={tabsValue} handleChangeTab={handleChangeTab} tabList={tabList} />
        ) : (
          <>
            <div className={adminPageTitleStyle}>{renderPageTitle()}</div>
            <Divider variant="middle" />
          </>
        )}
      </div>
      {renderPageContent()}
    </GridItem>
  )
}

ContentAdminPageWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  bodyClasses: PropTypes.object,
  iconPageHeader: PropTypes.object,
  pageTitle: PropTypes.node,
  toolbar: PropTypes.bool,
  actionsHeader: PropTypes.node,
  children: PropTypes.node,
  tabList: PropTypes.array,
  handleChangeTab: PropTypes.func,
  tabsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasBackButton: PropTypes.bool,
  backButtonUrl: PropTypes.string,
  backButtonText: PropTypes.object,
}

export default withStyles(globalWrapperStyles)(ContentAdminPageWrapper)
