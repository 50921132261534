import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {horizontalStepperStyle} from '_asset/global/jss/component/stepper/horizontalStepperStyle'
import GridItem from '_component/global/common/grid/GridItem'
import GridContainer from '_component/global/common/grid/GridContainer'

const HorizontalStepFormWrapper = (props) => {
  const {children, classes} = props

  return (
    <GridContainer justifyContent="center">
      <GridItem sm={11} md={9} xl={7}>
        <div className={classes.horizontalStepForm}>{children}</div>
      </GridItem>
    </GridContainer>
  )
}

HorizontalStepFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default withStyles(horizontalStepperStyle)(HorizontalStepFormWrapper)
