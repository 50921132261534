import React from 'react'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'

const DialogCreateFormButtons = (props) => {
  const buttonText = props.buttonText || <Trans>Create</Trans>
  return (
    <GridContainer justifyContent="flex-end">
      <GridItem>
        <GlobalButton backgroundColor="primary" type="submit" loading={props.submitting}>
          {buttonText}
        </GlobalButton>
      </GridItem>
    </GridContainer>
  )
}

DialogCreateFormButtons.propTypes = {
  deleteItem: PropTypes.func,
}

export default DialogCreateFormButtons
