import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from 'classnames'

const SwitcherField = (props) => {
  const {
    helpText,
    classes,
    disabled,
    input: {onChange, checked},
    valueA,
    valueB,
    justifyStart,
    justifyEnd,
    className,
    zeroMarginTop,
  } = props

  const switcherClasses = cx(
    classes.switcher,
    justifyStart && classes.switcherJustifyStart,
    justifyEnd && classes.switcherJustifyEnd,
    zeroMarginTop && classes.switcherZeroMarginTop,
    className
  )

  return (
    <FormControl className={classes.formSwitchControl} disabled={disabled}>
      <div className={switcherClasses}>
        <span>{valueA}</span>
        <Switch checked={checked} onChange={onChange} color="primary" />
        <span>{valueB}</span>
      </div>
      <FormHelperText className={classes.helperTextSwitcherMargin}>{helpText}</FormHelperText>
    </FormControl>
  )
}

export default withStyles(globalFormFieldStyles)(SwitcherField)
