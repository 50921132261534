import React from 'react'
import {KeyboardDatePicker, DatePicker} from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import {withStyles} from '@material-ui/core/styles'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import {DATE_FORMAT} from '_helper/global/constants'
import {Trans} from '@lingui/macro'
import cx from 'classnames'

const DateInput = (props) => {
  const {
    helperText,
    id,
    input: {value: propsValue, ...input},
    meta,
    label,
    classes,
    onCustomChange,
    classNames,
    allowKeyboardControl = true,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={cx(classes.dateTimePicker, classNames)}>
      {allowKeyboardControl ? (
        <KeyboardDatePicker
          ampm={true}
          autoOk={true}
          format={DATE_FORMAT}
          label={label}
          value={propsValue ? propsValue : null}
          {...input}
          onChange={(date, value) => {
            input.onChange && input.onChange(date, value)
            onCustomChange && onCustomChange(date, input?.name)
          }}
          invalidDateMessage={<Trans>Invalid Date Format</Trans>}
          maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
          minDateMessage={<Trans>Date should not be before minimal date</Trans>}
          cancelLabel={<Trans>Close</Trans>}
          okLabel={<Trans>Ok</Trans>}
          clearLabel={<Trans>Reset</Trans>}
          {...rest}
        />
      ) : (
        <DatePicker
          ampm={true}
          autoOk={true}
          format={DATE_FORMAT}
          label={label}
          value={propsValue ? propsValue : null}
          {...input}
          onChange={(date, value) => {
            input.onChange && input.onChange(date, value)
            onCustomChange && onCustomChange(date, input?.name)
          }}
          invalidDateMessage={<Trans>Invalid Date Format</Trans>}
          maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
          minDateMessage={<Trans>Date should not be before minimal date</Trans>}
          cancelLabel={<Trans>Close</Trans>}
          okLabel={<Trans>Ok</Trans>}
          clearLabel={<Trans>Reset</Trans>}
          {...rest}
        />
      )}
      <FormHelperText id={id + '-text'}>{errorMessage || helperText || ' '}</FormHelperText>
    </div>
  )
}

export default withStyles(globalFormFieldStyles)(DateInput)
