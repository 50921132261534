import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import SpaceConfigurationItem from '_component/projectSpecific/proposal/proposalForm/SpaceConfigurationItem'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {getSpaceConfigurationImage} from '_helper/projectSpecific/functions'

const ProposalSpaceConfigurationModal = (props) => {
  const {data, closeModal} = props
  return (
    <DialogWrapper
      open={data !== null}
      maxWidth="sm"
      handleClose={closeModal}
      title={<Trans>Space configuration</Trans>}
    >
      {data !== null && (
        <GridContainer justifyContent="center">
          <GridItem xs={6}>
            <SpaceConfigurationItem>
              {data && getSpaceConfigurationImage(data)}
            </SpaceConfigurationItem>
          </GridItem>
        </GridContainer>
      )}
    </DialogWrapper>
  )
}

ProposalSpaceConfigurationModal.propTypes = {
  closeModal: PropTypes.func,
}

export default ProposalSpaceConfigurationModal
