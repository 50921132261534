import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import globalButtonStyle from '_asset/global/jss/component/common/button/globalButtonStyle'
import IconButton from '@material-ui/core/IconButton'

const GlobalIconButton = (props) => {
  const {classes, color, className, muiClasses, children, ...rest} = props

  const btnClasses = cx({
    [classes[color]]: color,
    [className]: className,
  })
  return (
    <IconButton classes={muiClasses} className={btnClasses} {...rest}>
      {children}
    </IconButton>
  )
}

GlobalIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['iconPrimary', 'iconSecondary', 'iconInfo', 'iconSuccess', 'iconWarning', 'iconDanger']),
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node,
}

export default withStyles(globalButtonStyle)(GlobalIconButton)
