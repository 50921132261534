import React from 'react'
import {Close} from '@material-ui/icons'
import {withStyles} from '@material-ui/core'
import {counterProposalActionsWrapperStyle} from '_asset/projectSpecific/jss/proposal/counterProposalActionsWrapperStyle'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'
import ProposalActionsFormFields from '_component/projectSpecific/proposal/proposalForm/ProposaActionsFormFields'
import moment from 'moment'
import GridContainer from '_component/global/common/grid/GridContainer'

const CounterProposalActionsWrapper = (props) => {
  const {
    availableDates,
    formProps,
    index,
    topic,
    classes,
    fieldsCount,
    timeFreeCounterProposals,
  } = props
  const {removeCounterProposalSet, handleDisableDates} = props

  const selectedDate =
    formProps.values.proposalActions &&
    moment(formProps.values.proposalActions[index]?.date).format(DATABASE_DATE_FORMAT)
  const timeSlotOptions = availableDates[selectedDate] || []
  const customTimeSlot =
    formProps.values.proposalActions &&
    formProps.values.proposalActions[index]?.timeSlot === 'CUSTOM'

  return (
    <div className={classes.counterWrapper}>
      {fieldsCount > 1 && (
        <span className={classes.counterCloseButton}>
          <Close onClick={removeCounterProposalSet} />
        </span>
      )}
      <GridContainer>
        <ProposalActionsFormFields
          timeFreeCounterProposals={timeFreeCounterProposals}
          index={index}
          topic={topic}
          handleDisableDates={handleDisableDates}
          timeSlotOptions={timeSlotOptions}
          selectedDate={selectedDate}
          customTimeSlot={customTimeSlot}
        />
      </GridContainer>
    </div>
  )
}

export default withStyles(counterProposalActionsWrapperStyle)(CounterProposalActionsWrapper)
