import {successColor, infoColor, dangerColor, warningColor} from '_asset/global/jss/styleHelpers'
import {BORDER_RADIUS} from '_helper/global/constants'

const globalToastStyle = () => ({
  containerStyle: {
    '& .Toastify__toast--success': {
      backgroundColor: successColor[0],
    },
    '& .Toastify__toast--warning': {
      backgroundColor: warningColor[0],
    },
    '& .Toastify__toast--info': {
      backgroundColor: infoColor[0],
    },
    '& .Toastify__toast--error': {
      backgroundColor: dangerColor[0],
    },
    '& .Toastify__toast': {
      height: 'auto',
      minHeight: '48px',
      borderRadius: BORDER_RADIUS,
      padding: '12px 15px',
      fontSize: '12px',
    },
    '& .Toastify__toast-container': {
      minWidth: '400px',
    },
    '& .Toastify__toast-body': {
      '& strong': {
        maxWidth: 250,
        width: 250,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      },
    },
  },
  toast: {
    display: 'flex',
    alignItems: 'center',
  },
  toastIcon: {
    marginRight: '10px',
  },
})
export default globalToastStyle
