import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import Cell from '_component/global/common/table/Cell'
import ProposalStatus from '_component/projectSpecific/proposal/proposalList/ProposalStatus'
import ProposalDateTimeCell from '_component/projectSpecific/proposal/proposalList/ProposalDateTimeCell'
import TableActionsButton from '_component/global/common/button/TableActionsButton'
import {TableRow, Tooltip} from '@material-ui/core'
import {Visibility} from '@material-ui/icons'
import ProposalSpaceConfigurationCell from '_component/projectSpecific/proposal/proposalList/ProposalSpaceConfigurationCell'
import {Can} from 'App/_provider/authorizedAbility'
import {EVENT_TYPES} from '_helper/projectSpecific/constants'

const ProposalRow = (props) => {
  const {row, onDetailClick, openProposalActionsModal, openSpaceConfiguration} = props

  const getTypeLabel = (rowType) => {
    return EVENT_TYPES.filter((type) => type.value === rowType)[0].label
  }

  return (
    <TableRow>
      <Cell align="left">
        <ProposalStatus status={row.status} />
      </Cell>
      <Cell>{row?.topic?.name}</Cell>
      <Cell>{row.name}</Cell>
      <Cell>{row.type && getTypeLabel(row.type)}</Cell>
      <Cell align="right">{row.expiresInDays}</Cell>
      <Cell>
        {row.proposalActions && (
          <ProposalDateTimeCell
            proposalActions={row.proposalActions}
            openProposalActionsModal={openProposalActionsModal}
          />
        )}
      </Cell>
      <Cell>
        {row.spaceConfiguration && (
          <ProposalSpaceConfigurationCell
            spaceConfiguration={row.spaceConfiguration}
            openSpaceConfiguration={openSpaceConfiguration}
          />
        )}
      </Cell>
      <Cell>{row?.organization?.name}</Cell>
      <Can I="read" a="Proposal">
        <Cell isActions={true} isRowAction>
          <TableActionsButton onClick={onDetailClick(row.id)} backgroundColor="secondary">
            <Tooltip title={<Trans>Proposal</Trans>}>
              <Visibility />
            </Tooltip>
          </TableActionsButton>
        </Cell>
      </Can>
    </TableRow>
  )
}

ProposalRow.propTypes = {
  row: PropTypes.object,
  onDetailClick: PropTypes.func,
  openProposalActionsModal: PropTypes.func,
  openSpaceConfiguration: PropTypes.func,
}

export default ProposalRow
