import {FETCH_LIST_ROLES_REQUEST, FETCH_LIST_ROLES_SUCCESS} from '_redux/_action/projectSpecific/types'

const initState = {
  data: [],
  isLoading: true,
}

export const RolesReducer = (state = initState, action = null) => {
  switch (action.type) {
    case FETCH_LIST_ROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case FETCH_LIST_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data.objects,
      }
    default:
      return state
  }
}
