const mapPermissionsToAbilities = (permissions) => {
  const rules = []
  permissions.forEach((permission) => {
    switch (permission) {
      case 'can_list_events':
        rules.push({subject: 'Events', actions: 'read'})
        break
      case 'can_create_events':
        rules.push({subject: 'Events', actions: 'create'})
        break
      case 'can_get_events':
        rules.push({subject: 'Event', actions: 'read'})
        break
      case 'can_share_events':
        rules.push({subject: 'Events', actions: 'share'})
        break
      case 'can_update_events':
        rules.push({subject: 'Events', actions: 'update'})
        break
      case 'can_update_events_for_partner':
        rules.push({subject: 'Events', actions: 'partnerUpdate'})
        break
      case 'can_delete_events':
        rules.push({subject: 'Events', actions: 'delete'})
        break
      case 'can_list_participants':
        rules.push({subject: 'Participants', actions: 'read'})
        break
      case 'can_export_participants':
        rules.push({subject: 'Participants', actions: 'export'})
        break
      case 'can_delete_participants':
        rules.push({subject: 'Participants', actions: 'delete'})
        break
      case 'can_list_organizations':
        rules.push({subject: 'Organizations', actions: 'read'})
        break
      case 'can_create_organizations':
        rules.push({subject: 'Organizations', actions: 'create'})
        break
      case 'can_get_organizations':
        rules.push({subject: 'Organization', actions: 'read'})
        break
      case 'can_update_organizations':
        rules.push({subject: 'Organizations', actions: 'update'})
        break
      case 'can_delete_organizations':
        rules.push({subject: 'Organizations', actions: 'delete'})
        break
      case 'can_list_topics':
        rules.push({subject: 'Topics', actions: 'read'})
        break
      case 'can_create_topics':
        rules.push({subject: 'Topics', actions: 'create'})
        break
      case 'can_get_topics':
        rules.push({subject: 'Topic', actions: 'read'})
        break
      case 'can_update_topics':
        rules.push({subject: 'Topics', actions: 'update'})
        break
      case 'can_delete_topics':
        rules.push({subject: 'Topics', actions: 'delete'})
        break
      case 'can_list_users':
        rules.push({subject: 'Users', actions: 'read'})
        break
      case 'can_create_users':
        rules.push({subject: 'Users', actions: 'create'})
        break
      case 'can_get_users':
        rules.push({subject: 'User', actions: 'read'})
        break
      case 'can_update_users':
        rules.push({subject: 'Users', actions: 'update'})
        break
      case 'can_delete_users':
        rules.push({subject: 'Users', actions: 'delete'})
        break
      case 'can_reset_password_users':
        rules.push({subject: 'Users', actions: 'resetPassword'})
        break
      case 'can_list_proposals':
        rules.push({subject: 'Proposals', actions: 'read'})
        break
      case 'can_create_proposals':
        rules.push({subject: 'Proposals', actions: 'create'})
        break
      case 'can_get_proposals':
        rules.push({subject: 'Proposal', actions: 'read'})
        break
      case 'can_get_proposals_for_partner':
        rules.push({subject: 'Proposals', actions: 'partnerOpen'})
        break
      case 'can_counter_proposals':
        rules.push({subject: 'Proposals', actions: 'counter'})
        break
      case 'can_confirm_proposals':
        rules.push({subject: 'Proposals', actions: 'confirm'})
        break
      case 'can_deny_proposals':
        rules.push({subject: 'Proposals', actions: 'deny'})
        break
      case 'can_manage_events_podcast':
        rules.push({subject: 'Podcast', actions: 'manage'})
        break
      case 'can_manage_events_all':
        rules.push({subject: 'AllEvents', actions: 'manage'})
        break
      case 'can_manage_event_internal_information':
        rules.push({subject: 'Internal', actions: 'manage'})
        break
      case 'can_get_statistics': {
        rules.push({subject: 'Statistic', actions: 'read'})
        break
      }
      default:
        break
    }
  })
  return rules
}
export default mapPermissionsToAbilities
