import httpClient from '_helper/global/httpClient'
import {
  FETCH_ORGANIZATION_USER_REQUEST,
  FETCH_ORGANIZATION_USER_SUCCESS,
  FETCH_ORGANIZATION_USERS_REQUEST,
  FETCH_ORGANIZATION_USERS_SUCCESS,
} from '_redux/_action/projectSpecific/types'
import {fireSuccessToast, globalApiErrorHandler} from '_helper/global/functions'
import endpoints from '_config/endpoints'
import {UPDATE_FILTERS_STATE} from '_redux/_action/global/types'
import {Trans} from '@lingui/macro'
import React from 'react'

export function getOrganizationUsers(
  id,
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc'
) {
  return (dispatch) => {
    const listOrganizationUsersFilters = {
      listOrganizationUsersFilters: {
        orderBy,
        orderDirection,
      },
    }
    dispatch({type: UPDATE_FILTERS_STATE, data: listOrganizationUsersFilters})
    dispatch({type: FETCH_ORGANIZATION_USERS_REQUEST})
    return httpClient
      .get(`${endpoints.organizations}${id}/users`, {
        limit,
        offset,
        orderBy: orderBy ? orderBy : null,
        orderDirection: orderBy ? orderDirection.toUpperCase() : null,
      })
      .then((response) => {
        dispatch({
          type: FETCH_ORGANIZATION_USERS_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getOrganizationUser(id, userId, forForm = false) {
  return (dispatch) => {
    dispatch({type: FETCH_ORGANIZATION_USER_REQUEST})
    return httpClient
      .get(`${endpoints.organizations}${id}/users/${userId}`)
      .then((response) => {
        if (forForm) {
          response.data.roles = response.data.roles.map((role) => {
            return role.id
          })
        }

        dispatch({
          type: FETCH_ORGANIZATION_USER_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function createOrganizationUser(organizationId, formParams) {
  return function () {
    return httpClient
      .post(`${endpoints.organizations}${organizationId}/users`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function editOrganizationUser(organizationId, userId, formParams) {
  return function () {
    return httpClient
      .put(`${endpoints.organizations}${organizationId}/users/${userId}`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function deleteOrganizationUser(organizationId, userId) {
  return function () {
    return httpClient
      .del(`${endpoints.organizations}${organizationId}/users/${userId}`)
      .catch(globalApiErrorHandler)
  }
}

export function sendWelcomeEmail(user) {
  return function () {
    return httpClient
      .post(`${endpoints.users}${user.authId}/welcome`)
      .then(() => fireSuccessToast(<Trans>Welcome e-mail sent to {user.email}.</Trans>))
      .catch(globalApiErrorHandler)
  }
}
