import React from 'react'
import {Trans} from '@lingui/macro'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import {withStyles} from '@material-ui/core'

const UploadField = (props) => {
  const {imageSrc, canRemoveFile, onImageChange, removeFile, classes, hasLogo} = props

  return (
    <div className={classes.logoContainer}>
      <div className={classes.logoResponsive}>
        <img className={classes.logo} src={imageSrc} alt="logo" />
      </div>
      <div className={classes.uploadWrapper}>
        <label>
          <Trans>Upload</Trans>
          <input
            className={classes.fileInput}
            onChange={onImageChange}
            accept="image/png, image/jpeg, image/gif"
            type="file"
          />
        </label>
        {(hasLogo || canRemoveFile) && (
          <label onClick={removeFile}>
            <Trans>Remove</Trans>
          </label>
        )}
      </div>
    </div>
  )
}

export default withStyles(globalFormFieldStyles)(UploadField)
