import {
  FETCH_TOPICS_CALENDAR_REQUEST,
  FETCH_TOPICS_CALENDAR_SUCCESS,
  FETCH_SIMPLE_LIST_TOPICS_REQUEST,
  FETCH_SIMPLE_LIST_TOPICS_SUCCESS,
  FETCH_TOPIC_REQUEST,
  FETCH_TOPIC_SUCCESS,
} from '_redux/_action/projectSpecific/types'

const initTopicsCalendarState = {
  data: {},
  isLoading: true,
}

const initSimpleListTopicsState = {
  data: [],
  isLoading: true,
}

const initTopicState = {
  data: {},
  isLoading: true,
}

export const TopicsCalendarReducer = (state = initTopicsCalendarState, action = null) => {
  switch (action.type) {
    case FETCH_TOPICS_CALENDAR_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case FETCH_TOPICS_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      }
    default:
      return state
  }
}

export const SimpleListTopicsReducer = (state = initSimpleListTopicsState, action = null) => {
  switch (action.type) {
    case FETCH_SIMPLE_LIST_TOPICS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case FETCH_SIMPLE_LIST_TOPICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data.objects,
      }
    default:
      return state
  }
}

export const TopicReducer = (state = initTopicState, action = null) => {
  switch (action.type) {
    case FETCH_TOPIC_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case FETCH_TOPIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      }
    default:
      return state
  }
}
