import React from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {required, requiredNumber} from '_helper/projectSpecific/validations'
import HorizontalStepFormWrapper from '_component/global/common/stepper/HorrizontalStepFormWrapper'
import {withStyles} from '@material-ui/core/styles'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import TextInput from '_component/global/common/formField/TextInput'
import SelectField from '_component/global/common/formField/SelectField'
import GridItem from '_component/global/common/grid/GridItem'
import GridContainer from '_component/global/common/grid/GridContainer'
import {EVENT_TYPES, PRIVACY_LIST} from '_helper/projectSpecific/constants'
import GlobalStepperButtons from '_component/global/common/button/GlobalStepperButtons'
import PropTypes from 'prop-types'
import CustomCheckbox from '_component/global/common/formField/CustomCheckbox'
import CustomRadioGroup from '_component/global/common/formField/CustomRadioGroup'
import SwitcherField from '_component/global/common/formField/SwitcherField'
import FormCondition from '_component/global/form/FormCondition'
import WysiwygInput from '_component/global/common/formField/WysiwygInput'
import NumberInput from "_component/global/common/formField/NumberInput";

const EventSettingsForm = (props) => {
  const {onSubmitSettings, goStepBack} = props
  const {initialValues, classes, havePartnerPermission, configuration} = props

  const prepareInitialValues = () => {
    if (initialValues) {
      return {
        ...initialValues,
        platform: initialValues.platform === 'ONLINE',
      }
    }
  }

  return (
    <HorizontalStepFormWrapper>
      <Form onSubmit={onSubmitSettings} initialValues={initialValues && prepareInitialValues()}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem xs={12}>
                  <h3 className={classes.recurrenceHeaders}>
                    <Trans>Event definition</Trans>
                  </h3>
                </GridItem>

                <GridItem xs={12}>
                  <Field
                    name="name"
                    label={<Trans>Event name</Trans>}
                    component={TextInput}
                    validate={required}
                  />
                </GridItem>
                {configuration.zone !== 'PODCAST' && (
                  <>
                    <GridItem xs={12} md={6}>
                      <Field
                        name="type"
                        label={<Trans>Event type</Trans>}
                        component={SelectField}
                        options={EVENT_TYPES}
                        validate={required}
                        disabled={havePartnerPermission}
                      />
                      <FormCondition when="type" is="CUSTOM">
                        <Field
                          name="customType"
                          label={<Trans>Custom event type</Trans>}
                          component={TextInput}
                          disabled={havePartnerPermission}
                        />
                      </FormCondition>
                      <Field
                        name="capacity"
                        label={<Trans>Maximum participants</Trans>}
                        component={NumberInput}
                        min={0}
                        type="number"
                        validate={requiredNumber}
                        disabled={havePartnerPermission}
                      />
                      <Field
                        name="actualParticipantsCount"
                        label={<Trans>Actual participants</Trans>}
                        component={NumberInput}
                        min={0}
                        type="number"
                        disabled={havePartnerPermission}
                      />
                      <Field
                        name="platform"
                        label={<Trans>Platform</Trans>}
                        component={SwitcherField}
                        type="checkbox"
                        valueA="Offline"
                        valueB="Online"
                        disabled={havePartnerPermission}
                      />
                    </GridItem>

                    <GridItem xs={12} md={6}>
                      <Field
                        name="isTopEvent"
                        label={<Trans>TOP event</Trans>}
                        component={CustomCheckbox}
                        type="checkbox"
                        disabled={havePartnerPermission}
                      />
                      <h3 className={classes.recurrenceHeaders}>
                        <Trans>Event privacy</Trans>
                      </h3>
                      <Field
                        name="privacy"
                        component={CustomRadioGroup}
                        options={PRIVACY_LIST}
                        validate={required}
                        direction="row"
                        xs={12}
                        disabled={havePartnerPermission}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <h3 className={classes.recurrenceHeaders}>
                        <Trans>Hide online registration</Trans>
                      </h3>
                      <Field
                        name="hideRegistration"
                        label={<Trans>Hide registration</Trans>}
                        component={SwitcherField}
                        zeroMarginTop={true}
                        type="checkbox"
                        valueA={<Trans>No</Trans>}
                        valueB={<Trans>Yes</Trans>}
                      />
                    </GridItem>
                  </>
                )}
              </GridContainer>

              {configuration.zone !== 'PODCAST' && (
                <>
                  <GridItem xs={12}>
                    <h3 className={classes.recurrenceHeaders}>
                      <Trans>Event description</Trans>
                    </h3>
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="description"
                      label={<Trans>Event description</Trans>}
                      component={WysiwygInput}
                    />
                  </GridItem>
                </>
              )}
              <GridItem xs={12}>
                <GlobalStepperButtons handleBack={() => goStepBack()} />
              </GridItem>
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

EventSettingsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmitSettings: PropTypes.func,
  goStepBack: PropTypes.func,
  havePartnerPermission: PropTypes.bool,
  configuration: PropTypes.object,
}
export default withStyles(stepperFormStyle)(EventSettingsForm)
