import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {Dialog, DialogContent, DialogActions, withMobileDialog, withStyles} from '@material-ui/core'
import cx from 'classnames'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import DialogTitle from '_component/global/common/dialog/DialogTitle'

const DialogWrapper = (props) => {
  const {handleClose, open, classes, fullScreen, maxWidth, title, children, dialogActions, dialogTitleClasses,fullWidth} = props

  return (
    <Dialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      classes={{paperFullScreen: cx({[classes.paperFullScreen]: fullScreen}), paperWidthMd: classes.paperWidthMd}}
    >
      {title && (
        <DialogTitle onClose={handleClose} className={classes.dialogTitle} dialogTitleClasses={dialogTitleClasses}>
          {title}
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  )
}

DialogWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.node,
  dialogActions: PropTypes.node,
  children: PropTypes.node,
}

export default compose(withMobileDialog(), withStyles(globalWrapperStyles))(DialogWrapper)
