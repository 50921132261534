import React from 'react'
import organizationDetailStyle from '_asset/projectSpecific/jss/organization/organizationDetailStyle'
import {withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import Pagination from '_component/global/common/table/Pagination'
import TableWrapper from '_component/global/common/wrapper/TableWrapper'
import EventParticipantRow from '_component/projectSpecific/event/eventParticipant/EventParticipantRow'
import {EVENT_PARTICIPANTS_LIST_COLUMNS} from '_component/projectSpecific/event/eventParticipant/_eventParticipantsTableColumns'

const EventParticipantsTab = (props) => {
  const {order, orderBy, countParticipants, participantPage, participants, rowsPerParticipantPage} =
    props
  const {requestSort, onParticipantChangePage, handleParticipantLogout} = props
  return (
    <TableWrapper
      onRequestSort={requestSort}
      selectable={false}
      columns={EVENT_PARTICIPANTS_LIST_COLUMNS}
      orderDirection={order}
      orderBy={orderBy}
      tablePagination={
        <Pagination
          count={countParticipants}
          page={participantPage}
          onChangePage={onParticipantChangePage}
          rowsPerPage={rowsPerParticipantPage}
        />
      }
    >
      {participants?.map((row) => (
        <EventParticipantRow
          key={row.id}
          row={row}
          onParticipantLogoutClick={handleParticipantLogout(row)}
        />
      ))}
    </TableWrapper>
  )
}

EventParticipantsTab.propTypes = {
  participants: PropTypes.array,
  requestSort: PropTypes.func,
  onParticipantChangePage: PropTypes.func,
  handleParticipantLogout: PropTypes.func,
}

export default withStyles(organizationDetailStyle)(EventParticipantsTab)
