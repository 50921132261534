import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {composeValidators, required, validateMaxValue} from '_helper/projectSpecific/validations'
import HorizontalStepFormWrapper from '_component/global/common/stepper/HorrizontalStepFormWrapper'
import {withStyles} from '@material-ui/core/styles'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import GlobalStepperButtons from '_component/global/common/button/GlobalStepperButtons'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import DatetimeInput from '_component/global/common/formField/DatetimeInput'
import RecurrenceLogicFields from '_component/projectSpecific/event/eventForm/recurrenceLogic/RecurrenceLogicFields'
import {DATABASE_DATE_FORMAT, DATE_TIME_DATABASE_FORMAT} from '_helper/global/constants'
import NumberInput from '_component/global/common/formField/NumberInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import TopicWarningMessage from '_component/projectSpecific/event/TopicWarningMessage'
import {isTimeFree} from '_redux/_action/projectSpecific/eventActions'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import WarningMessage from '_component/projectSpecific/common/WarningMessage'
import moment from 'moment'

const EventDateTimeForm = (props) => {
  const {
    onSubmitDateTime,
    goStepBack,
    initialValues,
    availableDates,
    topic,
    classes,
    havePartnerPermission,
    operation,
    configuration,
    isTimeFree,
  } = props

  const [timeFree, setTimeFree] = useState(true)

  const availableDatesList = Object.keys(availableDates)

  const handleDisableDates = (day) => {
    return !availableDatesList.includes(day.format(DATABASE_DATE_FORMAT))
  }

  const prepareInitValues = (initialValues) => {
    if (initialValues.rruleConfig) {
      let help = {endType: 'never'}
      if (initialValues.rruleConfig.hasOwnProperty('count')) {
        help.endType = 'after'
      } else if (initialValues.rruleConfig.hasOwnProperty('until')) {
        help.endType = 'until'
      }

      if (initialValues.rruleConfig.hasOwnProperty('bymonthday')) {
        help.monthlyRepetition = 'onDay'
      }

      if (initialValues.rruleConfig.hasOwnProperty('bysetpos')) {
        help.monthlyRepetition = 'onThe'
      }

      // minimal length of an event is 30minutes, so if the length is less than 12,
      // it means that the length is in hours and must be converted
      return {
        ...initialValues,
        length: initialValues.length >= 12 ? initialValues.length : initialValues.length * 60,
        rruleConfig: {
          ...initialValues.rruleConfig,
          help: help,
        },
      }
    } else {
      return {
        ...initialValues,
        length: initialValues.length
          ? initialValues.length >= 12
            ? initialValues.length
            : initialValues.length * 60
          : null,
      }
    }
  }

  const handleSubmit = (values) => {
    const isTimeFreeParams = {
      eventId: values.eventId,
      length: values.length / 60,
      preparationLength: values.preparationLength / 60,
      from: values.from && moment(values.from).format(DATE_TIME_DATABASE_FORMAT),
      eventZone: configuration.zone,
      spaceConfiguration: configuration.spaceConfiguration,
    }

    isTimeFree(isTimeFreeParams).then((res) => {
      if (res) {
        onSubmitDateTime(values)
      } else {
        setTimeFree(res)
      }
    })
  }

  return (
    <HorizontalStepFormWrapper>
      <Form onSubmit={handleSubmit} initialValues={prepareInitValues(initialValues)}>
        {(formProps) => {
          let monthlyRepetition = formProps.values?.rruleConfig?.monthlyRepetition
          if (monthlyRepetition) {
            formProps.values.rruleConfig.help = {monthlyRepetition: monthlyRepetition}
          }

          let endType = formProps.values?.rruleConfig?.help?.endType

          if (endType !== 'until') {
            delete formProps.values?.rruleConfig?.until
          }
          if (endType !== 'after') {
            delete formProps.values?.rruleConfig?.count
          }

          const handleChangeFreq = () => {
            formProps.form.change('rruleConfig.bysetpos', null)
            formProps.form.change('rruleConfig.byweekday', null)
            formProps.form.change('rruleConfig.bymonthday', null)
            formProps.form.change('rruleConfig.interval', null)
            formProps.form.change('rruleConfig.help.monthlyRepetition', null)
          }

          const handleChangeType = () => {
            formProps.form.change('rruleConfig.bymonthday', null)
            formProps.form.change('rruleConfig.bysetpos', null)
            formProps.form.change('rruleConfig.byweekday', null)
          }

          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer className={classes.resetPadding}>
                {topic && <TopicWarningMessage topic={topic} />}
                <GridItem xs={12} sm={6}>
                  <Field
                    name="from"
                    label={<Trans>Date and time of event</Trans>}
                    component={DatetimeInput}
                    shouldDisableDate={availableDates && handleDisableDates}
                    minDate={topic && topic.from}
                    maxDate={topic && topic.to}
                    validate={required}
                    disabled={havePartnerPermission}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="length"
                    label={<Trans>Length of event</Trans>}
                    component={NumberInput}
                    type="number"
                    max={720}
                    min={30}
                    step={1}
                    validate={composeValidators(required, validateMaxValue(720))}
                    disabled={havePartnerPermission}
                    endAdornment={
                      <InputAdornment position="end">
                        <Trans>Min.</Trans>
                      </InputAdornment>
                    }
                  />
                </GridItem>
                <RecurrenceLogicFields
                  havePartnerPermission={havePartnerPermission}
                  operation={operation}
                  handleChangeFreq={handleChangeFreq}
                  handleChangeType={handleChangeType}
                />
                {!timeFree && (
                  <WarningMessage
                    warningMessage={
                      <Trans>The time of this event overlaps with another event!</Trans>
                    }
                  />
                )}
                <GridItem xs={12}>
                  <GlobalStepperButtons handleBack={() => goStepBack()} />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

EventDateTimeForm.propTypes = {
  onSubmitDateTime: PropTypes.func,
  goStepBack: PropTypes.func,
  classes: PropTypes.object,
  havePartnerPermission: PropTypes.bool,
  operation: PropTypes.string,
  configuration: PropTypes.object,
  isTimeFree: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      isTimeFree,
    },
    dispatch
  )
}

export default compose(
  withStyles(stepperFormStyle),
  connect(null, mapDispatchToProps)
)(EventDateTimeForm)
