import React from 'react'
import {Trans} from '@lingui/macro'
import DropDownButton from '_component/global/common/button/DropDownButton'
import {Create, DeleteForever, Facebook, FileCopy, PhotoLibrary, Print} from '@material-ui/icons'
import GlobalButton from '_component/global/common/button/GlobalButton'
import QrCodeIcon from '_component/projectSpecific/common/icon/QrCodeIcon'
import PropTypes from 'prop-types'
import {authorizedAbility, Can} from 'App/_provider/authorizedAbility'

const EventDetailButtons = (props) => {
  const {
    shareOnFacebook,
    copyUrl,
    generateQr,
    deleteEvent,
    editEvent,
    openMediaServerDialog,
    openPrintDialog,
    isEventPodcast,
  } = props

  const canEditEvent =
    authorizedAbility.can('manage', 'AllEvents') &&
    (authorizedAbility.can('update', 'Events') || authorizedAbility.can('partnerUpdate', 'Events'))

  const canEditPodcastEvent =
    authorizedAbility.can('manage', 'Podcast') &&
    authorizedAbility.can('update', 'Events') &&
    isEventPodcast

  return (
    <>
      <Can I="share" an="Events">
        <DropDownButton
          backgroundColor="primary"
          label={<Trans>Share</Trans>}
          dropDownItems={[
            {
              label: (
                <>
                  <Facebook />
                  <Trans>Share on Facebook</Trans>
                </>
              ),
              onClick: shareOnFacebook,
            },
            {
              label: (
                <>
                  <FileCopy />
                  <Trans>Copy URL</Trans>
                </>
              ),
              onClick: copyUrl,
            },
            {
              label: (
                <>
                  <QrCodeIcon />
                  <Trans>Generate QR</Trans>
                </>
              ),
              onClick: generateQr,
            },
          ]}
        />
      </Can>

      {(canEditEvent || canEditPodcastEvent) && (
        <>
          <GlobalButton
            onClick={editEvent}
            justIcon={true}
            backgroundColor="success"
            startIcon={<Create />}
          />
          <GlobalButton
            justIcon={true}
            startIcon={<PhotoLibrary />}
            backgroundColor="success"
            onClick={openMediaServerDialog}
          />
        </>
      )}

      <GlobalButton
        justIcon={true}
        startIcon={<Print />}
        backgroundColor="success"
        onClick={openPrintDialog}
      />

      <Can I="delete" an="Events">
        <GlobalButton
          onClick={deleteEvent}
          justIcon={true}
          backgroundColor="warning"
          startIcon={<DeleteForever />}
        />
      </Can>
    </>
  )
}

EventDetailButtons.propTypes = {
  shareOnFacebook: PropTypes.func,
  copyUrl: PropTypes.func,
  generateQr: PropTypes.func,
  deleteEvent: PropTypes.func,
  openMediaServerDialog: PropTypes.func,
  openPrintDialog: PropTypes.func,
}

export default EventDetailButtons
