import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import EventPricingDialog from '_component/projectSpecific/event/eventPrice/EventPricingDialog'
import GridContainer from '_component/global/common/grid/GridContainer'
import DetailGridItem from '_component/global/common/grid/DetailGridItem'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import {Trans} from '@lingui/macro'
import DetailTableWrapper from '_component/global/common/wrapper/DetailTableWrapper'
import {RECURRING_EVENT_PAYMENT_TYPES} from '_helper/projectSpecific/constants'
import RemoveIcon from '@material-ui/icons/Remove'
import {withStyles} from '@material-ui/core'
import {eventDetailStyle} from '_asset/projectSpecific/jss/event/eventDetailStyle'

const EventPriceTab = (props) => {
  const {event, open, setOpenDialog, classes} = props

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  return (
    <GridContainer>
      <EventPricingDialog event={event} open={open} closeDialog={handleCloseDialog} />
      <DetailGridItem xs={12} sm={6} icon={<AttachMoneyIcon />} title={<Trans>Price items (prices without vat)</Trans>}>
        <DetailTableWrapper
          valueCellAlign={'right'}
          data={[
            {
              field: <Trans>Recurrent event</Trans>,
              value:
                event?.recurringEventPayment &&
                RECURRING_EVENT_PAYMENT_TYPES.find(
                  (type) => type.value === event.recurringEventPayment
                )?.label,
            },
            {
              field: <Trans>Space rent</Trans>,
              value: event?.spaceRent ? `${Math.round(event.spaceRent / 100)} Kč` : '',
            },
            {
              field: <Trans>Equipment rent</Trans>,
              value: event?.equipmentRent ? `${Math.round(event.equipmentRent / 100)} Kč` : '',
            },
            {
              field: <Trans>Video production</Trans>,
              value: event?.videoProduction ? `${Math.round(event.videoProduction / 100)} Kč` : '',
            },
            {
              field: <Trans>Other payments</Trans>,
              value: event?.otherPayments ? `${Math.round(event.otherPayments / 100)} Kč` : '',
            },
            {
              field: <Trans>Marketing counter</Trans>,
              value: event?.marketingCounter
                ? `${Math.round(event.marketingCounter / 100)} Kč`
                : '',
            },
            {
              field: <Trans>Total price</Trans>,
              value: event?.total ? `${Math.round(event.total / 100)} Kč` : '',
            },
          ]}
        />
      </DetailGridItem>
      <DetailGridItem
        xs={12}
        sm={6}
        icon={<RemoveIcon />}
        title={<Trans>Marketing counter description</Trans>}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: event?.marketingCounterDescription && event?.marketingCounterDescription,
          }}
        />
      </DetailGridItem>
    </GridContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withStyles(eventDetailStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(EventPriceTab)
