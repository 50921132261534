import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import Cell from '_component/global/common/table/Cell'
import TableActionsButton from '_component/global/common/button/TableActionsButton'
import {Tooltip, TableRow} from '@material-ui/core'
import {DeleteForever, Edit, Visibility} from '@material-ui/icons'
import moment from 'moment'
import {DATE_TIME_FORMAT} from '_helper/global/constants'
import {Can} from 'App/_provider/authorizedAbility'

const OrganizationRow = (props) => {
  const {row, onDetailClick, onEditClick, onDeleteClick} = props
  return (
    <TableRow key={row.id}>
      <Cell align="left">{row.name}</Cell>
      <Cell>{row?.admin?.name}</Cell>
      <Cell>{row?.admin?.email}</Cell>
      <Cell>{row?.admin?.lastLogin && moment(row.admin.lastLogin).format(DATE_TIME_FORMAT)}</Cell>
      <Cell isActions={true}>
        <Can I="read" an="Organization">
          <TableActionsButton onClick={onDetailClick} backgroundColor="secondary">
            <Tooltip title={<Trans>Detail</Trans>}>
              <Visibility />
            </Tooltip>
          </TableActionsButton>
        </Can>
        <Can I="update" an="Organizations">
          <TableActionsButton onClick={onEditClick} backgroundColor="success">
            <Tooltip title={<Trans>Edit</Trans>}>
              <Edit />
            </Tooltip>
          </TableActionsButton>
        </Can>

        <Can I="delete" an="Organizations">
          <TableActionsButton onClick={onDeleteClick} backgroundColor="warning">
            <Tooltip title={<Trans>Delete</Trans>}>
              <DeleteForever />
            </Tooltip>
          </TableActionsButton>
        </Can>
      </Cell>
    </TableRow>
  )
}

OrganizationRow.propTypes = {
  row: PropTypes.object,
  onDetailClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default OrganizationRow
