import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import globalCardStyle from '_asset/global/jss/component/common/card/globalCardStyle'

const CardIcon = (props) => {
  const {classes, className, children, color, ...rest} = props

  const cardIconClasses = cx({
    [classes.cardIcon]: true,
    [classes[color + 'CardHeader']]: color,
    [className]: className !== undefined,
  })

  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  )
}

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary']),
}

export default withStyles(globalCardStyle)(CardIcon)
