import React from 'react'
import PropTypes from 'prop-types'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import Cell from '_component/global/common/table/Cell'

const TableHeadSelected = (props) => {
  const {onRequestSort, onSelectAllClick, order, numSelected, rowCount, orderBy, columns, align} =
    props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <Cell isSortable={true}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </Cell>
        {columns?.map((column) => (
          <Cell
            align={align ? align : 'right'}
            key={column.id}
            sortDirection={order}
            isRowAction={column.id === 'actions'}
          >
            {column.sortable && (
              <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                <TableSortLabel
                  active={column.order_key ? orderBy === column.order_key : orderBy === column.id}
                  direction={order}
                  onClick={createSortHandler(column.order_key ? column.order_key : column.id)}
                >
                  <strong>{column.label}</strong>
                </TableSortLabel>
              </Tooltip>
            )}
            {!column.sortable && column.label}
          </Cell>
        ))}
      </TableRow>
    </TableHead>
  )
}

TableHeadSelected.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  align: PropTypes.string,
}

export default TableHeadSelected
