import React from 'react'
import {Field} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {composeValidators, required, validateMinValue} from '_helper/projectSpecific/validations'
import GridItem from '_component/global/common/grid/GridItem'
import PropTypes from 'prop-types'
import NumberInput from "_component/global/common/formField/NumberInput";

const DailyRecurrenceFields = ({havePartnerPermission}) => {
  return (
    <GridItem xs={12} sm={6}>
      <Field
        name={`rruleConfig.interval`}
        label={<Trans>Every X day(s)</Trans>}
        component={NumberInput}
        type="number"
        min={1}
        validate={composeValidators(required, validateMinValue(1))}
        disabled={havePartnerPermission}
      />
    </GridItem>
  )
}

DailyRecurrenceFields.propTypes = {
  havePartnerPermission: PropTypes.bool,
}

export default DailyRecurrenceFields
