import React from 'react'
import PropTypes from 'prop-types'
import {AddCircle} from '@material-ui/icons'
import {Trans} from '@lingui/macro'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import {bindActionCreators, compose} from 'redux'
import {fireSuccessToast, redirect} from '_helper/global/functions'
import {createEvent} from '_redux/_action/projectSpecific/eventActions'
import {routesMap} from '_config/routesMap'
import EventStepper from '_component/projectSpecific/event/eventForm/EventStepper'
import {connect} from 'react-redux'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'

const EventCreatePage = (props) => {
  const {createEvent, updateLocalLoader} = props

  const handleCreateEvent = (values) => {
    updateLocalLoader(true)
    createEvent(values)
      .then(() => {
        fireSuccessToast(<Trans>Event sent.</Trans>)
        redirect(routesMap.tenant.admin.calendar)
      })
      .finally(() => {
        updateLocalLoader(false)
      })
  }

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper
        pageTitle={<Trans>Create new event</Trans>}
        iconPageHeader={<AddCircle />}
      >
        <EventStepper
          handleSubmitEvent={handleCreateEvent}
          handleBack={() => redirect(routesMap.tenant.admin.calendar)}
        />
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createEvent,
      updateLocalLoader,
    },
    dispatch
  )
}

EventCreatePage.propTypes = {
  createEvent: PropTypes.func,
}

export default compose(connect(null, mapDispatchToProps))(EventCreatePage)
