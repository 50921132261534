import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import TableWrapper from '_component/global/common/wrapper/TableWrapper'
import {PROPOSALS_LIST_COLUMNS} from '_component/projectSpecific/proposal/proposalList/_proposalTableColumns'
import Pagination from '_component/global/common/table/Pagination'
import {routesMap} from '_config/routesMap'
import {bindActionCreators, compose} from 'redux'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import {getListProposals} from '_redux/_action/projectSpecific/proposalActions'
import {connect} from 'react-redux'
import ProposalDateTimeModal from '_component/projectSpecific/proposal/proposalList/ProposalDateTimeModal'
import ProposalRow from '_component/projectSpecific/proposal/proposalList/ProposalRow'
import ProposalSpaceConfigurationModal from '_component/projectSpecific/proposal/proposalList/ProposalSpaceConfigurationModal'
import {redirect} from '_helper/global/functions'

const ProposalsListTab = (props) => {
  const {globalSettings, proposalsLoaded, proposalsFilter, proposals, proposalsMeta} = props
  const {updateLocalLoader, setProposalsLoaded, getListProposals} = props

  const [proposalActionsModalData, setProposalActionsModalData] = useState(null)
  const [spaceConfigurationModalData, setSpaceConfigurationModalData] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)

  const [sort, setSort] = useState({
    orderBy: proposalsFilter.orderBy,
    orderDirection: proposalsFilter.orderDirection,
  })

  const fetchListProposal = useCallback(() => {
    updateLocalLoader(true)
    return getListProposals(
      pageNumber * globalSettings.rowsPerPage,
      globalSettings.rowsPerPage,
      sort.orderBy,
      sort.orderDirection
    )
      .then(() => {
        setProposalsLoaded(true)
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }, [updateLocalLoader, getListProposals, sort, globalSettings, pageNumber, setProposalsLoaded])

  useEffect(() => {
    if (!proposalsLoaded) {
      fetchListProposal()
    }
  }, [fetchListProposal, proposalsLoaded])

  useEffect(() => {
    if (proposalsLoaded) {
      fetchListProposal()
    }
  }, [sort, pageNumber, fetchListProposal, proposalsLoaded])

  const handleOpenProposalActionsModal = (proposalActions) => () => {
    setProposalActionsModalData(proposalActions)
  }

  const handleCloseProposalActionsModal = () => {
    setProposalActionsModalData(null)
  }

  const handleOpenSpaceConfigurationModal = (spaceConfiguration) => () => {
    setSpaceConfigurationModalData(spaceConfiguration)
  }

  const handleCloseSpaceConfigurationModal = () => {
    setSpaceConfigurationModalData(null)
  }

  const goToProposalDetail = (id) => () => {
    return redirect(`${routesMap.tenant.admin.proposal}/${id}`)
  }

  return (
    <>
      <ProposalDateTimeModal
        data={proposalActionsModalData}
        closeModal={handleCloseProposalActionsModal}
      />
      <ProposalSpaceConfigurationModal
        data={spaceConfigurationModalData}
        closeModal={handleCloseSpaceConfigurationModal}
      />
      <TableWrapper
        onRequestSort={setSort}
        selectable={false}
        columns={PROPOSALS_LIST_COLUMNS}
        orderDirection={proposalsFilter.orderDirection}
        orderBy={proposalsFilter.orderBy}
        tablePagination={
          <Pagination
            count={proposalsMeta.totalCount}
            page={pageNumber}
            onChangePage={setPageNumber}
            rowsPerPage={globalSettings.rowsPerPage}
          />
        }
      >
        {proposals.map((row) => (
          <ProposalRow
            key={row.id}
            row={row}
            onDetailClick={goToProposalDetail}
            openProposalActionsModal={handleOpenProposalActionsModal}
            openSpaceConfiguration={handleOpenSpaceConfigurationModal}
          />
        ))}
      </TableWrapper>
    </>
  )
}

ProposalsListTab.propTypes = {
  onSubmitAvailability: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocalLoader,
      getListProposals,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      proposalsFilter: store.AppFilterStates.listProposalsFilters,
      globalSettings: store.GlobalSettings,
      proposals: store.Proposals.data,
      proposalsMeta: store.Proposals.meta,
    }
  }, mapDispatchToProps)
)(ProposalsListTab)
