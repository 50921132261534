import {primaryColor} from '_asset/global/jss/styleHelpers'

export const horizontalStepperStyle = (theme) => ({
  horizontalStepForm: {
    padding: '30px 0px',
  },
  stepper: {
    '& .MuiStepIcon-root.MuiStepIcon': {
      '&-active,&-completed': {
        color: primaryColor[0],
      },
    },
    '&.MuiStepper-root': {
      padding: '24px 0px',
    },
  },
  resetPadding: {
    padding: '0px !important',
  },
  stepLabel: {
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      display: 'block',
      color: primaryColor[0],
      fontWeight: 500,
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiStepLabel-label': {
        display: 'none',
      },
    },
  },
})
