export const FORCE_IS_TENANT = true
export const FORCE_NO_AUTH = false
export const MOCK_API = false

export const MOCK_PORT_MAPPING = {
  auth: 8000,
  notifications: 8001,
  organizations: 8002,
  proposals: 8003,
  topics: 8004,
  'url-shortener': 8005,
  events: 8006,
}
