import HttpClient from '_helper/global/httpClient'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import mapPermissionsToAbilities from '_config/mapPermissionsToAbilities'
import endpoints from '_config/endpoints'
import {fireSuccessToast, globalApiErrorHandler, redirect} from '_helper/global/functions'
import React from 'react'
import {Trans} from '@lingui/macro'
import {routesMap} from '_config/routesMap'
import {
  FETCH_MY_ACCOUNT_REQUEST,
  FETCH_MY_ACCOUNT_SUCCESS,
} from '_redux/_action/projectSpecific/types'

export const login = (data) => {
  return () => {
    return HttpClient.post(endpoints.login, data, {
      headers: {'organization-id': 1}, // required for login
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('sessionId', response.headers['session-id'])
          localStorage.setItem('loggedUser', JSON.stringify({...response.data}))
          authorizedAbility.update(mapPermissionsToAbilities(response.data.user.permissions))
          fireSuccessToast(<Trans>Successfully logged in.</Trans>)
          redirect(routesMap.tenant.admin.calendar)
        }
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const logout = () => {
  return () => {
    HttpClient.del(endpoints.logout).then((response) => {
      if (response.status === 204) {
        localStorage.removeItem('sessionId')
        localStorage.removeItem('loggedUser')
        redirect(routesMap.common.auth.login)
      }
      return response
    })
  }
}

export const forgottenPasswordRequest = (data, redirectToLogin = true) => {
  return () => {
    return HttpClient.post(endpoints.forgottenPassword, data)
      .then((response) => {
        redirectToLogin && redirect(routesMap.common.auth.login)
        fireSuccessToast(<Trans>E-mail was sent.</Trans>)
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const resetPassword = (token, data) => {
  return () => {
    return HttpClient.post(`${endpoints.resetPassword}/${token}`, data)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('sessionId', response.headers['session-id'])
          localStorage.setItem('loggedUser', JSON.stringify({...response.data}))
          authorizedAbility.update(mapPermissionsToAbilities(response.data.user.permissions))
          fireSuccessToast(<Trans>Password was reset.</Trans>)
          redirect(routesMap.tenant.admin.calendar)
        }
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const changePassword = (data) => {
  return () => {
    return HttpClient.put(endpoints.changePassword, data)
      .then((response) => {
        fireSuccessToast(<Trans>Password was changed.</Trans>)
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const getMyAccount = () => {
  return function (dispatch) {
    dispatch({type: FETCH_MY_ACCOUNT_REQUEST})
    return HttpClient.get(endpoints.myAccount)
      .then((response) => {
        dispatch({
          type: FETCH_MY_ACCOUNT_SUCCESS,
          data: response.data,
        })
        const loggedUser = JSON.parse(localStorage.getItem('loggedUser'))
        loggedUser.user.name = response.data?.name
        loggedUser.user.email = response.data?.email
        localStorage.setItem('loggedUser', JSON.stringify(loggedUser))
      })
      .catch(globalApiErrorHandler)
  }
}

export const changeMyAccount = (data) => {
  return () => {
    return HttpClient.put(endpoints.myAccount, data)
      .then((response) => {
        fireSuccessToast(<Trans>User account updated.</Trans>)
        return response
      })
      .catch(globalApiErrorHandler)
  }
}
