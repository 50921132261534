import {Trans} from '@lingui/macro'
import React from 'react'

export const ORGANIZATION_CONTACTS_LIST_COLUMNS = [
  {
    id: 'name',
    sortable: true,
    order_key: 'name',
    label: <Trans>User name</Trans>,
  },
  {
    id: 'email',
    sortable: true,
    order_key: 'email',
    label: <Trans>E-mail</Trans>,
  },
  {
    id: 'phone',
    sortable: true,
    order_key: 'phone',
    label: <Trans>Phone</Trans>,
  },
  {
    id: 'description',
    sortable: true,
    order_key: 'description',
    label: <Trans>Contact description</Trans>,
  },
  {
    id: 'user',
    sortable: false,
    label: <Trans>Has user</Trans>,
  },
  {
    id: 'actions',
    sortable: false,
    order_key: 'actions',
    label: '',
  },
]
