import React from 'react'
import {Field, Form} from 'react-final-form'
import {withStyles} from '@material-ui/core'
import calendarStyle from '_asset/global/jss/component/calendar/calendarStyle'
import {Trans} from '@lingui/macro'
import SelectField from '_component/global/common/formField/SelectField'
import {getSelectOptions} from '_helper/global/functions'
import {EVENT_SPACE_CONFIGURATIONS, EVENT_ZONES} from '_helper/projectSpecific/constants'
import {authorizedAbility} from 'App/_provider/authorizedAbility'

const ReservationCalendarFilters = (props) => {
  const {classes, eventsProposalsFilter, setEventsProposalsFilter} = props

  const setFilters = (name, value) => {
    setEventsProposalsFilter((prevState) => ({...prevState, [name]: value}))
  }

  // const getEventZonesOptions = () => {
  //   if (authorizedAbility.can('manage', 'AllEvents')) {
  //     return EVENT_ZONES
  //   } else if (authorizedAbility.can('manage', 'Podcast')) {
  //     return [{value: 'PODCAST', label: <Trans>Podcast</Trans>}]
  //   }
  // }

  return (
    <Form onSubmit={setEventsProposalsFilter} initialValues={eventsProposalsFilter}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.onSubmit} className={classes.eventFilters}>
            <Field
              name="spaceConfiguration"
              label={<Trans>Space</Trans>}
              component={SelectField}
              options={getSelectOptions(EVENT_SPACE_CONFIGURATIONS, 'id', 'configuration')}
              emptyOption={true}
              emptyOptionLabel={<Trans>reset</Trans>}
              customOnChange={setFilters}
            />
            {/*<Field*/}
            {/*  name="eventZone"*/}
            {/*  label={<Trans>Zone</Trans>}*/}
            {/*  component={SelectField}*/}
            {/*  options={getEventZonesOptions()}*/}
            {/*  emptyOption={true}*/}
            {/*  emptyOptionLabel={<Trans>reset</Trans>}*/}
            {/*  customOnChange={setFilters}*/}
            {/*/>*/}
          </form>
        )
      }}
    </Form>
  )
}

export default withStyles(calendarStyle)(ReservationCalendarFilters)
