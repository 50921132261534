import React from 'react'
import {Field, Form} from 'react-final-form'
import {Box} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {Delete} from '@material-ui/icons'
import DateInput from '_component/global/common/formField/DateInput'
// import SelectField from '_component/global/common/formField/SelectField'
import {EVENT_METHODS} from '_helper/projectSpecific/constants'
import CustomRadioGroup from '_component/global/common/formField/CustomRadioGroup'
import {required} from '_helper/projectSpecific/validations'

const EventDeleteForm = (props) => {
  const {onSubmit, date} = props

  return (
    <Form onSubmit={onSubmit} initialValues={{method: 'SINGLE_OCCURENCE', date: date}}>
      {(formProps) => {
        const selectedAllOccurrenceMethod = formProps.values.method === 'ALL_OCCURENCES'
        return (
          <form onSubmit={formProps.handleSubmit}>
            {/*<Field name="method" label={<Trans>Method</Trans>} component={SelectField} options={EVENT_METHODS} />*/}
            <Field
              name="method"
              component={CustomRadioGroup}
              options={EVENT_METHODS}
              validate={required}
              direction="row"
              xs={12}
            />
            <Field
              name="date"
              label={<Trans>Date</Trans>}
              component={DateInput}
              disabled={selectedAllOccurrenceMethod}
            />
            <Box pt={1}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <GlobalButton
                  backgroundColor="primary"
                  type="submit"
                  loading={props.submitting}
                  endIcon={<Delete />}
                >
                  <Trans>Delete</Trans>
                </GlobalButton>
              </div>
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

EventDeleteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default EventDeleteForm
