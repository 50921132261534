import React from 'react'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import {withStyles} from '@material-ui/core'
import cx from 'classnames'
import PropTypes from 'prop-types'

const SpaceConfigurationItem = (props) => {
  const {selectConfigurations, selected, classes, itemId, children} = props
  return (
    <div
      className={cx(classes.spaceConfigurationContent, {
        [classes.spaceConfigurationItemClickable]: selectConfigurations,
        [classes.spaceConfigurationItemSelected]: selected && selected === itemId,
        [classes.spaceConfigurationItemNonSelected]: selected && selected !== itemId,
      })}
      onClick={selectConfigurations && selectConfigurations(itemId)}
    >
      {children}
    </div>
  )
}

SpaceConfigurationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  selectConfigurations: PropTypes.func,
  selected: PropTypes.string,
  children: PropTypes.node,
}

export default withStyles(stepperFormStyle)(SpaceConfigurationItem)
