import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import ProposalRejectForm from '_component/projectSpecific/proposal/proposalForm/ProposalRejectForm'

const ProposalRejectDialog = (props) => {
  const {openRejectDialog, closeRejectDialog, handleReject, ...rest} = props
  return (
    <DialogWrapper
      open={openRejectDialog}
      handleClose={closeRejectDialog}
      title={<Trans>Reject event proposal</Trans>}
      maxWidth="md"
    >
      <ProposalRejectForm onSubmitReject={handleReject} {...rest} />
    </DialogWrapper>
  )
}

ProposalRejectDialog.propTypes = {
  openRejectDialog: PropTypes.bool,
  closeRejectDialog: PropTypes.func,
  handleReject: PropTypes.func,
}

export default ProposalRejectDialog
