import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'

const TableToolbarActionsSimpleWrapper = (props) => {
  const {classes, children} = props

  return <div className={classes.flex}>{children}</div>
}

TableToolbarActionsSimpleWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.node,
}

export default withStyles(globalWrapperStyles)(TableToolbarActionsSimpleWrapper)
