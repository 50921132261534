import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import globalCardStyle from '_asset/global/jss/component/common/card/globalCardStyle'

const CardBody = (props) => {
  const {classes, className, children, profile, ...rest} = props

  const cardBodyClasses = cx({
    [classes.cardBody]: true,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined,
  })

  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  )
}

CardBody.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  profile: PropTypes.bool,
  children: PropTypes.node,
}

export default withStyles(globalCardStyle)(CardBody)
