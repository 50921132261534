import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import {withStyles} from '@material-ui/core/styles'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import ConfigurationMap from '_asset/projectSpecific/img/planek_big.png'

const ConfigurationDialog = (props) => {
  const {openDialog, closeDialog, classes} = props

  return (
    <DialogWrapper
      open={openDialog}
      maxWidth="md"
      handleClose={closeDialog}
      title={<Trans>Configuration map</Trans>}
    >
      <div className={classes.configurationMap}>
        <img src={ConfigurationMap} alt="configuration map" />
      </div>
    </DialogWrapper>
  )
}

ConfigurationDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(globalWrapperStyles)(ConfigurationDialog)
