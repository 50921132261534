import React, {useState} from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import {withStyles} from '@material-ui/core/styles'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import GlobalButton from '_component/global/common/button/GlobalButton'
import MediaServerUpload from '_component/projectSpecific/event/eventDetail/MediaServerUpload'
import {bindActionCreators, compose} from 'redux'
import {editEvent, getEvent} from '_redux/_action/projectSpecific/eventActions'
import {updateLocalLoader} from '_redux/_action/global/loaderActions'
import {connect} from 'react-redux'
import {fireSuccessToast} from '_helper/global/functions'
import moment from 'moment'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'

const MediaServerVisualsDialog = (props) => {
  const {
    openDialog,
    closeDialog,
    classes,
    event,
    editEvent,
    getEvent,
    updateLocalLoader,
    localLoading,
  } = props

  const [horizontalPicture, setHorizontalPicture] = useState(null)
  const [horizontalPictureChanged, setHorizontalPictureChanged] = useState(false)
  const [verticalPicture, setVerticalPicture] = useState(null)
  const [verticalPictureChanged, setVerticalPictureChanged] = useState(null)
  const [eventPicture, setEventPicture] = useState(null)
  const [eventPictureChanged, setEventPictureChanged] = useState(null)

  const fetchEvent = () => {
    updateLocalLoader(true)
    return getEvent(event.id, moment(event.from).format(DATABASE_DATE_FORMAT))
      .then(() => {
        updateLocalLoader(false)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const handleImageChange = (type) => (e) => {
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      if (type === 'vertical') {
        setVerticalPicture(reader.result)
        setVerticalPictureChanged(true)
      } else if (type === 'horizontal') {
        setHorizontalPicture(reader.result)
        setHorizontalPictureChanged(true)
      } else {
        setEventPicture(reader.result)
        setEventPictureChanged(true)
      }
      document.getElementById(type).value = null
    }
    file && reader.readAsDataURL(file)
  }

  const handleSaveImages = () => {
    updateLocalLoader(true)
    const valuesToSubmit = {
      ...event,
      pictureData: eventPicture,
      pictureUrl:
        eventPicture === null ? (eventPictureChanged === true ? null : event.pictureUrl) : null,
      verticalPictureData: verticalPicture,
      verticalPictureUrl:
        verticalPicture === null
          ? verticalPictureChanged === true
            ? null
            : event.verticalPictureUrl
          : null,
      horizontalPictureData: horizontalPicture,
      horizontalPictureUrl:
        horizontalPicture === null
          ? horizontalPictureChanged === true
            ? null
            : event.horizontalPictureUrl
          : null,
      organizationId: event.organization?.id,
    }
    editEvent(event.id, valuesToSubmit).then(() => {
      updateLocalLoader(false)
      fetchEvent()
      fireSuccessToast(<Trans>Event edited.</Trans>)
      closeDialog()
    })
  }

  const handleRemoveFile = (type) => (e) => {
    if (type === 'vertical') {
      setVerticalPicture(null)
      setVerticalPictureChanged(true)
    } else if (type === 'horizontal') {
      setHorizontalPicture(null)
      setHorizontalPictureChanged(true)
    } else {
      setEventPicture(null)
      setEventPictureChanged(true)
    }
  }

  const getEventPictureSrc = (dataPic, urlPic, changed) => {
    if (changed) {
      return dataPic
    } else {
      return urlPic
    }
  }

  return (
    <DialogWrapper
      open={openDialog}
      maxWidth="md"
      handleClose={closeDialog}
      title={<Trans>Event visuals</Trans>}
    >
      <div className={classes.mediaServerDialog}>
        <div className="visual">
          <MediaServerUpload
            title={<Trans>event image (220x220)</Trans>}
            type={'event'}
            onImageChange={handleImageChange('event')}
            imageSrc={getEventPictureSrc(eventPicture, event.pictureUrl, eventPictureChanged)}
            removeFile={handleRemoveFile('event')}
            fileChanged={eventPictureChanged}
          />
        </div>
        <div className="visual">
          <MediaServerUpload
            title={<Trans>main commercial stage (2100x1300)</Trans>}
            type={'vertical'}
            onImageChange={handleImageChange('vertical')}
            imageSrc={getEventPictureSrc(
              verticalPicture,
              event.verticalPictureUrl,
              verticalPictureChanged
            )}
            removeFile={handleRemoveFile('vertical')}
            fileChanged={verticalPictureChanged}
          />
        </div>
        <div className="visual">
          <MediaServerUpload
            title={<Trans>main event stage (1500x1800)</Trans>}
            type={'horizontal'}
            onImageChange={handleImageChange('horizontal')}
            imageSrc={getEventPictureSrc(
              horizontalPicture,
              event.horizontalPictureUrl,
              horizontalPictureChanged
            )}
            removeFile={handleRemoveFile('horizontal')}
            fileChanged={horizontalPictureChanged}
          />
        </div>
      </div>
      <div className={classes.saveClose}>
        <GlobalButton disabled={localLoading} backgroundColor="primary" onClick={handleSaveImages}>
          <Trans>Save</Trans>
        </GlobalButton>
        <GlobalButton backgroundColor="primary" inverted={true} onClick={closeDialog}>
          <Trans>Cancel</Trans>
        </GlobalButton>
      </div>
    </DialogWrapper>
  )
}

MediaServerVisualsDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  name: PropTypes.node,
  classes: PropTypes.object,
  editEvent: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editEvent,
      getEvent,
      updateLocalLoader,
    },
    dispatch
  )
}

export default compose(
  withStyles(globalWrapperStyles),
  connect((store) => {
    return {
      localLoading: store.Loader.local,
    }
  }, mapDispatchToProps)
)(MediaServerVisualsDialog)
