import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {Box} from '@material-ui/core'
import GlobalButton from '_component/global/common/button/GlobalButton'
import CounterProposalActionsWrapper from '_component/projectSpecific/proposal/proposalDetail/CounterProposalActionsWrapper'
import TextInput from '_component/global/common/formField/TextInput'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'
import {required} from '_helper/projectSpecific/validations'

const CounterProposalForm = (props) => {
  const {topic, availableDates, timeFreeCounterProposals} = props
  const {onSubmitCounterProposal} = props

  const [fields, setFields] = useState([1])

  const availableDatesList = Object.keys(availableDates)
  const handleDisableDates = (day) => {
    return !availableDatesList.includes(day.format(DATABASE_DATE_FORMAT))
  }

  const addCounterProposalSet = () => {
    setFields([...fields, 1])
  }

  const removeCounterProposalSet = (index, formProps) => () => {
    if (fields.length > 0) {
      const newFields = [...fields]
      formProps.values.proposalActions && formProps.values.proposalActions.splice(index, 1)
      newFields.splice(index, 1)
      setFields(newFields)
    }
  }

  return (
    <Form onSubmit={onSubmitCounterProposal}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                {fields.map((field, index) => {
                  return (
                    <CounterProposalActionsWrapper
                      timeFreeCounterProposals={
                        timeFreeCounterProposals && timeFreeCounterProposals[index]
                      }
                      key={index}
                      fieldsCount={fields.length}
                      index={index}
                      topic={topic}
                      handleDisableDates={handleDisableDates}
                      formProps={formProps}
                      availableDates={availableDates}
                      removeCounterProposalSet={removeCounterProposalSet(index, formProps)}
                    />
                  )
                })}
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="reason"
                  label={<Trans>Counter proposal note</Trans>}
                  component={TextInput}
                  multiline={true}
                  variant="outlined"
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12}>
                <Box display="flex" justifyContent={'space-between'} flexGrow={1}>
                  <GlobalButton onClick={addCounterProposalSet} backgroundColor="primary" inverted>
                    <Trans>Add choice</Trans>
                  </GlobalButton>
                  <GlobalButton type="submit" backgroundColor="primary">
                    <Trans>Send counter proposal</Trans>
                  </GlobalButton>
                </Box>
              </GridItem>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

CounterProposalForm.propTypes = {
  onSubmitCounterProposal: PropTypes.func,
}

export default CounterProposalForm
