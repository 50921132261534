import React, {useState} from 'react'
import {Visibility, VisibilityOff} from '@material-ui/icons'
import {IconButton} from '@material-ui/core'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextInput from '_component/global/common/formField/TextInput'
import withStyles from '@material-ui/core/styles/withStyles'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from 'classnames'

const PasswordInput = (props) => {
  const {classes, input, ...rest} = props

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = (event) => {
    event.preventDefault()
    setShowPassword((showPassword) => !showPassword)
  }

  const AdornmentIcon = showPassword ? Visibility : VisibilityOff
  const inputElementType = showPassword ? 'text' : 'password'

  return (
    <TextInput
      className={cx(classes.root, classes.passwordIcon)}
      {...input}
      type={inputElementType}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword}>
              <AdornmentIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}

PasswordInput.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
}

export default withStyles(globalFormFieldStyles)(PasswordInput)
