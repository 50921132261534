import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'
import {OVERRULE_AUTH} from '_helper/global/constants'
import {routesMap} from '_config/routesMap'
import {redirect} from '_helper/global/functions'

const PrivateRoute = (props) => {
  const {component: Component, ...rest} = props

  useEffect(() => {
    document.getElementById('content-wrapper').scrollTop = 0
  }, [])

  const isAuthorized = localStorage.getItem('loggedUser') || OVERRULE_AUTH

  if (!isAuthorized) {
    redirect(routesMap.common.auth.login)
  }

  return <Route {...rest} component={Component} />
}

export default PrivateRoute
