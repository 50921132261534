import httpClient from '_helper/global/httpClient'
import {
  FETCH_EVENTS_CALENDAR_REQUEST,
  FETCH_LIST_PROPOSALS_REQUEST,
  FETCH_LIST_PROPOSALS_SUCCESS,
  FETCH_PROPOSAL_CONFIRM_DATA_REQUEST,
  FETCH_PROPOSAL_CONFIRM_DATA_SUCCESS,
  FETCH_PROPOSAL_REQUEST,
  FETCH_PROPOSAL_SUCCESS,
  FETCH_PROPOSALS_CALENDAR_REQUEST,
  FETCH_PROPOSALS_CALENDAR_SUCCESS,
} from '_redux/_action/projectSpecific/types'
import endpoints from '_config/endpoints'
import {globalApiErrorHandler} from '_helper/global/functions'
import {UPDATE_FILTERS_STATE} from '_redux/_action/global/types'

export function getListProposals(
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc',
  filter = ''
) {
  return function (dispatch) {
    const listProposalsFilters = {
      listProposalsFilters: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter,
      },
    }
    dispatch({type: UPDATE_FILTERS_STATE, data: listProposalsFilters})
    dispatch({type: FETCH_LIST_PROPOSALS_REQUEST})
    return httpClient
      .get(endpoints.proposals, {
        limit,
        offset,
        orderBy: orderBy || null,
        orderDirection: orderBy ? orderDirection.toUpperCase() : null,
        filter: filter || null,
      })
      .then((response) => {
        dispatch({
          type: FETCH_LIST_PROPOSALS_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getProposal(proposalId) {
  return function (dispatch) {
    dispatch({type: FETCH_PROPOSAL_REQUEST})
    return httpClient
      .get(`${endpoints.proposals}${proposalId}`)
      .then((response) => {
        dispatch({
          type: FETCH_PROPOSAL_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function createProposal(formParams) {
  return function () {
    return httpClient
      .post(endpoints.proposals, formParams)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export function createAdminProposal(orgId, formParams) {
  return function () {
    return httpClient
      .post(`${endpoints.proposals}organization/${orgId}`, formParams)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export function editProposal(proposalId, formParams) {
  return function () {
    return httpClient
      .put(`${endpoints.proposals}${proposalId}/`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function getProposalConfirmData(proposalId, proposalActionId) {
  return function (dispatch) {
    dispatch({type: FETCH_PROPOSAL_CONFIRM_DATA_REQUEST})
    return httpClient
      .get(`${endpoints.proposals}${proposalId}/proposal-actions/${proposalActionId}/confirm-data`)
      .then((response) => {
        dispatch({
          type: FETCH_PROPOSAL_CONFIRM_DATA_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function confirmProposal(proposalId, proposalActionId, formParams) {
  return function () {
    return httpClient
      .post(
        `${endpoints.proposals}${proposalId}/proposal-actions/${proposalActionId}/confirm`,
        formParams
      )
      .catch(globalApiErrorHandler)
  }
}

export function denyProposal(proposalId, formParams) {
  return function () {
    return httpClient
      .post(`${endpoints.proposals}${proposalId}/deny`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function counterProposal(proposalId, formParams) {
  return function () {
    return httpClient
      .post(`${endpoints.proposals}${proposalId}/counter`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function getProposalsCalendar(from, to, filters) {
  return function (dispatch) {
    const proposalFilters = {
      from,
      to,
      ...filters,
    }
    dispatch({type: FETCH_PROPOSALS_CALENDAR_REQUEST})
    return httpClient
      .get(endpoints.proposals + 'calendar', {
        ...proposalFilters,
      })
      .then((response) => {
        dispatch({type: FETCH_PROPOSALS_CALENDAR_SUCCESS, data: response.data})
      })
      .catch(globalApiErrorHandler)
  }
}
