import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {topicsCalendarStyle} from '_asset/projectSpecific/jss/topic/topicsCalendarStyle'
import moment from 'moment'
import {Edit} from '@material-ui/icons'
import {Tooltip} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import {Can} from 'App/_provider/authorizedAbility'

const TopicItem = (props) => {
  const {topic, classes, onClickItem, month} = props

  const dateFrom = topic.dateFrom && moment(topic.dateFrom)
  const dateTo = topic.dateTo && moment(topic.dateTo)

  const from = dateFrom.month() + 1 === month ? `${dateFrom.date()}.` : '~'
  const to = dateTo.month() + 1 === month ? `${dateTo.date()}.` : '~'

  return (
    <div className={classes.topicItemContent}>
      <div className={classes.nonBreakableSpace}>
        <span>{from}</span>
        &nbsp;&ndash;&nbsp;
        <span>{to}</span>
      </div>
      <span className={classes.topicName}>{topic.name && topic.name}</span>
      <Can I="update" a="Topics">
        <Tooltip title={<Trans>Edit topic</Trans>}>
          <Edit onClick={onClickItem(topic)} />
        </Tooltip>
      </Can>
    </div>
  )
}

TopicItem.propTypes = {
  classes: PropTypes.object.isRequired,
  topic: PropTypes.object,
  onClickItem: PropTypes.func,
  month: PropTypes.number,
}

export default withStyles(topicsCalendarStyle)(TopicItem)
