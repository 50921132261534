import {UPDATE_GLOBAL_SETTINGS_SUCCESS} from '_redux/_action/global/types'
import {darkGradient, lightGradient} from '_asset/global/jss/styleHelpers'

const initState = localStorage.getItem('globalSettings')
  ? JSON.parse(localStorage.getItem('globalSettings'))
  : {
      rowsPerPage: 20,
      rowsPerPageOptions: [5, 10, 20, 50],
      currentLanguage: 'cs',
      gradientColors: [darkGradient, lightGradient],
    }

const globalSettingsReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_GLOBAL_SETTINGS_SUCCESS) {
    const settings = {
      ...state,
      ...action.data,
    }
    localStorage.setItem('globalSettings', JSON.stringify(settings))
    return settings
  }
  return state
}
export default globalSettingsReducer
