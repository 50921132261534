import React, {useCallback, useEffect} from 'react'
import PropTypes from 'prop-types'
import {AddCircle} from '@material-ui/icons'
import {Trans} from '@lingui/macro'
import AdminPageWrapper from '_component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from '_component/global/common/wrapper/ContentAdminPageWrapper'
import {bindActionCreators, compose} from 'redux'
import {fireSuccessToast, redirect} from '_helper/global/functions'
import {editEvent, getEvent} from '_redux/_action/projectSpecific/eventActions'
import {routesMap} from '_config/routesMap'
import EventStepper from '_component/projectSpecific/event/eventForm/EventStepper'
import {connect} from 'react-redux'
import {updateGlobalLoader} from '_redux/_action/global/loaderActions'
import moment from 'moment'
import {DATABASE_DATE_FORMAT} from '_helper/global/constants'

const EventEditPage = (props) => {
  const {
    event,
    getEvent,
    isEventLoaded,
    updateGlobalLoader,
    editEvent,
    match: {
      params: {id, date},
    },
  } = props

  const fetchEvent = useCallback(() => {
    updateGlobalLoader(true)
    return getEvent(id, date)
      .then(() => {
        updateGlobalLoader(false)
      })
      .catch(() => {
        updateGlobalLoader(false)
      })
  }, [getEvent, updateGlobalLoader, id])

  useEffect(() => {
    fetchEvent()
  }, [fetchEvent])

  const handleEditEvent = (values, isRecurrent) => {
    editEvent(event.id, values).then(() => {
      fireSuccessToast(<Trans>Event edited.</Trans>)
      if (isRecurrent || moment(values.from).format(DATABASE_DATE_FORMAT) !== date) {
        // if recurrent event is changed, the date of event is changed and therefore
        // the link with date wont work
        redirect(routesMap.tenant.admin.calendar)
      } else {
        redirect(`${routesMap.tenant.admin.calendar}/${id}/date/${date}`)
      }
    })
  }

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper pageTitle={<Trans>Edit event</Trans>} iconPageHeader={<AddCircle />}>
        <EventStepper
          operation="edit"
          handleSubmitEvent={handleEditEvent}
          event={isEventLoaded && event}
          handleBack={() => redirect(`${routesMap.tenant.admin.calendar}/${id}/date/${date}`)}
        />
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editEvent,
      getEvent,
      updateGlobalLoader,
    },
    dispatch
  )
}

EventEditPage.propTypes = {
  editEvent: PropTypes.func,
}

export default compose(
  connect((store) => {
    return {
      event: store.Event.data,
      isEventLoaded: !store.Event.isLoading,
    }
  }, mapDispatchToProps)
)(EventEditPage)
