import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'
import globalGridStyle from '_asset/global/jss/component/common/grid/globalGridStyle'

const GridContainer = (props) => {
  const {classes, children, className, ...rest} = props

  return (
    <Grid container {...rest} className={cx(classes.gridContainer, className)}>
      {children}
    </Grid>
  )
}

export default withStyles(globalGridStyle)(GridContainer)
