import React from 'react'
import PropTypes from 'prop-types'
import {Table, TableBody} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import TableToolbarWrapper from '_component/global/common/wrapper/TableToolbarWrapper'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'
import TableHeadSelected from '_component/global/common/table/TableHeadSelected'
import SimpleTableHead from '_component/global/common/table/SimpleTableHead'

const TableWrapper = (props) => {
  // split props to variables and functions
  const {
    orderDirection,
    orderBy,
    columns,
    numSelected,
    selectable,
    rowCount,
    tableToolbar,
    tablePagination,
    classes,
    children,
  } = props
  const {handleSelectAllClick, onRequestSort} = props

  const renderTableToolbar = () => {
    return (
      tableToolbar && (
        <TableToolbarWrapper selectable={selectable} numSelected={numSelected}>
          {tableToolbar}
        </TableToolbarWrapper>
      )
    )
  }

  return (
    <>
      <div className={classes.tableWrapper}>
        {renderTableToolbar()}
        <Table className={cx({[classes.tableMargin]: selectable})}>
          {selectable ? (
            <TableHeadSelected
              onSelectAllClick={handleSelectAllClick}
              numSelected={numSelected}
              rowCount={rowCount}
              onRequestSort={onRequestSort}
              columns={columns}
            />
          ) : (
            <SimpleTableHead
              orderDirection={orderDirection}
              orderBy={orderBy}
              columns={columns}
              onRequestSort={onRequestSort}
            />
          )}
          <TableBody className={classes.tableBody}>{children}</TableBody>
        </Table>
      </div>
      {tablePagination}
    </>
  )
}

TableWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  orderDirection: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  tableToolbar: PropTypes.node,
  tablePagination: PropTypes.node,
  children: PropTypes.node,
  columns: PropTypes.array,
  align: PropTypes.string,
}

export default withStyles(globalWrapperStyles)(TableWrapper)
