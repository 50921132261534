import React from 'react'
import {Trans} from '@lingui/macro'

export const getOrganizationData = (organization) => {
  return [
    {
      field: <Trans>Administrator name</Trans>,
      value: organization.admin ? organization.admin.name : '',
    },
    {
      field: <Trans>Administrator e-mail</Trans>,
      value: organization.admin ? organization.admin.email : '',
    },
  ]
}
