import Box from '@material-ui/core/Box'
import React from 'react'
import PropTypes from 'prop-types'

const TabPanel = (props) => {
  const {children, value, index} = props
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default TabPanel
