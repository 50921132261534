import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {required} from '_helper/projectSpecific/validations'
import TextInput from '_component/global/common/formField/TextInput'
import DialogCreateFormButtons from '_component/global/common/dialog/DialogCreateFormButton'
import {Box} from '@material-ui/core'
import {useParams} from 'react-router-dom'
import {getOrganizationContact} from '_redux/_action/projectSpecific/organizationContactAction'
import SelectField from '_component/global/common/formField/SelectField'
import {getOrganizationUsers} from '_redux/_action/projectSpecific/organizationUserActions'

const OrganizationContactDialog = (props) => {
  const {
    open,
    close,
    handleSubmit,
    form,
    getOrganizationContact,
    contact,
    organizationContact,
    getOrganizationUsers,
    organizationUsers,
    disabledUser = false,
  } = props

  const {id} = useParams()

  const initValues = () => {
    if (form === 'custom') {
      return {
        ...contact,
        userId: contact.id,
      }
    }

    return {
      ...organizationContact,
    }
  }

  useEffect(() => {
    if (form === 'edit' && contact?.id) {
      getOrganizationContact(id, contact?.id)
    }

    getOrganizationUsers(id, 0, 1000, 'name', 'asc')
  }, [form, contact])

  return (
    <DialogWrapper
      fullWidth={true}
      open={open}
      handleClose={close}
      title={form === 'new' ? <Trans>Create new contact</Trans> : <Trans>Edit contact</Trans>}
      maxWidth="sm"
    >
      <Form onSubmit={handleSubmit} initialValues={form === 'new' ? {} : initValues}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem xs={12}>
                  <Field
                    name="name"
                    label={<Trans>Contact name</Trans>}
                    validate={required}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field name="email" label={<Trans>E-mail</Trans>} component={TextInput} />
                </GridItem>
                <GridItem xs={12}>
                  <Field name="phone" label={<Trans>Phone</Trans>} component={TextInput} />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="description"
                    label={<Trans>Contact description</Trans>}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="userId"
                    label={<Trans>User</Trans>}
                    component={SelectField}
                    emptyOption={true}
                    disabled={disabledUser}
                    emptyOptionLabel={<Trans>None</Trans>}
                    options={organizationUsers.map((user) => ({value: user.id, label: user.name}))}
                  />
                </GridItem>
              </GridContainer>
              <Box pt={1}>
                <DialogCreateFormButtons
                  buttonText={
                    form === 'new' || form === 'custom' ? (
                      <Trans>Create</Trans>
                    ) : (
                      <Trans>Edit</Trans>
                    )
                  }
                />
              </Box>
            </form>
          )
        }}
      </Form>
    </DialogWrapper>
  )
}

OrganizationContactDialog.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.func,
  handleSubmit: PropTypes.func,
  getOrganizationContact: PropTypes.func,
  organizationContact: PropTypes.object,
  getOrganizationUsers: PropTypes.func,
  organizationUsers: PropTypes.array,
  disabledUser: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationContact,
      getOrganizationUsers,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      organizationContact: store.OrganizationContact.data,
      organizationUsers: store.OrganizationUsers.data,
    }
  }, mapDispatchToProps)
)(OrganizationContactDialog)
