import {applyMiddleware, compose, createStore} from 'redux'
import axiosMiddleware from 'redux-axios-middleware'
import thunk from 'redux-thunk'
import {ENVIRONMENT} from '_helper/global/constants'
import httpClient from '_helper/global/httpClient'
import rootReducer from '_redux/_reducer'

const composeEnhancers = ENVIRONMENT !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(axiosMiddleware(httpClient), thunk)))
export default store
