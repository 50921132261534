import React from 'react'
import PropTypes from 'prop-types'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@material-ui/core/Link'
import cx from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import globalLinkStyle from '_asset/global/jss/component/common/button/globalLinkStyle'

const GlobalLink = (props) => {
  const {classes, children, globalLinkClassName, ...rest} = props

  return (
    <Link component={RouterLink} className={cx(classes.globalLink, globalLinkClassName)} {...rest}>
      {children}
    </Link>
  )
}

GlobalLink.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  rest: PropTypes.bool,
}

export default withStyles(globalLinkStyle)(GlobalLink)
