import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import globalButtonStyle from '_asset/global/jss/component/common/button/globalButtonStyle'
import LinearProgress from '@material-ui/core/LinearProgress'

const GlobalButton = (props) => {
  const {
    classes,
    backgroundColor,
    round,
    children,
    fullWidth,
    disabled,
    startIcon,
    endIcon,
    simple,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    inverted,
    text,
    loading,
    actionHeaderButton,
    ...rest
  } = props

  const btnClasses = cx({
    [classes.button]: true,
    [classes[backgroundColor]]: backgroundColor && !inverted,
    [classes[backgroundColor + 'Inverted']]: backgroundColor && inverted,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled || loading,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.actionHeaderButton]: actionHeaderButton,
    [className]: className,
  })
  return (
    <span className={classes.loadingWrapper}>
      <Button
        startIcon={startIcon}
        text={text}
        {...rest}
        classes={muiClasses}
        className={btnClasses}
        endIcon={endIcon}
        disabled={disabled || loading}
      >
        {children}
      </Button>
      {loading && <LinearProgress className={classes.linearProgress} />}
    </span>
  )
}

GlobalButton.propTypes = {
  classes: PropTypes.object.isRequired,
  backgroundColor: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'danger']),
  inverted: PropTypes.bool,
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  actionHeaderButton: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  text: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
}

export default withStyles(globalButtonStyle)(GlobalButton)
