import {
  FETCH_EVENT_PARTICIPANTS_REQUEST,
  FETCH_EVENT_PARTICIPANTS_SUCCESS,
} from '_redux/_action/projectSpecific/types'

const initState = {
  isLoading: true,
  data: [],
  meta: {},
}

export const ListEventParticipantsReducer = (state = initState, action = null) => {
  switch (action.type) {
    case FETCH_EVENT_PARTICIPANTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_EVENT_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        meta: action.data.meta,
        isLoading: false,
      }
    default:
      return state
  }
}
