import React from 'react'
import {KeyboardDateTimePicker} from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import {withStyles} from '@material-ui/core/styles'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import {DATE_TIME_FORMAT} from '_helper/global/constants'
import {Trans} from '@lingui/macro'

const DatetimeInput = (props) => {
  const {
    helperText,
    id,
    input: {value: propsValue, ...input},
    meta,
    label,
    classes,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={classes.dateTimePicker}>
      <KeyboardDateTimePicker
        variant="inline"
        ampm={true}
        autoOk={true}
        format={DATE_TIME_FORMAT}
        label={label}
        value={propsValue ? propsValue : null}
        {...input}
        {...rest}
        invalidDateMessage={<Trans>Invalid Date Format</Trans>}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
      />
      <FormHelperText id={id + '-text'}>{errorMessage || helperText || ' '}</FormHelperText>
    </div>
  )
}

export default withStyles(globalFormFieldStyles)(DatetimeInput)
