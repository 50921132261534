import {dangerColor, primaryColor, successColor, warningColor} from '_asset/global/jss/styleHelpers'

const feedbackStyle = (theme) => ({
  feedbackTitle: {
    marginTop: 20,
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  feedbackQuestionNumber: {
    marginTop: 20,
    color: primaryColor[0],
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  relativePosition: {
    position: 'relative',
    minHeight: 200,
    marginTop: 25,
  },
  absolutePosition: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  columnBox: {
    margin: 4,
  },
  textCenter: {
    textAlign: 'center',
  },
  successColor: {
    '& svg': {
      fill: successColor[0],
    },
  },
  errorColor: {
    '& svg': {
      fill: dangerColor[0],
    },
  },
  neutralColor: {
    '& svg': {
      fill: warningColor[0],
    },
  },
  heightOfQuestionText: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'flex-start',
  },
  mediumSizedIcons: {
    '& svg': {
      height: 30,
      width: 30,
    },
  },
  feedbackBoxWidth: {
    minWidth: '550px !important',
    width: '550px !important',
    boxSizing: 'border-box !important',
  },
  requiredAstrix: {
    color: dangerColor[0],
  },
})

export default feedbackStyle
