import React from 'react'
import {Info, CheckCircle, Warning, Error} from '@material-ui/icons'
import {withStyles} from '@material-ui/core/styles'
import globalToastStyle from '_asset/global/jss/component/common/toast/globalToastStyle'

const Toast = (props) => {
  const {message, type, classes} = props

  const switchIcons = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircle className={classes.toastIcon} />
      case 'warning':
        return <Warning className={classes.toastIcon} />
      case 'info':
        return <Info className={classes.toastIcon} />
      case 'error':
        return <Error className={classes.toastIcon} />
      default:
    }
    return true
  }

  return (
    <div className={classes.toast}>
      {switchIcons(type)}
      <strong>{message}</strong>
    </div>
  )
}

export default withStyles(globalToastStyle)(Toast)
