import React, {useCallback, useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {required} from '_helper/projectSpecific/validations'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {bindActionCreators, compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Box} from '@material-ui/core'
import {getOrganizationUser} from '_redux/_action/projectSpecific/organizationUserActions'
import DialogCreateFormButtons from '_component/global/common/dialog/DialogCreateFormButton'
import {getRoles} from '_redux/_action/projectSpecific/roleActions'
import PasswordInput from '_component/global/common/formField/PasswordInput'
import {translateErrorMessage} from '_helper/global/functions'

const UserChangePasswordForm = (props) => {
  const {userId, id, form, organizationUser, isOrganizationUserLoaded, errorMessage} = props
  const {onSubmit, getOrganizationUser, getRoles} = props

  const fetchOrganizationUser = useCallback(() => {
    return getOrganizationUser(id, userId, true)
  }, [getOrganizationUser, id, userId])

  const fetchOrganizationUserRoles = useCallback(() => {
    return getRoles()
  }, [getRoles])

  useEffect(() => {
    if (userId) {
      fetchOrganizationUser()
    }
    fetchOrganizationUserRoles()
  }, [userId, fetchOrganizationUser, fetchOrganizationUserRoles, form])

  const getInitialValues = () => {
    if (userId && isOrganizationUserLoaded) {
      return {...organizationUser, roleIds: organizationUser.roles}
    }
  }

  return (
    <Form onSubmit={onSubmit} initialValues={getInitialValues()}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="oldPassword"
                  label={<Trans>Old password</Trans>}
                  validate={required}
                  component={PasswordInput}
                  helperText={errorMessage && translateErrorMessage(errorMessage)}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="newPassword"
                  label={<Trans>New password</Trans>}
                  validate={required}
                  component={PasswordInput}
                />
              </GridItem>
            </GridContainer>
            <Box pt={1}>
              <DialogCreateFormButtons buttonText={<Trans>Change password</Trans>} />
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationUser,
      getRoles,
    },
    dispatch
  )
}

UserChangePasswordForm.propTypes = {
  userId: PropTypes.number,
  id: PropTypes.number,
  form: PropTypes.string,
  organizationUser: PropTypes.object,
  isOrganizationUserLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  deleteItem: PropTypes.func,
  getOrganizationUser: PropTypes.func,
  getRoles: PropTypes.func,
}

export default compose(
  connect((store) => {
    return {
      organizationUser: store.OrganizationUser.data,
      isOrganizationUserLoaded: !store.OrganizationUser.isLoading,
      roles: store.Roles.data,
    }
  }, mapDispatchToProps)
)(UserChangePasswordForm)
