import React from 'react'
import {Trans} from '@lingui/macro'
import ContentAuthPageWrapper from '_component/global/common/wrapper/ContentAuthPageWrapper'
import AuthPageWrapper from '_component/global/common/wrapper/AuthPageWrapper'
import ResetPasswordForm from '_component/projectSpecific/auth/ResetPasswordForm'

const ResetPasswordPage = (props) => {
  const {
    match: {
      params: {token},
    },
  } = props

  return (
    <AuthPageWrapper>
      <ContentAuthPageWrapper pageTitle={<Trans>Reset password</Trans>}>
        <ResetPasswordForm token={token} />
      </ContentAuthPageWrapper>
    </AuthPageWrapper>
  )
}

export default ResetPasswordPage
