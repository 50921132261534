import React from 'react'
import i18nProvider from 'App/_provider/i18n'
import {t} from '@lingui/macro'

export const feedbackQuestions = [
  {
    question: i18nProvider._(t`Jak jste byli celkově spokojeni s Magenta Experience Centrem?`),
    type: 'range',
    required: true,
    name: 'overAllSatisfaction',
  },
  {
    question: i18nProvider._(t`Jak byli s prostorem spokojeni účastníci vašeho eventu?`),
    type: 'range',
    required: true,
    name: 'participantSatisfaction',
  },
  {
    question: i18nProvider._(t`Jak jste byli spokojeni s organizačním zajištěním?`),
    type: 'range',
    required: true,
    name: 'organizationalSatisfaction',
  },
  {
    question: i18nProvider._(t`Jak jste byli spokojeni s technickým zajištěním akce?`),
    type: 'range',
    required: true,
    name: 'technicalSupportSatisfaction',
  },
  {
    question: i18nProvider._(t`Doporučili by jste MEC kolegům pro konání eventů?`),
    type: 'range',
    required: true,
    name: 'likelihoodOfRecommendation',
  },
  {
    question: i18nProvider._(t`Co Vám nevyhovovalo, nebo máme zlepšit?`),
    type: 'text',
    required: true,
    name: 'improvementSuggestion',
  },
  {
    question: i18nProvider._(t`
        Zajišťovali jsme vám live stream, záznam z akce či dokonce postprodukční práce a grafický
        servis? Jak jste byli spokojeni?
      `),
    type: 'text',
    required: false,
    name: 'audioVideoServicesCommentary',
  },
  {
    question: i18nProvider._(t`
        Zajišťovali jsme vám služby, které nejsou běžně součástí pronájmů prostor a vnímáte je jako
        benefit Magenta Experience Centra?
      `),
    type: 'bool',
    required: false,
    name: 'nonStandardServices',
  },
]
