import React, {useState} from 'react'
import {authorizedAbility} from 'App/_provider/authorizedAbility'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {Edit, Add, DeleteForever} from '@material-ui/icons'
import {useParams} from 'react-router-dom'
import {deletePricing, getEvent} from '_redux/_action/projectSpecific/eventActions'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import WarningDialog from '_component/global/common/dialog/WarningDialog'

const PricingButtons = (props) => {
  const {hasPricing, handleOpenPricingDialog, deletePricing, getEvent} = props

  const {id, date} = useParams()

  const [deleteDialog, setDeleteDialog] = useState(false)

  const handleOpenDeleteDialog = () => {
    setDeleteDialog(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false)
  }

  const canEditEvent =
    authorizedAbility.can('update', 'Events') || authorizedAbility.can('partnerUpdate', 'Events')

  const handleDeletePricing = () => {
    return deletePricing(id, date).then(() => {
      handleCloseDeleteDialog()
      getEvent(id, date)
    })
  }

  return (
    canEditEvent && (
      <>
        <WarningDialog
          open={deleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={handleDeletePricing}
        />
        {hasPricing ? (
          <>
            <GlobalButton
              onClick={handleOpenPricingDialog}
              justIcon={true}
              backgroundColor="success"
              startIcon={<Edit />}
            />
            <GlobalButton
              onClick={handleOpenDeleteDialog}
              justIcon={true}
              backgroundColor="warning"
              startIcon={<DeleteForever />}
            />
          </>
        ) : (
          <GlobalButton
            onClick={handleOpenPricingDialog}
            justIcon={true}
            backgroundColor="success"
            startIcon={<Add />}
          />
        )}
      </>
    )
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({deletePricing, getEvent}, dispatch)
}

export default connect(null, mapDispatchToProps)(PricingButtons)
