import {routesMap} from '_config/routesMap'
import LoginPage from 'App/page/auth/LoginPage'
import ResetPasswordPage from 'App/page/auth/ResetPasswordPage'
import ForgottenPasswordPage from 'App/page/auth/ForgottenPasswordPage'
import FeedbackPage from 'App/page/auth/FeedbackPage'

export const authRoutes = [
  {
    path: routesMap.common.auth.login,
    exact: true,
    component: LoginPage,
  },
  {
    path: routesMap.common.auth.forgottenPassword,
    exact: true,
    component: ForgottenPasswordPage,
  },
  {
    path: `${routesMap.common.auth.resetPassword}/:token`,
    exact: true,
    component: ResetPasswordPage,
  },
  {
    path: `${routesMap.common.auth.satisfaction}/:token`,
    exact: true,
    component: FeedbackPage,
  },
]
