import React from 'react'
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import {Trans} from '@lingui/macro'

const EmailWasSent = () => {
  return (
    <Card>
      <CardHeader
        title={
          <>
            <MailOutlineIcon />
            <Trans>You have a new message!</Trans>
          </>
        }
      />

      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <p>
              <Trans>
                Thank you for your registration. You can finish the registration through a link which you received on
                the email address you entered.
              </Trans>
            </p>
          </Grid>
          <Grid item xs={12}>
            <p className="support">
              <Trans>
                In case of any issue contact us at <a href="mailto:support@eventito.com">support@eventito.com</a>.
              </Trans>
            </p>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default EmailWasSent
