import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'

const LayoutRoute = (props) => {
  const {component: Component, ...rest} = props

  useEffect(() => {
    document.getElementById('content-wrapper').scrollTop = 0
  }, [])

  return <Route {...rest} render={(props) => <Component {...props} />} />
}

export default LayoutRoute
