import React from 'react'
import {Redirect, Router, Switch} from 'react-router-dom'
import {I18nProvider} from '@lingui/react'
import PropTypes from 'prop-types'
import {ToastContainer} from 'react-toastify'
import 'App/appStyle.scss'
import 'react-toastify/dist/ReactToastify.css'
import browserHistoryAccessor from 'App/_provider/history'
import {isTenant} from '_helper/global/functions'
import ScrollToTopRoute from '_component/global/route/ScrollToTopRoute'
import TenantAuthLayout from 'App/layout/TenantAuthLayout'
import TenantAdminLayout from 'App/layout/TenantAdminLayout'
import PublicLayout from 'App/layout/PublicLayout'
import withStyles from '@material-ui/core/styles/withStyles'
import globalToastStyle from '_asset/global/jss/component/common/toast/globalToastStyle'
import {routesMap} from '_config/routesMap'
import moment from 'moment'
import 'moment/locale/cs'
import catalogEn from 'App/_locale/en/messages'
import catalogCs from 'App/_locale/cs/messages'
import i18nProvider from 'App/_provider/i18n'
import {en, cs} from 'make-plural/plurals'

const renderTenantLayouts = () => {
  return [
    <ScrollToTopRoute key={'auth'} path={'/auth'} component={TenantAuthLayout} />,
    <ScrollToTopRoute key={'admin'} path={'/admin'} component={TenantAdminLayout} />,
    <Redirect key={'redirect'} to={routesMap.common.auth.login} />,
  ]
}

const renderNonTenantLayouts = () => {
  return [<ScrollToTopRoute key={'public'} path={'/'} component={PublicLayout} />]
}

const renderSwitchContent = () => {
  return isTenant() ? renderTenantLayouts() : renderNonTenantLayouts()
}

const App = (props) => {
  const {classes} = props

  i18nProvider.load({
    en: catalogEn.messages,
    cs: catalogCs.messages,
  })

  i18nProvider.loadLocaleData('en', {plurals: en})
  i18nProvider.loadLocaleData('cs', {plurals: cs})

  i18nProvider.activate('cs')

  moment.locale('cs')

  return (
    <I18nProvider i18n={i18nProvider}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      <Router history={browserHistoryAccessor}>
        <Switch>{renderSwitchContent()}</Switch>
      </Router>
    </I18nProvider>
  )
}

App.propType = {
  globalSettings: PropTypes.object,
  classes: PropTypes.object.isRequired,
}

export default withStyles(globalToastStyle)(App)
