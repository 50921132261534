import React, {useCallback, useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import TextInput from '_component/global/common/formField/TextInput'
import {
  composeValidators,
  required,
  validateEmail,
  validateLength,
} from '_helper/projectSpecific/validations'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {bindActionCreators, compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Box} from '@material-ui/core'
import {getOrganizationUser} from '_redux/_action/projectSpecific/organizationUserActions'
import DialogEditFormButtons from '_component/global/common/dialog/DialogEditFormButtons'
import DialogCreateFormButtons from '_component/global/common/dialog/DialogCreateFormButton'
import MultiSelectField from '_component/global/common/formField/MultiSelectField'
import {getRoles} from '_redux/_action/projectSpecific/roleActions'
import {getMyAccount} from '_redux/_action/global/authActions'
import {ROLES_LIST} from '_helper/projectSpecific/constants'

const OrganizationUserForm = (props) => {
  const {
    userId,
    id,
    form,
    organizationUser,
    myAccount,
    isMyAccountLoaded,
    isOrganizationUserLoaded,
    roles,
  } = props
  const {onSubmit, deleteItem, getOrganizationUser, getRoles, getMyAccount, disableRolesEdit} =
    props

  const fetchOrganizationUser = useCallback(() => {
    return getOrganizationUser(id, userId, true)
  }, [getOrganizationUser, id, userId])

  const fetchOrganizationUserRoles = useCallback(() => {
    return getRoles()
  }, [getRoles])

  useEffect(() => {
    if (userId) {
      fetchOrganizationUser()
    } else if (form === 'edit') {
      getMyAccount()
    }
    fetchOrganizationUserRoles()
  }, [userId, fetchOrganizationUser, fetchOrganizationUserRoles, getMyAccount, form])

  const isMyAccount = userId === null && form === 'edit'
  const user = isMyAccount ? myAccount : organizationUser
  const formInitialValues = isMyAccount
    ? isMyAccountLoaded && user
    : userId && isOrganizationUserLoaded && {...organizationUser, roleIds: organizationUser.roles}

  const getSelectOptions = (list, valueKey = 'id', labelKey = 'name') => {
    return list.map((item) => {
      return {
        value: item[valueKey],
        label: ROLES_LIST.find((role) => role.value === item[labelKey])?.label,
      }
    })
  }

  return (
    <Form onSubmit={onSubmit} initialValues={formInitialValues}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="name"
                  label={<Trans>User name</Trans>}
                  validate={composeValidators(required, validateLength(100))}
                  component={TextInput}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="email"
                  label={<Trans>User e-mail</Trans>}
                  validate={composeValidators(required, validateEmail)}
                  component={TextInput}
                />
              </GridItem>
              {disableRolesEdit ? (
                ''
              ) : (
                <GridItem xs={12}>
                  <Field
                    name="roleIds"
                    label={<Trans>User roles</Trans>}
                    validate={required}
                    component={MultiSelectField}
                    options={getSelectOptions(roles)}
                  />
                </GridItem>
              )}
            </GridContainer>
            <Box pt={1}>
              {form === 'edit' && userId ? (
                <DialogEditFormButtons
                  permissionAsset="Users"
                  deleteItem={deleteItem !== null ? deleteItem(organizationUser) : null}
                  submitting={formProps.form.submitting}
                />
              ) : (
                <DialogCreateFormButtons buttonText={isMyAccount ? <Trans>Edit</Trans> : null} />
              )}
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationUser,
      getRoles,
      getMyAccount,
    },
    dispatch
  )
}

OrganizationUserForm.propTypes = {
  userId: PropTypes.number,
  id: PropTypes.number,
  form: PropTypes.string,
  organizationUser: PropTypes.object,
  isOrganizationUserLoading: PropTypes.bool,
  myAccount: PropTypes.object,
  isMyAccountLoaded: PropTypes.bool,
  onSubmit: PropTypes.func,
  deleteItem: PropTypes.func,
  getOrganizationUser: PropTypes.func,
  getRoles: PropTypes.func,
}

export default compose(
  connect((store) => {
    return {
      organizationUser: store.OrganizationUser.data,
      myAccount: store.MyAccount.data,
      isOrganizationUserLoaded: !store.OrganizationUser.isLoading,
      isMyAccountLoaded: !store.MyAccount.isLoading,
      roles: store.Roles.data,
    }
  }, mapDispatchToProps)
)(OrganizationUserForm)
