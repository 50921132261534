import React from 'react'
import {Field} from 'react-final-form'
import PropTypes from 'prop-types'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import {withStyles} from '@material-ui/core/styles'

const ErrorField = (props) => {
  const {name, classes} = props
  return (
    <Field
      name={name}
      subscribe={{touched: true, error: true}}
      render={({meta: {touched, error}}) =>
        touched && error ? <span className={classes.spaceConfigurationError}>{error}</span> : null
      }
    />
  )
}

ErrorField.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(stepperFormStyle)(ErrorField)
