import {createTheme} from '@material-ui/core'

import {
  primaryColor,
  cardBackgroundColor,
  defaultBackgroundColor,
  darkText,
  grayColor,
  secondaryColor,
  successColor,
} from '_asset/global/jss/styleHelpers'

const defaultTheme = createTheme({
  typography: {
    fontFamily: ['TeleNeo', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: primaryColor[0],
    },
    secondary: {
      main: secondaryColor[0],
    },
    success: {
      main: successColor[0],
    },
    text: {
      primary: darkText,
      secondary: grayColor[0],
    },
    background: {
      paper: cardBackgroundColor,
      default: defaultBackgroundColor,
    },
  },
})

export default defaultTheme
