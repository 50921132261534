import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import OrganizationForm from '_component/projectSpecific/organization/organizationForm/OrganizationForm'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'

const OrganizationCreateDialog = (props) => {
  const {openCreate, closeCreate, handleCreateSubmit, ...rest} = props
  return (
    <DialogWrapper
      open={openCreate}
      handleClose={closeCreate}
      title={<Trans>Create new organization</Trans>}
      maxWidth="md"
    >
      <OrganizationForm onSubmit={handleCreateSubmit} form="create" {...rest} />
    </DialogWrapper>
  )
}

OrganizationCreateDialog.propTypes = {
  openCreate: PropTypes.bool,
  closeCreate: PropTypes.func,
  handleCreateSubmit: PropTypes.func,
}

export default OrganizationCreateDialog
