import React from 'react'
import PropTypes from 'prop-types'
import {Redirect, Switch} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Footer from '_component/global/layoutElement/Footer'
import {getRoutesForLayoutSwitch} from '_helper/global/routeHelper'
import {authRoutes} from '_config/_route/tenant/authRoutes'
import Gradients from '_component/projectSpecific/common/Gradients'
import globalLayoutStyle from '_asset/global/jss/layout/globalLayoutStyle'
import {routesMap} from '_config/routesMap'

const TenantAuthLayout = (props) => {
  const {classes} = props

  return (
    <div className={classes.authWrapper} id="content-wrapper">
      <Gradients />
      <Switch>
        {getRoutesForLayoutSwitch(authRoutes)}
        <Redirect key={'redirect'} to={routesMap.common.auth.login} />,
      </Switch>
      <Footer />
    </div>
  )
}

TenantAuthLayout.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(globalLayoutStyle)(TenantAuthLayout)
