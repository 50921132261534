import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import TableToolbarActionsSimpleWrapper from '_component/global/common/wrapper/TableToolbarActionsSimpleWrapper'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'

const GlobalTableToolbarSimpleWrapper = (props) => {
  const {classes, children} = props

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <TableToolbarActionsSimpleWrapper>{children}</TableToolbarActionsSimpleWrapper>
      </Toolbar>
    </div>
  )
}

GlobalTableToolbarSimpleWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.node,
}

export default withStyles(globalWrapperStyles)(GlobalTableToolbarSimpleWrapper)
