import {primaryColor} from '_asset/global/jss/styleHelpers'

const globalLinkStyle = (theme) => ({
  globalLink: {
    '&.MuiTypography-colorPrimary': {
      color: primaryColor[0],
      '&:hover': {
        textDecoration: 'none',
        color: primaryColor[1],
      },
    },
  },
  text: {
    cursor: 'pointer',
  },
})

export default globalLinkStyle
