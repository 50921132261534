import React, {useState} from 'react'
import {Form} from 'react-final-form'
import PropTypes from 'prop-types'
import HorizontalStepFormWrapper from '_component/global/common/stepper/HorrizontalStepFormWrapper'
import GlobalStepperButtons from '_component/global/common/button/GlobalStepperButtons'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import {
  DATABASE_DATE_FORMAT,
  DATE_TIME_DATABASE_FORMAT,
  TIME_FORMAT,
} from '_helper/global/constants'
import ProposalActionsFormFields from '_component/projectSpecific/proposal/proposalForm/ProposaActionsFormFields'
import moment from 'moment'
import TopicWarningMessage from '_component/projectSpecific/event/TopicWarningMessage'
import {isTimeFree} from '_redux/_action/projectSpecific/eventActions'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import WarningMessage from '_component/projectSpecific/common/WarningMessage'
import {Trans} from '@lingui/macro'

const ProposalDateTimeForm = (props) => {
  const {onSubmitDateTime, goStepBack, initialValues, availableDates, topic, isTimeFree} = props
  const [timeFree, setTimeFree] = useState(true)

  const availableDatesList = Object.keys(availableDates)
  const handleDisableDates = (day) => {
    return !availableDatesList.includes(day.format(DATABASE_DATE_FORMAT))
  }

  const prepareInitialValues = (initialValues) => {
    if (
      initialValues.proposalActions?.length > 0 &&
      initialValues.proposalActions[0].customTimeFrom
    ) {
      return {
        ...initialValues,
        proposalActions: [
          {
            ...initialValues.proposalActions[0],
            customTimeFrom: moment(initialValues.proposalActions[0].date).set({
              hour: initialValues.proposalActions[0].customTimeFrom.split(':')[0],
              minute: initialValues.proposalActions[0].customTimeFrom.split(':')[1],
            }),
          },
        ],
      }
    }
    return initialValues
  }

  const handleSubmit = (values) => {
    const preparedValues = values.proposalActions[0]
    const prepareParams = {
      length: preparedValues.customTimeLength && preparedValues.customTimeLength / 60,
      preparationLength:
        preparedValues.customPreparationLength && preparedValues.customPreparationLength / 60,
      from:
        preparedValues.customTimeFrom &&
        preparedValues.date &&
        moment(
          `${moment(preparedValues.date).format(DATABASE_DATE_FORMAT)}T${moment(
            preparedValues.customTimeFrom
          ).format(TIME_FORMAT)}`
        ).format(DATE_TIME_DATABASE_FORMAT),
      spaceConfiguration: preparedValues.spaceConfiguration && preparedValues.spaceConfiguration,
    }

    if (preparedValues.timeSlot === 'CUSTOM') {
      isTimeFree(prepareParams).then((res) => {
        if (res) {
          onSubmitDateTime(values)
        } else {
          setTimeFree(res)
        }
      })
    } else {
      onSubmitDateTime(values)
    }
  }

  return (
    <HorizontalStepFormWrapper mediumWidth={8} largeWidth={6}>
      <Form onSubmit={handleSubmit} initialValues={prepareInitialValues(initialValues)}>
        {(formProps) => {
          const selectedDate =
            formProps.values.proposalActions &&
            moment(formProps.values.proposalActions[0].date).format(DATABASE_DATE_FORMAT)
          const timeSlotOptions = availableDates[selectedDate] || []
          const customTimeSlot =
            formProps.values.proposalActions &&
            formProps.values.proposalActions[0].timeSlot === 'CUSTOM'

          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                {topic && <TopicWarningMessage topic={topic} />}
                <ProposalActionsFormFields
                  topic={topic}
                  handleDisableDates={handleDisableDates}
                  timeSlotOptions={timeSlotOptions}
                  selectedDate={selectedDate}
                  customTimeSlot={customTimeSlot}
                />
                {!timeFree && (
                  <WarningMessage
                    warningMessage={
                      <Trans>The time of this event overlaps with another event!</Trans>
                    }
                  />
                )}
                <GridItem xs={12}>
                  <GlobalStepperButtons handleBack={goStepBack} />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

ProposalDateTimeForm.propTypes = {
  onSubmitDateTime: PropTypes.func,
  goStepBack: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      isTimeFree,
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(ProposalDateTimeForm)
