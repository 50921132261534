import React from 'react'
import {TextField} from '@material-ui/core'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from "classnames";

const NumberInput = (props) => {
  const {classes, input, meta, helperText, min, max, step, endAdornment, ...rest} = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <TextField
      className={cx(classes.root, classes.numberAlignRight)}
      fullWidth
      type="number"
      error={!!errorMessage}
      helperText={errorMessage || helperText || ' '}
      InputProps={{inputProps: {min: min, max: max, step: step}, endAdornment: endAdornment}}
      {...input}
      {...rest}
    />
  )
}

NumberInput.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
  helperText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  endAdornment: PropTypes.node,
}

export default withStyles(globalFormFieldStyles)(NumberInput)
