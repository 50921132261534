import React from 'react'
import {Trans} from '@lingui/macro'
import moment from 'moment'
import {withStyles} from '@material-ui/core'
import footer from '_asset/projectSpecific/img/footer.svg'
import logo from '_asset/projectSpecific/img/eluvia_logo_dark.png'
import GridItem from '_component/global/common/grid/GridItem'
import GridContainer from '_component/global/common/grid/GridContainer'
import globalLayoutElementsStyle from '_asset/global/jss/component/layoutElement/globalLayoutElementsStyle'
import GlobalLink from '_component/global/common/button/GlobalLink'
import cx from 'classnames'

const Footer = (props) => {
  const {classes} = props
  const eluviaAddress = 'https://www.eluvia.com'

  const currentYear = moment().year()
  return (
    <GridContainer className={classes.footerGrid}>
      <GridItem xs={12} md={5} className={classes.footerLinks}>
        {/* once there are a real links, fill to={''} and remove disabledLink class */}
        <GlobalLink to={''} className={cx(classes.footerLink, classes.disabledLink)}>
          <Trans>About app</Trans>
        </GlobalLink>
        <a className={cx(classes.globalLink, classes.footerLink)} href="/tos.pdf" target="_blank">
          <Trans>Terms & Conditions</Trans>
        </a>
        {/* once there are a real links, fill to={''} and remove disabledLink class */}
        <GlobalLink to={''} className={cx(classes.footerLink, classes.disabledLink)}>
          <Trans>Contact</Trans>
        </GlobalLink>
      </GridItem>
      <GridItem xs={12} md={true} className={classes.footerLogo}>
        <a href={eluviaAddress}>
          <img src={logo} alt="logo" className={classes.footerLogoImg} />
        </a>
      </GridItem>
      <GridItem xs={12} md={5} className={classes.footerLaunch}>
        <a href={eluviaAddress}>
          <Trans>Launched from land to cloud by Eluvians</Trans>
        </a>
        <a href={eluviaAddress}>
          <span className={classes.footerIcons}>
            <img src={footer} alt="" className={classes.footerIconMiddle} />
          </span>
        </a>
        <a href={eluviaAddress}>2010 - {currentYear}</a>
      </GridItem>
    </GridContainer>
  )
}

export default withStyles(globalLayoutElementsStyle)(Footer)
