import React from 'react'
import {proposalListStyle} from '_asset/projectSpecific/jss/proposal/proposalListStyle'
import {Tooltip, withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {Visibility} from '@material-ui/icons'
import TableActionsButton from '_component/global/common/button/TableActionsButton'
import {EVENT_SPACE_CONFIGURATIONS} from '_helper/projectSpecific/constants'

const ProposalSpaceConfigurationCell = (props) => {
  const {spaceConfiguration, openSpaceConfiguration, classes} = props
  return (
    <div className={classes.proposalSpaceConfigurationCell}>
      <TableActionsButton
        onClick={openSpaceConfiguration(spaceConfiguration)}
        backgroundColor="secondary"
      >
        <Tooltip title={<Trans>Detail space configuration</Trans>}>
          <Visibility />
        </Tooltip>
      </TableActionsButton>

      <span>
        {spaceConfiguration &&
          EVENT_SPACE_CONFIGURATIONS.find((config) => config.id === spaceConfiguration)
            .configuration}
      </span>
    </div>
  )
}

ProposalSpaceConfigurationCell.propTypes = {
  classes: PropTypes.object.isRequired,
  spaceConfiguration: PropTypes.string,
  openSpaceConfiguration: PropTypes.func,
}

export default withStyles(proposalListStyle)(ProposalSpaceConfigurationCell)
