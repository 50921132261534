import React from 'react'
import PropTypes from 'prop-types'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Cell from '_component/global/common/table/Cell'
import {prepareFilteringState} from '_helper/global/functions'

const SimpleTableHead = (props) => {
  const {onRequestSort, orderDirection, orderBy, columns} = props

  const createSortHandler = (property) => (event) => {
    const sort = prepareFilteringState(orderBy, orderDirection, property)

    return onRequestSort(sort)
  }

  return (
    <TableHead>
      <TableRow>
        {columns?.map((column) => (
          <Cell
            key={column.id}
            sortDirection={orderDirection}
            isRowAction={column.id === 'actions'}
          >
            {column.sortable && (
              <Tooltip title="Sort" placement="top-end" enterDelay={300}>
                <TableSortLabel
                  active={column.order_key ? orderBy === column.order_key : orderBy === column.id}
                  direction={orderDirection}
                  onClick={createSortHandler(column.order_key ? column.order_key : column.id)}
                >
                  <strong>{column.label}</strong>
                </TableSortLabel>
              </Tooltip>
            )}
            {!column.sortable && column.label}
          </Cell>
        ))}
      </TableRow>
    </TableHead>
  )
}

SimpleTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  orderDirection: PropTypes.string,
  orderBy: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  align: PropTypes.string,
}

export default SimpleTableHead
