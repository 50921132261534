import React from 'react'
import {TextField} from '@material-ui/core'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'

const TextInput = (props) => {
  const {classes, input, meta, helperText, customOnChange, ...rest} = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <TextField
      {...input}
      className={classes.root}
      fullWidth
      error={!!errorMessage}
      helperText={errorMessage || helperText || ' '}
      onChange={(e) => {
        input && input.onChange(e)
        customOnChange && customOnChange(e.target.value)
      }}
      {...rest}
    />
  )
}

TextInput.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
  helperText: PropTypes.string,
  customOnChange: PropTypes.func,
}

export default withStyles(globalFormFieldStyles)(TextInput)
