import React, {useEffect} from 'react'
import {Route, withRouter} from 'react-router-dom'

const ScrollToTopRoute = (properties) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {component: Component, ...rest} = properties

  return <Route {...rest} render={() => <Component {...rest} />} />
}

export default withRouter(ScrollToTopRoute)
