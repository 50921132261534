import React from 'react'
import {Group} from '@material-ui/icons'
import PropTypes from 'prop-types'
import {proposalDetailStyle} from '_asset/projectSpecific/jss/proposal/proposalDetailStyle'
import {withStyles} from '@material-ui/core'

const EstimatedCapacityDetailCell = (props) => {
  const {estimatedCapacity, classes} = props

  return (
    <div className={classes.estimatedCapacityDetailCell}>
      {estimatedCapacity}
      <span>
        <Group />
      </span>
    </div>
  )
}

EstimatedCapacityDetailCell.propTypes = {
  estimatedCapacity: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(proposalDetailStyle)(EstimatedCapacityDetailCell)
