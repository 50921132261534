import React from 'react'
import {Field} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {composeValidators, required, validateMinValue} from '_helper/projectSpecific/validations'
import GridItem from '_component/global/common/grid/GridItem'
import FormCondition from '_component/global/form/FormCondition'
import SelectField from '_component/global/common/formField/SelectField'
import {RR_CONSTANTS} from '_helper/projectSpecific/constants'
import NumberInput from "_component/global/common/formField/NumberInput";

const MonthlyRecurrenceFields = ({havePartnerPermission, handleChangeType}) => {
  return (
    <>
      <GridItem xs={12} sm={6}>
        <Field
          name={`rruleConfig.interval`}
          label={<Trans>Every X month(s)</Trans>}
          component={NumberInput}
          type="number"
          min={1}
          validate={composeValidators(required, validateMinValue(1))}
          disabled={havePartnerPermission}
        />
      </GridItem>

      <GridItem xs={12} sm={6}>
        <Field
          name="rruleConfig.help.monthlyRepetition"
          label={<Trans>Type</Trans>}
          component={SelectField}
          options={RR_CONSTANTS.monthlyRepetitionType}
          validate={required}
          disabled={havePartnerPermission}
          customOnChange={handleChangeType}
        />
      </GridItem>

      <FormCondition when="rruleConfig.help.monthlyRepetition" is={'onDay'}>
        <GridItem xs={12} sm={6}>
          <Field
            name={`rruleConfig.bymonthday`}
            label={<Trans>Day</Trans>}
            component={SelectField}
            options={RR_CONSTANTS.daysInMonthNumbers.map((value) => {
              return {value, label: `${value}.`}
            })}
            validate={required}
            disabled={havePartnerPermission}
          />
        </GridItem>
      </FormCondition>

      <FormCondition when="rruleConfig.help.monthlyRepetition" is={'onThe'}>
        <GridItem xs={12} sm={6}>
          <Field
            name={`rruleConfig.bysetpos`}
            label={<Trans>Which</Trans>}
            component={SelectField}
            options={RR_CONSTANTS.dayInMonthPosition}
            validate={required}
            disabled={havePartnerPermission}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Field
            name={`rruleConfig.byweekday`}
            label={<Trans>Day</Trans>}
            component={SelectField}
            options={[...RR_CONSTANTS.daysOptions, ...RR_CONSTANTS.daysOptionsExt]}
            validate={required}
            disabled={havePartnerPermission}
            valuesToString={true}
          />
        </GridItem>
      </FormCondition>
    </>
  )
}

export default MonthlyRecurrenceFields
