import {Trans} from '@lingui/macro'
import React from 'react'

export const ORGANIZATION_USERS_LIST_COLUMNS = [
  {
    id: 'name',
    sortable: true,
    order_key: 'name',
    label: <Trans>User name</Trans>,
  },
  {
    id: 'email',
    sortable: true,
    order_key: 'email',
    label: <Trans>User e-mail</Trans>,
  },
  {
    id: 'userRoles',
    sortable: true,
    order_key: 'userRoles',
    label: <Trans>User roles</Trans>,
  },
  {
    id: 'contact',
    sortable: false,
    label: <Trans>Contact</Trans>,
  },
  {
    id: 'actions',
    sortable: false,
    order_key: 'actions',
    label: '',
  },
]
