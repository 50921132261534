import {Trans} from '@lingui/macro'
import React from 'react'

export const PROPOSALS_LIST_COLUMNS = [
  {
    id: 'status',
    order_key: 'status',
    sortable: true,
    label: <Trans>Status</Trans>,
  },
  {
    id: 'topicId',
    sortable: true,
    order_key: 'topic.id',
    label: <Trans>Topic</Trans>,
  },
  {
    id: 'name',
    sortable: true,
    order_key: 'name',
    label: <Trans>Event name</Trans>,
  },
  {
    id: 'type',
    sortable: true,
    order_key: 'type',
    label: <Trans>Event type</Trans>,
  },
  {
    id: 'expiresInDays',
    sortable: true,
    order_key: 'createdAt',
    label: <Trans>Expiration (days)</Trans>,
  },
  {
    id: 'date',
    order_key: 'date',
    label: <Trans>Date and time</Trans>,
  },
  {
    id: 'spaceConfiguration',
    order_key: 'spaceConfiguration',
    label: <Trans>Space</Trans>,
  },
  {
    id: 'organizationId',
    sortable: true,
    order_key: 'organization.id',
    label: <Trans>Organization</Trans>,
  },
  {
    id: 'actions',
    sortable: false,
    order_key: 'actions',
    label: '',
  },
]
