import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Footer from '_component/global/layoutElement/Footer'
import Gradients from '_component/projectSpecific/common/Gradients'
import {Redirect, Switch} from 'react-router-dom'
import {getRoutesForLayoutSwitch} from '_helper/global/routeHelper'
import {adminRoutes} from '_config/_route/tenant/adminRoutes'
import AdminSidebar from '_component/global/layoutElement/AdminSidebar'
import AdminNavbar from '_component/global/layoutElement/AdminNavbar'
import {withStyles} from '@material-ui/core/styles'
import globalLayoutStyle from '_asset/global/jss/layout/globalLayoutStyle'
import cx from 'classnames'
import LinearProgress from '@material-ui/core/LinearProgress'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {routesMap} from '_config/routesMap'

const TenantAdminLayout = (props) => {
  const {classes, globalLoading, localLoading} = props
  const [openedDrawer, setOpenedDrawer] = useState(true)

  const handleDrawerToggle = () => {
    setOpenedDrawer(!openedDrawer)
  }

  const mainPanelClasses = cx(classes.mainAdminPanel, {
    [classes.mainAdminPanelSidebarMini]: !openedDrawer,
    [classes.mainAdminPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
  })

  useEffect(() => {
    if (window.innerWidth < 960) {
      setOpenedDrawer(false)
    }
  }, [])

  return (
    <div className={classes.adminLayoutWrapper}>
      <div className={cx(classes.indeterminate, classes.positionLoader)}>
        <LinearProgress
          className={cx(classes.positionLoader, {[classes.hide]: !globalLoading && !localLoading})}
          classes={{indeterminate: classes.indeterminate}}
        />
      </div>
      <Gradients size={[400, 800]} />
      <AdminSidebar openedDrawer={openedDrawer} handleDrawerToggle={handleDrawerToggle} />
      <div className={mainPanelClasses} id="content-wrapper">
        <AdminNavbar />
        <div className={classes.contentAdminLayout}>
          <div className={cx(classes.containerAdminLayout, {[classes.hide]: globalLoading})}>
            <Switch>
              {getRoutesForLayoutSwitch(adminRoutes, true)}
              <Redirect key={'redirect'} to={routesMap.tenant.admin.calendar} />,
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

TenantAdminLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  globalLoading: PropTypes.bool,
  localLoading: PropTypes.bool,
}

export default compose(
  withStyles(globalLayoutStyle),
  connect((store) => {
    return {
      globalLoading: store.Loader.global,
      localLoading: store.Loader.local,
    }
  })
)(TenantAdminLayout)
