import React from 'react'
import PropTypes from 'prop-types'
import GridContainer from '_component/global/common/grid/GridContainer'
import {withStyles} from '@material-ui/core/styles'
import {globalWrapperStyles} from '_asset/global/jss/component/common/wrapper/globalWrapperStyles'

const AuthPageWrapper = (props) => {
  const {alert, children, classes} = props

  return (
    <GridContainer justifyContent="center" alignItems="center" className={classes.authPageWrapper}>
      {alert}
      {children}
    </GridContainer>
  )
}

AuthPageWrapper.propTypes = {
  alert: PropTypes.node,
  children: PropTypes.node,
}

export default withStyles(globalWrapperStyles)(AuthPageWrapper)
