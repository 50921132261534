import NewUser from '_component/projectSpecific/registration/NewUser'
import {routesMap} from '_config/routesMap'

export const publicRoutes = [
  {
    path: routesMap.nonTenant.public.registration,
    exact: true,
    component: NewUser,
  },
]
