import React from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {required, requiredNumber} from '_helper/projectSpecific/validations'
import HorizontalStepFormWrapper from '_component/global/common/stepper/HorrizontalStepFormWrapper'
import {withStyles} from '@material-ui/core/styles'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import TextInput from '_component/global/common/formField/TextInput'
import SelectField from '_component/global/common/formField/SelectField'
import GridItem from '_component/global/common/grid/GridItem'
import GridContainer from '_component/global/common/grid/GridContainer'
import {EVENT_TYPES, PRIVACY_LIST} from '_helper/projectSpecific/constants'
import GlobalStepperButtons from '_component/global/common/button/GlobalStepperButtons'
import PropTypes from 'prop-types'
import CustomRadioGroup from '_component/global/common/formField/CustomRadioGroup'
import SwitcherField from '_component/global/common/formField/SwitcherField'
import NumberInput from "_component/global/common/formField/NumberInput";

const ProposalSettingsForm = (props) => {
  const {onSubmitSettings, goStepBack, initialValues, classes} = props

  return (
    <HorizontalStepFormWrapper mediumWidth={8} largeWidth={6}>
      <Form onSubmit={onSubmitSettings} initialValues={initialValues}>
        {(formProps) => {
          const customType = formProps.values.type === 'CUSTOM'
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem xs={6}>
                  <Field
                    name="name"
                    label={<Trans>Event name</Trans>}
                    component={TextInput}
                    validate={required}
                  />
                  <Field
                    name="type"
                    label={<Trans>Event type</Trans>}
                    component={SelectField}
                    options={EVENT_TYPES}
                    validate={required}
                  />
                  {customType && (
                    <Field
                      name="customType"
                      label={<Trans>Custom event type</Trans>}
                      component={TextInput}
                      disabled={!customType}
                      validate={customType && required}
                    />
                  )}
                  <Field
                    name="estimatedCapacity"
                    label={<Trans>Estimated capacity</Trans>}
                    component={NumberInput}
                    min={0}
                    type="number"
                    validate={requiredNumber}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field
                    name="platform"
                    label={<Trans>Platform</Trans>}
                    component={SwitcherField}
                    type="checkbox"
                    valueA="Offline"
                    valueB="Online"
                    className={classes.proposalPlatformSwitcher}
                  />
                  <Field
                    name="privacy"
                    component={CustomRadioGroup}
                    options={PRIVACY_LIST}
                    validate={required}
                    xs={12}
                  />
                </GridItem>
              </GridContainer>
              <GridItem xs={12}>
                <GlobalStepperButtons handleBack={goStepBack} />
              </GridItem>
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

ProposalSettingsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmitSettings: PropTypes.func,
  goStepBack: PropTypes.func,
}
export default withStyles(stepperFormStyle)(ProposalSettingsForm)
