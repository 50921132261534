import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import PropTypes from 'prop-types'
import {horizontalStepperStyle} from '_asset/global/jss/component/stepper/horizontalStepperStyle'
import {withStyles} from '@material-ui/core/styles'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'

const HorizontalStepper = (props) => {
  const {steps, activeStep, classes} = props
  return (
    <GridContainer justifyContent="center">
      <GridItem sm={11} md={9} xl={7}>
        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel className={classes.stepLabel}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </GridItem>
    </GridContainer>
  )
}

HorizontalStepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
}

export default withStyles(horizontalStepperStyle)(HorizontalStepper)
