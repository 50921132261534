import React from 'react'
import FullTextSearchForm from '_component/global/form/FullTextSearchForm'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {Add, TableChart} from '@material-ui/icons'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import {Can} from 'App/_provider/authorizedAbility'
import {withStyles} from '@material-ui/core/styles'
import globalButtonStyle from '_asset/global/jss/component/common/button/globalButtonStyle'
import DropDownButton from '_component/global/common/button/DropDownButton'
import {Box} from '@material-ui/core'

const OrganizationListActionsHeader = (props) => {
  const {handleSearch, openDialog, classes, exportAllContacts, exportAllAddresses} = props
  return (
    <>
      <FullTextSearchForm handleSubmit={handleSearch} />
      <Can I="create" an="Organizations">
        <GlobalButton
          onClick={openDialog}
          backgroundColor="primary"
          startIcon={<Add />}
          className={classes.newButton}
        >
          <div>
            <Trans>New organization</Trans>
          </div>
        </GlobalButton>
      </Can>
      <Can I="read" an="Organization">
        <Box pl={1.5}>
          <DropDownButton
            backgroundColor="primary"
            label={<Trans>Export</Trans>}
            dropDownItems={[
              {
                label: (
                  <>
                    <TableChart />
                    <Trans>All contacts to XLS</Trans>
                  </>
                ),
                onClick: exportAllContacts,
              },
              {
                label: (
                  <>
                    <TableChart />
                    <Trans>All addresses to XLS</Trans>
                  </>
                ),
                onClick: exportAllAddresses,
              },
            ]}
          />
        </Box>
      </Can>
    </>
  )
}

OrganizationListActionsHeader.propTypes = {
  classes: PropTypes.object,
  handleSearch: PropTypes.func,
  openDialog: PropTypes.func,
  exportAllContacts: PropTypes.func,
  exportAllAddresses: PropTypes.func,
}

export default withStyles(globalButtonStyle)(OrganizationListActionsHeader)
