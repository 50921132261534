import React from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Box, IconButton} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import {Person} from '@material-ui/icons'
import {Trans} from '@lingui/macro'
import {login} from '_redux/_action/global/authActions'
import {composeValidators, required, validateEmail} from '_helper/projectSpecific/validations'
import PropTypes from 'prop-types'
import TextInput from '_component/global/common/formField/TextInput'
import PasswordInput from '_component/global/common/formField/PasswordInput'
import GlobalButton from '_component/global/common/button/GlobalButton'
import {routesMap} from '_config/routesMap'
import GlobalLink from '_component/global/common/button/GlobalLink'
import CustomCheckbox from '_component/global/common/formField/CustomCheckbox'
import globalLayoutElementsStyle from '_asset/global/jss/component/layoutElement/globalLayoutElementsStyle'
import {withStyles} from '@material-ui/core/styles'

const LoginForm = (props) => {
  const {login, classes} = props

  const handleSubmit = (values) => {
    return login(values).catch(() => {}) // catch secures props.form.reset() trigger
  }

  return (
    <Form onSubmit={handleSubmit}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <Field
              name="email"
              label={<Trans>Username / e-mail</Trans>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <Person />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              component={TextInput}
              validate={composeValidators(required, validateEmail)}
              autoComplete="email"
            />
            <Field
              name="password"
              label={<Trans>Password</Trans>}
              component={PasswordInput}
              validate={required}
              autoComplete="current-password"
            />
            <Field
              name="tos_agreement"
              type="checkbox"
              label={
                <>
                  <Trans>I agree with</Trans>{' '}
                  <a className={classes.tosLink} href="/tos.pdf" target="_blank">
                    <Trans>Terms Of Service</Trans>
                  </a>
                </>
              }
              component={CustomCheckbox}
              validate={required}
            />
            <Box pt={1}>
              <GlobalButton
                backgroundColor="primary"
                fullWidth
                type="submit"
                loading={formProps.submitting}
              >
                <Trans>Login</Trans>
              </GlobalButton>
            </Box>
            <Box pt={1} textAlign={'center'}>
              <GlobalLink to={routesMap.common.auth.forgottenPassword}>
                <Trans>Forgotten password</Trans>
              </GlobalLink>
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  )
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

export default compose(
  withStyles(globalLayoutElementsStyle),
  connect(null, mapDispatchToProps)
)(LoginForm)
