import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import HorizontalStepFormWrapper from '_component/global/common/stepper/HorrizontalStepFormWrapper'
import {withStyles} from '@material-ui/core/styles'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import CustomCheckbox from '_component/global/common/formField/CustomCheckbox'
import GridContainer from '_component/global/common/grid/GridContainer'
import GridItem from '_component/global/common/grid/GridItem'
import TextInput from '_component/global/common/formField/TextInput'
import {ORGANIZATIONAL_SETUP, PROPOSAL_REQUIREMENTS} from '_helper/projectSpecific/constants'
import GlobalStepperButtons from '_component/global/common/button/GlobalStepperButtons'
import SelectField from '_component/global/common/formField/SelectField'
import Tooltip from '@material-ui/core/Tooltip'
import {Info} from '@material-ui/icons'
import ConfigurationDialog from '_component/projectSpecific/proposal/proposalForm/ConfigurationDialog'
import {authorizedAbility} from 'App/_provider/authorizedAbility'

const EventRequirementsForm = (props) => {
  const {
    onSubmitRequirements,
    goStepBack,
    initialValues,
    classes,
    havePartnerPermission,
    operation,
    configuration,
  } = props

  const getSubmitText = () => {
    if (operation === 'edit') {
      return <Trans>Edit</Trans>
    }
    return <Trans>Create</Trans>
  }
  const [configurationDialog, setConfigurationDialog] = useState(false)

  return (
    <HorizontalStepFormWrapper>
      <ConfigurationDialog
        openDialog={configurationDialog}
        closeDialog={() => setConfigurationDialog(false)}
      />
      <Form onSubmit={onSubmitRequirements} initialValues={initialValues}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              {configuration.zone !== 'PODCAST' &&
                PROPOSAL_REQUIREMENTS.map((proposalRequirement, indexProposal) => {
                  return (
                    <div key={indexProposal} className={classes.requirementBox}>
                      <div className={classes.spaceConfigurationLabel}>
                        <h4>{proposalRequirement.header}</h4>
                        {proposalRequirement.group === 'organizationalRequirements' && (
                          <Tooltip
                            title={<Trans>Configuration map</Trans>}
                            placement="top"
                            enterDelay={300}
                          >
                            <a href="/planek-usporadani.pdf" target="_blank">
                              <Info />
                            </a>
                          </Tooltip>
                        )}
                      </div>
                      <GridContainer>
                        {proposalRequirement.requirements.map((requirement, index) => {
                          return (
                            <GridItem key={index} xs={6} md={3} className={classes.resetPadding}>
                              <Field
                                name={proposalRequirement.group}
                                value={requirement.value}
                                type="checkbox"
                                label={requirement.label}
                                component={CustomCheckbox}
                                disabled={havePartnerPermission}
                              />
                            </GridItem>
                          )
                        })}
                        {proposalRequirement.setUp && (
                          <GridItem xs={12} md={6} className={classes.resetPadding}>
                            <Field
                              name={proposalRequirement.setUp.name}
                              label={proposalRequirement.setUp.label}
                              component={SelectField}
                              options={ORGANIZATIONAL_SETUP}
                              disabled={havePartnerPermission}
                            />
                          </GridItem>
                        )}
                      </GridContainer>
                      <Field
                        name={proposalRequirement.note.name}
                        label={proposalRequirement.note.label}
                        component={TextInput}
                        variant={'outlined'}
                        multiline={true}
                        disabled={havePartnerPermission}
                      />
                    </div>
                  )
                })}

              {authorizedAbility.can('manage', 'Internal') && (
                <div key={'internal'} className={classes.requirementNoPaddingBox}>
                  <div className={classes.spaceConfigurationLabel}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <h4>
                          <Trans>Internal requirements</Trans>
                        </h4>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name={'internalTechnicalNote'}
                          label={<Trans>Internal technical note</Trans>}
                          component={TextInput}
                          variant={'outlined'}
                          multiline={true}
                          disabled={havePartnerPermission}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Field
                          name={'internalOrganizationalNote'}
                          label={<Trans>Internal organizational note</Trans>}
                          component={TextInput}
                          variant={'outlined'}
                          multiline={true}
                          disabled={havePartnerPermission}
                        />
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              )}
              <GlobalStepperButtons
                handleBack={() => goStepBack(formProps.values)}
                submitButtonLabel={getSubmitText()}
              />
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

EventRequirementsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmitRequirements: PropTypes.func,
  goStepBack: PropTypes.func,
  havePartnerPermission: PropTypes.bool,
  operation: PropTypes.string,
  configuration: PropTypes.object,
}

export default withStyles(stepperFormStyle)(EventRequirementsForm)
