import React from 'react'
import {Field} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {required, validateMaxValue} from '_helper/projectSpecific/validations'
import {withStyles} from '@material-ui/core/styles'
import {stepperFormStyle} from '_asset/projectSpecific/jss/common/stepperFormStyle'
import GridItem from '_component/global/common/grid/GridItem'
import CustomCheckbox from '_component/global/common/formField/CustomCheckbox'
import FormCondition from '_component/global/form/FormCondition'
import SelectField from '_component/global/common/formField/SelectField'
import {RR_CONSTANTS} from '_helper/projectSpecific/constants'
import {RRule} from 'rrule'
import MonthlyRecurrenceFields from '_component/projectSpecific/event/eventForm/recurrenceLogic/MonthlyRecurrenceFields'
import WeeklyRecurrenceFields from '_component/projectSpecific/event/eventForm/recurrenceLogic/WeeklyRecurrenceFields'
import DailyRecurrenceFields from '_component/projectSpecific/event/eventForm/recurrenceLogic/DailyRecurrenceFields'
import RecurrenceEndFields from '_component/projectSpecific/event/eventForm/recurrenceLogic/RecurrenceEndFields'
import WarningMessage from '_component/projectSpecific/common/WarningMessage'
import NumberInput from '_component/global/common/formField/NumberInput'
import InputAdornment from '@material-ui/core/InputAdornment'

const RecurrenceLogicFields = (props) => {
  const {classes, havePartnerPermission, operation, handleChangeFreq, handleChangeType} = props

  return (
    <>
      <GridItem xs={12} sm={6}>
        <Field
          name="isRecurrent"
          label={<Trans>Recurrent event</Trans>}
          type="checkbox"
          component={CustomCheckbox}
          disabled={havePartnerPermission}
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <Field
          name="preparationLength"
          label={<Trans>Length of preparation</Trans>}
          component={NumberInput}
          type="number"
          max={720}
          min={0}
          step={1}
          validate={validateMaxValue(720)}
          disabled={havePartnerPermission}
          endAdornment={
            <InputAdornment position="end">
              <Trans>Min.</Trans>
            </InputAdornment>
          }
        />
      </GridItem>
      {operation === 'edit' && (
        <FormCondition when="isRecurrent" is={true}>
          <GridItem xs={12}>
            <WarningMessage
              warningMessage={
                <Trans>
                  If the event is recurrent, always edit the first occurrence, otherwise there is a
                  risk of corrupting later events.
                </Trans>
              }
            />
          </GridItem>
        </FormCondition>
      )}
      <FormCondition when="isRecurrent" is={true}>
        <GridItem xs={12}>
          <h3 className={classes.recurrenceHeaders}>
            <Trans>Frequency</Trans>
          </h3>
        </GridItem>
        <GridItem xs={12}>
          <Field
            name="rruleConfig.freq"
            label={<Trans>Frequency</Trans>}
            component={SelectField}
            options={RR_CONSTANTS.repeatOptions}
            validate={required}
            disabled={havePartnerPermission}
            customOnChange={handleChangeFreq}
          />
        </GridItem>

        <FormCondition when="rruleConfig.freq" is={RRule.MONTHLY}>
          <MonthlyRecurrenceFields
            havePartnerPermission={havePartnerPermission}
            handleChangeType={handleChangeType}
          />
        </FormCondition>

        <FormCondition when="rruleConfig.freq" is={RRule.WEEKLY}>
          <WeeklyRecurrenceFields havePartnerPermission={havePartnerPermission} />
        </FormCondition>

        <FormCondition when="rruleConfig.freq" is={RRule.DAILY}>
          <DailyRecurrenceFields havePartnerPermission={havePartnerPermission} />
        </FormCondition>

        <RecurrenceEndFields havePartnerPermission={havePartnerPermission} />
      </FormCondition>
    </>
  )
}

RecurrenceLogicFields.propTypes = {
  classes: PropTypes.object,
  havePartnerPermission: PropTypes.bool,
  operation: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  handleChangeFreq: PropTypes.func,
  handleChangeType: PropTypes.func,
}

export default withStyles(stepperFormStyle)(RecurrenceLogicFields)
