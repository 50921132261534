import React from 'react'
import PropTypes from 'prop-types'
import GlobalTableToolbarSelectedWrapper from '_component/global/common/wrapper/GlobalTableToolbarSelectedWrapper'
import GlobalTableToolbarSimpleWrapper from '_component/global/common/wrapper/GlobalTableToolbarSimpleWrapper'

const TableToolbarWrapper = (props) => {
  const {selectable, children, ...rest} = props
  const Toolbar = selectable ? GlobalTableToolbarSelectedWrapper : GlobalTableToolbarSimpleWrapper

  return <Toolbar {...rest}>{children}</Toolbar>
}

TableToolbarWrapper.propTypes = {
  selectable: PropTypes.bool,
  numSelected: PropTypes.number,
  children: PropTypes.node,
}

export default TableToolbarWrapper
