import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import React from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import GridContainer from '_component/global/common/grid/GridContainer'
import globalDialogStyle from '_asset/global/jss/component/common/dialog/globalDialogStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import WarningDialogButtons from '_component/global/common/dialog/WarningDialogButtons'
import {Trans} from '@lingui/macro'

const WarningDialog = (props) => {
  const {open, handleClose, handleConfirm, classes, message, title} = props

  return (
    <>
      {open && (
        <DialogWrapper handleClose={handleClose} open={open}>
          <GridContainer justifyContent="center">
            <ErrorOutlineIcon className={classes.warningIcon} />
            <GridContainer justifyContent="center" className={classes.warningTitle}>
              {title || <Trans>Are you sure?</Trans>}
            </GridContainer>
            <GridContainer justifyContent="center" className={classes.warningBody}>
              {message}
            </GridContainer>
            <WarningDialogButtons handleClose={handleClose} handleConfirm={handleConfirm} />
          </GridContainer>
        </DialogWrapper>
      )}
    </>
  )
}

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  children: PropTypes.node,
}

export default withStyles(globalDialogStyle)(WarningDialog)
