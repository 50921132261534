import React from 'react'
import PropTypes from 'prop-types'
import HorizontalStepper from '_component/global/common/stepper/HorizontalStepper'

const HorizontalStepperWrapper = (props) => {
  const {steps, getStepContent, activeStep} = props

  return (
    <>
      <HorizontalStepper steps={steps} activeStep={activeStep} />
      {getStepContent(activeStep)}
    </>
  )
}

HorizontalStepperWrapper.propTypes = {
  steps: PropTypes.array,
  getStepContent: PropTypes.func,
  activeStep: PropTypes.number,
}

export default HorizontalStepperWrapper
