import React from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {composeValidators, required, validateEmail} from '_helper/projectSpecific/validations'
import {bindActionCreators, compose} from 'redux'
import {forgottenPasswordRequest} from '_redux/_action/global/authActions'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import TextInput from '_component/global/common/formField/TextInput'
import {routesMap} from '_config/routesMap'
import GlobalButton from '_component/global/common/button/GlobalButton'
import GlobalLink from '_component/global/common/button/GlobalLink'
import {Box} from '@material-ui/core'

const ForgottenPasswordForm = (props) => {
  const {forgottenPasswordRequest} = props

  const handleSubmit = (values) => {
    return forgottenPasswordRequest(values)
  }

  return (
    <Form onSubmit={handleSubmit}>
      {(props) => {
        const {handleSubmit, submitting} = props

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Field
              name="email"
              label={<Trans>E-mail</Trans>}
              component={TextInput}
              validate={composeValidators(required, validateEmail)}
            />
            <Box pt={1} textAlign={'right'}>
              <GlobalButton fullWidth backgroundColor="primary" type="submit" loading={submitting}>
                <Trans>Send</Trans>
              </GlobalButton>
            </Box>
            <Box pt={1} textAlign={'center'}>
              <GlobalLink to={routesMap.common.auth.login}>
                <Trans>Back to login</Trans>
              </GlobalLink>
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgottenPasswordRequest,
    },
    dispatch
  )
}

ForgottenPasswordForm.propTypes = {
  classes: PropTypes.object,
  forgottenPasswordRequest: PropTypes.func.isRequired,
}

export default compose(connect(null, mapDispatchToProps))(ForgottenPasswordForm)
