import React from 'react'
import {Box} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import ContentAuthPageWrapper from '_component/global/common/wrapper/ContentAuthPageWrapper'
import AuthPageWrapper from '_component/global/common/wrapper/AuthPageWrapper'
import ForgottenPasswordForm from '_component/projectSpecific/auth/ForgottenPasswordForm'

const ForgottenPasswordPage = () => {
  return (
    <AuthPageWrapper>
      <ContentAuthPageWrapper pageTitle={<Trans>Forgotten password</Trans>}>
        <Box textAlign={'center'} padding={'10px'}>
          <Trans>We will send you a link where you can set a new password</Trans>
        </Box>
        <ForgottenPasswordForm />
      </ContentAuthPageWrapper>
    </AuthPageWrapper>
  )
}

export default ForgottenPasswordPage
