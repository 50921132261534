import {UPDATE_LOADER_SUCCESS, UPDATE_LOCAL_LOADER_SUCCESS} from '_redux/_action/global/types'

export function updateGlobalLoader(data) {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_LOADER_SUCCESS,
      data,
    })
  }
}

export function updateLocalLoader(data) {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_LOCAL_LOADER_SUCCESS,
      data,
    })
  }
}
