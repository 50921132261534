import React, {useState} from 'react'
import {MailOutline, PersonOutlineOutlined} from '@material-ui/icons'
import NewUserForm from '_component/projectSpecific/registration/NewUserForm'
import EmailWasSent from '_component/projectSpecific/registration/EmailWasSent'
import Footer from '_component/global/layoutElement/Footer'
import Gradients from '_component/projectSpecific/common/Gradients'
import httpClient from '_helper/global/httpClient'
import api from '_config/endpoints'

const NewUser = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleSubmit = (values) => {
    httpClient
      .post(api.onBoardingStart, values)
      .then((res) => {
        handleNext()
      })
      .catch((err) => {
        console.error('err response: ', err.response)
      })
  }

  const handleNext = () => {
    // to make sure that the new user wont go further than to 1
    setActiveStep(1)
  }

  const onSubmit = (values) => {
    handleSubmit(values)
  }

  const icons = [<PersonOutlineOutlined />, <MailOutline />]

  const components = [<NewUserForm onSubmit={onSubmit} />, <EmailWasSent />]

  return (
    <>
      <Gradients size={[400, 800]} />
      <div className="watermark-icon">{icons[activeStep]}</div>

      <div className="registration-grid empty-header">
        <div className="grid-header" />

        <div className="grid-main">
          <main className="empty-header">{components[activeStep]}</main>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default NewUser
