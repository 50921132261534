import React from 'react'
import {Field} from 'react-final-form'
import PropTypes from 'prop-types'

const FormCondition = ({when, is, children}) => (
  <Field name={when} subscription={{value: true}}>
    {({input: {value}}) => (value === is ? children : null)}
  </Field>
)

FormCondition.propTypes = {
  when: PropTypes.string.isRequired,
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
}

export default FormCondition
