import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import OrganizationUserForm from '_component/projectSpecific/organization/organizationForm/OrganizationUserForm'

const OrganizationUserEditDialog = (props) => {
  const {
    openUserEdit,
    closeUserEdit,
    handleUserEditSubmit,
    userId,
    organizationId,
    deleteUser,
    ...rest
  } = props

  return (
    <DialogWrapper
      fullWidth={true}
      open={openUserEdit}
      handleClose={closeUserEdit}
      title={<Trans>Edit user</Trans>}
      maxWidth="sm"
    >
      <OrganizationUserForm
        onSubmit={handleUserEditSubmit}
        userId={userId}
        id={organizationId}
        deleteItem={deleteUser}
        form="edit"
        {...rest}
      />
    </DialogWrapper>
  )
}

OrganizationUserEditDialog.propTypes = {
  openUserEdit: PropTypes.bool,
  closeUserEdit: PropTypes.func,
  deleteUser: PropTypes.func,
  handleUserEditSubmit: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default OrganizationUserEditDialog
