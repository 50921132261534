import React, {useState} from 'react'
import {Editor} from 'react-draft-wysiwyg'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import {EditorState, ContentState, convertToRaw, convertFromHTML} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '_asset/projectSpecific/style/wysiwygInput.scss'

const WysiwygInput = (props) => {
  const {classes, input, ...rest} = props

  const [editorState, setEditorState] = useState(
    input.value
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(input.value))
        )
      : EditorState.createEmpty()
  )

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'list', 'link'],
        inline: {options: ['bold', 'italic']},
        list: {options: ['unordered']},
        link: {defaultTargetOption: '_blank', options: ['link', 'unlink']},
      }}
      editorClassName="wysiwygInputEditor"
      toolbarClassName="wysiwygEditorToolbar"
      stripPastedStyles={true}
      {...rest}
    />
  )
}

WysiwygInput.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  rest: PropTypes.object,
}

export default withStyles(globalFormFieldStyles)(WysiwygInput)
