import {Field, Form} from 'react-final-form'
import React, {useCallback, useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import {withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {getAvailableDates} from '_redux/_action/projectSpecific/eventActions'
import {getTopics} from '_redux/_action/projectSpecific/topicActions'
import {getListOrganizations} from '_redux/_action/projectSpecific/organizationActions'
import {connect} from 'react-redux'
import SelectField from '_component/global/common/formField/SelectField'
import {getSelectOptions} from '_helper/global/functions'
import calendarStyle from '_asset/global/jss/component/calendar/calendarStyle'
import {EVENT_SPACE_CONFIGURATIONS} from '_helper/projectSpecific/constants'
import {authorizedAbility, Can} from 'App/_provider/authorizedAbility'
import TextInput from '_component/global/common/formField/TextInput'

let searchInterval = null

const EventCalendarFilters = (props) => {
  const {
    setEventsFilter,
    classes,
    topics,
    getTopics,
    organizations,
    getListOrganizations,
    selectedYear,
    eventsFilter,
  } = props

  const [organizerSearch, setOrganizerSearch] = useState(null)

  const fetchTopics = useCallback(() => {
    if (eventsFilter?.topicId) {
      setEventsFilter((prevState) => ({...prevState, topicId: ''}))
    }
    return getTopics(selectedYear, 0, 1000, 'name')
  }, [getTopics, selectedYear])

  const fetchOrganizations = useCallback(() => {
    return getListOrganizations(0, 1000, 'name')
  }, [getListOrganizations])

  useEffect(() => {
    if (authorizedAbility.can('read', 'Organization')) {
      fetchOrganizations()
    }
    if (authorizedAbility.can('read', 'Topics')) {
      fetchTopics()
    }
  }, [fetchTopics, fetchOrganizations])

  const setFilters = (name, value) => {
    setEventsFilter((prevState) => ({...prevState, [name]: value}))
  }

  const debounceSearch = (value) => {
    setOrganizerSearch(value)
    if (organizerSearch) {
      clearTimeout(searchInterval)
      searchInterval = setTimeout(() => {
        setEventsFilter((prevState) => ({...prevState, organizer: value}))
      }, 500)
    }
  }

  return (
    <Form onSubmit={setEventsFilter} initialValues={eventsFilter}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.onSubmit} className={classes.eventFilters}>
            <Can I="read" an="Organization">
              <Field
                name="organizationId"
                label={<Trans>Organization</Trans>}
                component={SelectField}
                options={getSelectOptions(organizations)}
                emptyOption={true}
                emptyOptionLabel={<Trans>reset</Trans>}
                customOnChange={setFilters}
              />
            </Can>
            <Can I="read" an="Topics">
              <Field
                name="topicId"
                label={<Trans>Topic</Trans>}
                component={SelectField}
                options={getSelectOptions(topics)}
                emptyOption={true}
                emptyOptionLabel={<Trans>reset</Trans>}
                customOnChange={setFilters}
              />
            </Can>
            <Field
              name="spaceConfiguration"
              label={<Trans>Space</Trans>}
              component={SelectField}
              options={getSelectOptions(EVENT_SPACE_CONFIGURATIONS, 'id', 'configuration')}
              emptyOption={true}
              emptyOptionLabel={<Trans>reset</Trans>}
              customOnChange={setFilters}
            />
            {authorizedAbility.can('manage', 'Internal') && (
              <Field
                name="organizer"
                label={<Trans>Organizer</Trans>}
                component={TextInput}
                customOnChange={debounceSearch}
              />
            )}
          </form>
        )
      }}
    </Form>
  )
}

EventCalendarFilters.propTypes = {
  setEventsFilter: PropTypes.func.isRequired,
  getTopics: PropTypes.func,
  getListOrganizations: PropTypes.func,
  topics: PropTypes.array,
  organizations: PropTypes.array,
  selectedYear: PropTypes.number,
  eventsFilter: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAvailableDates,
      getTopics,
      getListOrganizations,
    },
    dispatch
  )
}

export default compose(
  withStyles(calendarStyle),
  connect((store) => {
    return {
      topics: store.SimpleListTopics.data,
      organizations: store.Organizations.data,
      localLoading: store.Loader.local,
    }
  }, mapDispatchToProps)
)(EventCalendarFilters)
