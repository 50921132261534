import httpClient from '_helper/global/httpClient'
import {
  FETCH_LIST_EVENTS_REQUEST,
  FETCH_LIST_EVENTS_SUCCESS,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_SHARING_REQUEST,
  FETCH_EVENT_SHARING_SUCCESS,
  FETCH_EVENTS_CALENDAR_REQUEST,
  FETCH_EVENTS_CALENDAR_SUCCESS,
} from '_redux/_action/projectSpecific/types'
import endpoints from '_config/endpoints'
import {globalApiErrorHandler} from '_helper/global/functions'
import {UPDATE_FILTERS_STATE} from '_redux/_action/global/types'

export function getListEvents(
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc',
  filter = ''
) {
  return function (dispatch) {
    const listEventsFilters = {
      listEventsFilters: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter,
      },
    }
    dispatch({type: UPDATE_FILTERS_STATE, data: listEventsFilters})
    dispatch({type: FETCH_LIST_EVENTS_REQUEST})
    return httpClient
      .get(endpoints.events, {
        limit,
        offset,
        orderBy,
        orderDirection: orderDirection.toUpperCase(),
        filter,
      })
      .then((response) => {
        dispatch({
          type: FETCH_LIST_EVENTS_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getEventsCalendar(
  from,
  to,
  organizationId = null,
  topicId = null,
  spaceConfiguration = null,
  organizer = null
) {
  return function (dispatch) {
    const eventsCalendarFilters = {
      from,
      to,
      organizationId: organizationId ? organizationId : null,
      topicId: topicId ? topicId : null,
      spaceConfiguration: spaceConfiguration ? spaceConfiguration : null,
      organizer: organizer ? organizer : null,
    }
    dispatch({type: FETCH_EVENTS_CALENDAR_REQUEST})
    return httpClient
      .get(endpoints.calendar, {
        ...eventsCalendarFilters,
      })
      .then((response) => {
        dispatch({
          type: FETCH_EVENTS_CALENDAR_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getEvent(eventId, date) {
  return function (dispatch) {
    dispatch({type: FETCH_EVENT_REQUEST})
    return httpClient
      .get(`${endpoints.events}${eventId}/date/${date}`)
      .then((response) => {
        dispatch({
          type: FETCH_EVENT_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function createEvent(formParams) {
  return function () {
    return httpClient.post(endpoints.events, formParams).catch(globalApiErrorHandler)
  }
}

export function editEvent(eventId, formParams) {
  return function () {
    return httpClient.put(`${endpoints.events}${eventId}/`, formParams).catch(globalApiErrorHandler)
  }
}

export function deleteEvent(eventId, formParams) {
  return function () {
    return httpClient
      .put(`${endpoints.events}${eventId}/delete`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function getAvailableDates(formParams) {
  return function () {
    return httpClient
      .get(endpoints.dateAvailability, formParams)
      .then((response) => {
        const datesMap = {}
        response.data.forEach((item) => {
          datesMap[item.availableDate] = item.timeSlots
        })
        return datesMap
      })
      .catch(globalApiErrorHandler)
  }
}

export function eventSharing(eventId, date) {
  return function (dispatch) {
    dispatch({type: FETCH_EVENT_SHARING_REQUEST})
    return httpClient
      .get(`${endpoints.events}${eventId}/date/${date}/sharing`)
      .then((response) => {
        dispatch({
          type: FETCH_EVENT_SHARING_SUCCESS,
          data: response.data,
        })
        return response.data
      })
      .catch(globalApiErrorHandler)
  }
}

export function isTimeFree(params) {
  return function (dispatch) {
    return httpClient
      .get(`${endpoints.isTimeFree}`, params)
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}

export function getSingleEventVisual(id, date) {
  return function (dispatch) {
    return httpClient
      .get(
        `${endpoints.print}/event/${id}/${date}`,
        {},
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}

export function getWeekEventVisual(date) {
  return function (dispatch) {
    return httpClient
      .get(
        `${endpoints.print}/week/${date}`,
        {},
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}

export function createPricing(eventId, date, formParams) {
  return function (dispatch) {
    return httpClient
      .post(`${endpoints.events}${eventId}/date/${date}/pricing`, formParams)
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}

export function patchPricing(eventId, date, formParams) {
  return function (dispatch) {
    return httpClient
      .put(`${endpoints.events}${eventId}/date/${date}/pricing`, formParams)
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}

export function deletePricing(eventId, date) {
  return function (dispatch) {
    return httpClient
      .del(`${endpoints.events}${eventId}/date/${date}/pricing`)
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}

export function createSatisfaction(data, token) {
  return function (dispatch) {
    return httpClient
      .post(`${endpoints.satisfaction}/${token}`, data)
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}
