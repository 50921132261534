import React from 'react'
import {KeyboardTimePicker} from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import {withStyles} from '@material-ui/core/styles'
import {globalFormFieldStyles} from '_asset/global/jss/component/common/formField/globalFormFieldStyles'
import {TIME_FORMAT} from '_helper/global/constants'

const TimeInput = (props) => {
  const {
    helperText,
    id,
    input: {value: propsValue, ...input},
    meta,
    label,
    classes,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={classes.dateTimePicker}>
      <KeyboardTimePicker
        variant="inline"
        ampm={true}
        autoOk={true}
        format={TIME_FORMAT}
        label={label}
        value={propsValue ? propsValue : null}
        {...input}
        {...rest}
      />
      <FormHelperText id={id + '-text'}>{errorMessage || helperText || ' '}</FormHelperText>
    </div>
  )
}

export default withStyles(globalFormFieldStyles)(TimeInput)
