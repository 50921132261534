import React from 'react'
import GridItem from '_component/global/common/grid/GridItem'
import {Trans} from '@lingui/macro'
import {withStyles} from '@material-ui/core/styles'
import {topicsCalendarStyle} from '_asset/projectSpecific/jss/topic/topicsCalendarStyle'
import cx from 'classnames'
import TopicItem from '_component/projectSpecific/topic/topicList/TopicItem'
import {Add} from '@material-ui/icons'
import {Box, Tooltip} from '@material-ui/core'
import {MONTHS_MAP} from '_helper/projectSpecific/constants'
import PropTypes from 'prop-types'
import {Can} from 'App/_provider/authorizedAbility'

const TopicsCalendarItem = (props) => {
  const {year, month, classes, openEdit, openCreate} = props

  const haveTopics = month.topics.length
  const topicClasses = cx(
    classes.topicItem,
    haveTopics ? classes.topicItemFull : classes.topicItemEmpty
  )

  const topicMonth = month.month

  return (
    <GridItem key={topicMonth} xs={6} md={4} className={classes.topicGridItem}>
      <div className={classes.topicStretch}>
        <div className={topicClasses}>
          <Box display="flex" flexDirection="row">
            <h4>
              {MONTHS_MAP[topicMonth]} {year}
            </h4>
            <Can I="create" a="Topics">
              <Tooltip title={<Trans>Create new topic</Trans>}>
                <Add className={classes.addTopicButton} onClick={openCreate(topicMonth)} />
              </Tooltip>
            </Can>
          </Box>
          <div className={classes.topicList}>
            {haveTopics ? (
              month.topics.map((topic, index) => {
                return (
                  <TopicItem key={index} topic={topic} onClickItem={openEdit} month={topicMonth} />
                )
              })
            ) : (
              <Trans>No topic</Trans>
            )}
          </div>
        </div>
      </div>
    </GridItem>
  )
}

TopicsCalendarItem.propTypes = {
  classes: PropTypes.object.isRequired,
  year: PropTypes.node,
  month: PropTypes.object,
  openEdit: PropTypes.func,
  openCreate: PropTypes.func,
}

export default withStyles(topicsCalendarStyle)(TopicsCalendarItem)
