// ORGANIZATION USERS and USER
export const FETCH_ORGANIZATION_USERS_REQUEST = 'FETCH_ORGANIZATION_USERS_REQUEST'
export const FETCH_ORGANIZATION_USERS_SUCCESS = 'FETCH_ORGANIZATION_USERS_SUCCESS'

export const FETCH_ORGANIZATION_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_ORGANIZATION_USER_SUCCESS = 'FETCH_USER_SUCCESS'

export const FETCH_ORGANIZATION_ADDRESSES_REQUEST = 'FETCH_ORGANIZATION_ADDRESSES_REQUEST'
export const FETCH_ORGANIZATION_ADDRESSES_SUCCESS = 'FETCH_ORGANIZATION_ADDRESSES_SUCCESS'

export const FETCH_ORGANIZATION_ADDRESS_REQUEST = 'FETCH_ORGANIZATION_ADDRESS_REQUEST'
export const FETCH_ORGANIZATION_ADDRESS_SUCCESS = 'FETCH_ORGANIZATION_ADDRESS_SUCCESS'

export const FETCH_ORGANIZATION_CONTACTS_REQUEST = 'FETCH_ORGANIZATION_CONTACTS_REQUEST'
export const FETCH_ORGANIZATION_CONTACTS_SUCCESS = 'FETCH_ORGANIZATION_CONTACTS_SUCCESS'

export const FETCH_ORGANIZATION_CONTACT_REQUEST = 'FETCH_ORGANIZATION_CONTACT_REQUEST'
export const FETCH_ORGANIZATION_CONTACT_SUCCESS = 'FETCH_ORGANIZATION_CONTACT_SUCCESS'

// MY ORGANIZATION
export const FETCH_MY_ORGANIZATION_REQUEST = 'FETCH_MY_ORGANIZATION_REQUEST'
export const FETCH_MY_ORGANIZATION_SUCCESS = 'FETCH_MY_ORGANIZATION_SUCCESS'

// MY ACCOUNT
export const FETCH_MY_ACCOUNT_REQUEST = 'FETCH_MY_ACCOUNT_REQUEST'
export const FETCH_MY_ACCOUNT_SUCCESS = 'FETCH_MY_ACCOUNT_SUCCESS'

// ORGANIZATIONS and ORGANIZATION
export const FETCH_LIST_ORGANIZATIONS_REQUEST = 'FETCH_LIST_ORGANIZATIONS_REQUEST'
export const FETCH_LIST_ORGANIZATIONS_SUCCESS = 'FETCH_LIST_ORGANIZATIONS_SUCCESS'

export const FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST'
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS'

// TOPICS
export const FETCH_TOPICS_CALENDAR_REQUEST = 'FETCH_TOPICS_CALENDAR_REQUEST'
export const FETCH_TOPICS_CALENDAR_SUCCESS = 'FETCH_TOPICS_CALENDAR_SUCCESS'

export const FETCH_SIMPLE_LIST_TOPICS_REQUEST = 'FETCH_SIMPLE_LIST_TOPICS_REQUEST'
export const FETCH_SIMPLE_LIST_TOPICS_SUCCESS = 'FETCH_SIMPLE_LIST_TOPICS_SUCCESS'

export const FETCH_TOPIC_REQUEST = 'FETCH_TOPIC_REQUEST'
export const FETCH_TOPIC_SUCCESS = 'FETCH_TOPIC_SUCCESS'

// EVENTS
export const FETCH_LIST_EVENTS_REQUEST = 'FETCH_LIST_EVENTS_REQUEST'
export const FETCH_LIST_EVENTS_SUCCESS = 'FETCH_LIST_EVENTS_SUCCESS'

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST'
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS'

// CALENDAR
export const FETCH_EVENTS_CALENDAR_REQUEST = 'FETCH_EVENTS_CALENDAR_REQUEST'
export const FETCH_EVENTS_CALENDAR_SUCCESS = 'FETCH_EVENTS_CALENDAR_SUCCESS'

// PROPOSALS
export const FETCH_PROPOSALS_CALENDAR_REQUEST = 'FETCH_PROPOSALS_CALENDAR_REQUEST'
export const FETCH_PROPOSALS_CALENDAR_SUCCESS = 'FETCH_PROPOSALS_CALENDAR_SUCCESS'

// EVENT PARTICIPANTS
export const FETCH_EVENT_PARTICIPANTS_REQUEST = 'FETCH_EVENT_PARTICIPANTS_REQUEST'
export const FETCH_EVENT_PARTICIPANTS_SUCCESS = 'FETCH_EVENT_PARTICIPANTS_SUCCESS'

// EVENT SHARING
export const FETCH_EVENT_SHARING_REQUEST = 'FETCH_EVENT_SHARING_REQUEST'
export const FETCH_EVENT_SHARING_SUCCESS = 'FETCH_EVENT_SHARING_SUCCESS'

// PROPOSALS
export const FETCH_LIST_PROPOSALS_REQUEST = 'FETCH_LIST_PROPOSALS_REQUEST'
export const FETCH_LIST_PROPOSALS_SUCCESS = 'FETCH_LIST_PROPOSALS_SUCCESS'

export const FETCH_PROPOSAL_REQUEST = 'FETCH_PROPOSAL_REQUEST'
export const FETCH_PROPOSAL_SUCCESS = 'FETCH_PROPOSAL_SUCCESS'

export const FETCH_PROPOSAL_CONFIRM_DATA_REQUEST = 'FETCH_PROPOSAL_CONFIRM_DATA_REQUEST'
export const FETCH_PROPOSAL_CONFIRM_DATA_SUCCESS = 'FETCH_PROPOSAL_CONFIRM_DATA_SUCCESS'

// ROLES
export const FETCH_LIST_ROLES_REQUEST = 'FETCH_LIST_ROLES_REQUEST'
export const FETCH_LIST_ROLES_SUCCESS = 'FETCH_LIST_ROLES_SUCCESS'

// STATISTICS
export const FETCH_STATISTICS_REQUEST = 'FETCH_STATISTICS_REQUEST'
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS'

export const FETCH_PRICING_STATISTICS_REQUEST = 'FETCH_PRICING_STATISTICS_REQUEST'
export const FETCH_PRICING_STATISTICS_SUCCESS = 'FETCH_PRICING_STATISTICS_SUCCESS'

export const FETCH_SATISFACTION_STATISTICS_REQUEST = 'FETCH_SATISFACTION_STATISTICS_REQUEST'
export const FETCH_SATISFACTION_STATISTICS_SUCCESS = 'FETCH_SATISFACTION_STATISTICS_SUCCESS'
