import {Star} from '@material-ui/icons'
import React from 'react'
import {eventDetailStyle} from '_asset/projectSpecific/jss/event/eventDetailStyle'
import {withStyles} from '@material-ui/core'

const EventDetailTitle = (props) => {
  const {name, classes} = props
  return (
    <div className={classes.topItem}>
      <span>{name}</span>
      <span>
        <Star />
        <span>TOP</span>
      </span>
    </div>
  )
}

export default withStyles(eventDetailStyle)(EventDetailTitle)
