import React from 'react'
import {Trans} from '@lingui/macro'
import {Edit, DeleteForever, Add, TableChart} from '@material-ui/icons'
import GlobalButton from '_component/global/common/button/GlobalButton'
import PropTypes from 'prop-types'
import {Can} from 'App/_provider/authorizedAbility'
import {withStyles} from '@material-ui/core/styles'
import globalButtonStyle from '_asset/global/jss/component/common/button/globalButtonStyle'
import DropDownButton from '_component/global/common/button/DropDownButton'
import {Box} from '@material-ui/core'

const OrganizationDetailActionsHeader = (props) => {
  const {
    classes,
    tabsValue,
    onEditClick,
    odDeleteClick,
    onCreateUserClick,
    onCreateAddressClick,
    onExportAddressesClick,
    onCreateContactClick,
    onExportContactsClick,
  } = props

  if (tabsValue === 'detail') {
    return (
      <>
        <Can I="update" an="Organizations">
          <GlobalButton
            onClick={onEditClick}
            justIcon={true}
            backgroundColor="success"
            startIcon={<Edit />}
          />
        </Can>
        <Can I="delete" an="Organizations">
          <GlobalButton
            onClick={odDeleteClick}
            justIcon={true}
            backgroundColor="warning"
            startIcon={<DeleteForever />}
          />
        </Can>
      </>
    )
  } else if (tabsValue === 'users') {
    return (
      <>
        <Can I="create" an="Users">
          <GlobalButton
            onClick={onCreateUserClick}
            backgroundColor="primary"
            startIcon={<Add />}
            className={classes.newButton}
          >
            <Trans>New user</Trans>
          </GlobalButton>
        </Can>
      </>
    )
  } else if (tabsValue === 'addresses') {
    return (
      <>
        <Can I="create" an="Organizations">
          <GlobalButton
            onClick={onCreateAddressClick}
            backgroundColor="primary"
            startIcon={<Add />}
            className={classes.newButton}
          >
            <Trans>New address</Trans>
          </GlobalButton>
        </Can>
        <Can I="read" an="Organizations">
          <Box pl={1.5}>
            <DropDownButton
              backgroundColor="primary"
              label={<Trans>Export</Trans>}
              dropDownItems={[
                {
                  label: (
                    <>
                      <TableChart />
                      <Trans>Export to XLS</Trans>
                    </>
                  ),
                  onClick: onExportAddressesClick,
                },
              ]}
            />
          </Box>
        </Can>
      </>
    )
  } else {
    return (
      <>
        <Can I="create" an="Organizations">
          <GlobalButton
            onClick={onCreateContactClick}
            backgroundColor="primary"
            startIcon={<Add />}
            className={classes.newButton}
          >
            <Trans>New contact</Trans>
          </GlobalButton>
        </Can>
        <Can I="read" an="Organizations">
          <Box pl={1.5}>
            <DropDownButton
              backgroundColor="primary"
              label={<Trans>Export</Trans>}
              dropDownItems={[
                {
                  label: (
                    <>
                      <TableChart />
                      <Trans>Export to XLS</Trans>
                    </>
                  ),
                  onClick: onExportContactsClick,
                },
              ]}
            />
          </Box>
        </Can>
      </>
    )
  }
}

OrganizationDetailActionsHeader.propTypes = {
  classes: PropTypes.object,
  tabsValue: PropTypes.string,
  onEditClick: PropTypes.func,
  odDeleteClick: PropTypes.func,
  onCreateUserClick: PropTypes.func,
  onCreateAddressClick: PropTypes.func,
  onCreateContactClick: PropTypes.func,
  onExportContactClick: PropTypes.func,
  onExportAddressesClick: PropTypes.func,
}

export default withStyles(globalButtonStyle)(OrganizationDetailActionsHeader)
