import {FORCE_NO_AUTH} from '_config/configConstant'
import {resolveEnvironmentParam} from '_helper/global/functions'

export const ENVIRONMENT = process.env.NODE_ENV
export const GATEWAY_URL = resolveEnvironmentParam('GATEWAY_URL')
export const BASE_FE_HOSTNAME = resolveEnvironmentParam('BASE_FE_HOSTNAME')
export const PUBLIC_API_KEY = resolveEnvironmentParam('PUBLIC_API_KEY')
export const OVERRULE_AUTH = ENVIRONMENT !== 'production' && FORCE_NO_AUTH

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DATE_TIME_DATABASE_FORMAT = 'YYYY-MM-DDTHH:mm'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD'
export const SIMPLE_DATE_FORMAT = 'DD.MM.'
export const SIMPLE_DATE_FORMAT_SPACED = 'DD. MM.'
export const TIME_FORMAT = 'HH:mm'

export const BORDER_RADIUS = '3px'
