import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import TopicForm from '_component/projectSpecific/topic/topicForm/TopicForm'

const TopicCreateDialog = (props) => {
  const {openCreate, closeCreate, handleCreateSubmit, month, year} = props
  return (
    <DialogWrapper
      open={openCreate}
      maxWidth="md"
      handleClose={closeCreate}
      title={<Trans>Create new topic</Trans>}
    >
      <TopicForm year={year} month={month} onSubmit={handleCreateSubmit} form="create" />
    </DialogWrapper>
  )
}

TopicCreateDialog.propTypes = {
  openCreate: PropTypes.bool,
  closeCreate: PropTypes.func,
  handleCreateSubmit: PropTypes.func,
  month: PropTypes.number,
  year: PropTypes.number,
}

export default TopicCreateDialog
