import React from 'react'
import PropTypes from 'prop-types'
import TablePagination from '@material-ui/core/TablePagination'
import {ThemeProvider} from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import globalPaginationStyle from '_asset/global/jss/component/common/table/globalPaginationStyle'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import updateGlobalSettings from '_redux/_action/global/globalSettingsActions'
import {Trans} from '@lingui/macro'

const Pagination = (props) => {
  const {count, rowsPerPage, rowsPerPageOptions, page, onChangePage, updateGlobalSettings} = props

  const updateRowsPerPage = (event) => {
    updateGlobalSettings({rowsPerPage: event.target.value})
  }

  const handleChangePage = (event, value) => {
    onChangePage(value)
  }

  return (
    <>
      <Hidden smUp implementation="css">
        <ThemeProvider theme={globalPaginationStyle}>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count ? count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={updateRowsPerPage}
            labelRowsPerPage={<Trans>Rows per page:</Trans>}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} z ${count !== -1 ? count : `více než ${to}`}`
            }
          />
        </ThemeProvider>
      </Hidden>
      <Hidden smDown implementation="css">
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count ? count : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={updateRowsPerPage}
          labelRowsPerPage={<Trans>Rows per page:</Trans>}
          labelDisplayedRows={({from, to, count}) =>
            `${from}-${to} z ${count !== -1 ? count : `více než ${to}`}`
          }
        />
      </Hidden>
    </>
  )
}

Pagination.propTypes = {
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSettings,
    },
    dispatch
  )
}

export default connect((store) => {
  return {
    rowsPerPage: store.GlobalSettings.rowsPerPage,
    rowsPerPageOptions: store.GlobalSettings.rowsPerPageOptions,
    total: store.Organizations.total,
  }
}, mapDispatchToProps)(Pagination)
