import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from '_component/global/common/wrapper/DialogWrapper'
import OrganizationUserForm from '_component/projectSpecific/organization/organizationForm/OrganizationUserForm'

const OrganizationUserCreateDialog = (props) => {
  const {openCreate, closeCreate, handleCreateSubmit, ...rest} = props
  return (
    <DialogWrapper
      fullWidth={true}
      open={openCreate}
      handleClose={closeCreate}
      title={<Trans>Create new user</Trans>}
      maxWidth="sm"
    >
      <OrganizationUserForm onSubmit={handleCreateSubmit} form="create" {...rest} />
    </DialogWrapper>
  )
}

OrganizationUserCreateDialog.propTypes = {
  openCreate: PropTypes.bool,
  closeCreate: PropTypes.func,
  handleCreateSubmit: PropTypes.func,
}

export default OrganizationUserCreateDialog
