import React from 'react'
import moment from 'moment'
import {DATE_FORMAT, TIME_FORMAT} from '_helper/global/constants'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import {eventDetailStyle} from '_asset/projectSpecific/jss/event/eventDetailStyle'

const EventDateTime = (props) => {
  const {from, length, isRecurrent, preparationLength, classes} = props

  const eventLength = Math.round(length * 60)
  const dateTimeFrom = moment(from).format(TIME_FORMAT)
  const preparationDateTimeFrom = moment(from)
    .subtract(Math.round(preparationLength * 60), 'minute')
    .format(TIME_FORMAT)
  const dateTimeTo = moment(from).add(eventLength, 'minutes').format(TIME_FORMAT)

  return (
    <div className={classes.detailEventDateTimeItem}>
      <div className={classes.detailEventDateTimeItemDate}>{moment(from).format(DATE_FORMAT)}</div>
      <div>
        {!!preparationLength && (
          <div>
            <span>{`${preparationDateTimeFrom}-${dateTimeFrom}`}</span>&nbsp;&nbsp;&nbsp;
            <span>
              <Trans>Preparation time</Trans>
            </span>
          </div>
        )}
        <div>
          <span>{`${dateTimeFrom}-${dateTimeTo}`}</span>&nbsp;&nbsp;&nbsp;
          {isRecurrent ? (
            <span>
              <Trans>Recurrent event</Trans>
            </span>
          ) : (
            <span>
              <Trans>Single event</Trans>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

EventDateTime.propTypes = {
  from: PropTypes.string,
  length: PropTypes.number,
  isRecurrent: PropTypes.bool,
  classes: PropTypes.object.isRequired,
}

export default withStyles(eventDetailStyle)(EventDateTime)
